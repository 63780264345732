import { onSnapshot, orderBy, where } from "firebase/firestore";
import { Button, Spinner } from "flowbite-react";
import React from "react";
import toast from "react-hot-toast";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

import newelection from "../../assets/lottie/newelection.json";
import ElectionItemComponent from "../../components/ElectionItemComponent";
import PageTitleComponent from "../../components/PageTitleComponent";
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { getElectionsError, getElectionsStart, getElectionsSuccess, setSelectedElection , resetActionType as resetActionTypeElection} from "../../redux/slices/ElectionSlice";
import { getFirebaseDocs } from "../../util/firebaseFirestoreUtils";
import EmptyListPage from "../EmptyPage";
const ViewElectionsPage = () => {

    const dispatch = useDispatch()
    const electionsState = useSelector(state => state.election)
    const authenticateState = useSelector(state => state.authenticate)

    const navigate = useNavigate();

    React.useEffect(() => {

        const electionsRef = getFirebaseDocs(
            `${FIREBASECOLLECTIONS.ELECTIONS}`
            ,
            [where('userId', "==", authenticateState.user_id), orderBy("createdAt", "desc")]);

        dispatch(getElectionsStart());

        const unsubscribeListener = onSnapshot(electionsRef,
            {
                next: (querySnapshot) => {
                    console.log("on snap callback called for get elections");
                    try {
                        const elections = []
                        querySnapshot.forEach((doc) => {
                            elections.push(doc.data())
                        });
                        dispatch(getElectionsSuccess({ elections: elections }));
                    } catch (error) {
                        const errorCode = error.code
                        dispatch(getElectionsError({ message: errorCode ?? error }))
                    }

                },
                error: (error) => {
                    // console.log(error);
                    dispatch(getElectionsError({ message: error.message }))

                }
            }
        );

        return () => {
            unsubscribeListener();
        }

    }, []);

    React.useEffect(() => {
        if (electionsState.ACTION_TYPE === getElectionsError.toString()) {
            toast.error(electionsState.getElectionsMessage, { duration: 10000 });
            dispatch( resetActionTypeElection());
        }
    }, [electionsState.ACTION_TYPE]);


    return (
        <div className="flex flex-col items-center justify-center">
            <PageTitleComponent
                title="View Elections Page"
                rightComponent={
                    <Link to="/admin/elections/add">
                        <Button color="primary" > <IoAdd /> Create New Election</Button>
                    </Link>
                }
            />

            <div className="flex flex-row flex-wrap justify-center md:justify-start  border w-full ">

                {electionsState.isGetElectionsLoading ?
                    <Spinner /> :
                    electionsState?.elections?.length > 0 ?
                        electionsState?.elections.map((election, index) =>

                            <ElectionItemComponent
                                onManageClicked={() => {
                                    dispatch(setSelectedElection({ selectedElection: election, selectedElectionIndex: index }));
                                    navigate("/admin/election/view")
                                }}

                                onUpgradeClicked={() => {
                                    dispatch(setSelectedElection({ selectedElection: election, selectedElectionIndex: index }));
                                    navigate("/admin/election/upgrade", { state: { electionIndex: index } })
                                }}
                                key={election.id}
                                item={election}
                            />

                        ) :
                        <div className=" flex  items-center justify-center w-full my-5">

                            <EmptyListPage
                                lottieJson={newelection}
                                buttonText="Create New Election"
                                texxt={"Click the button below to create your first Election"}
                                onButtonClick={() => navigate("/admin/elections/add")}
                            />
                        </div>
                }
            </div>



        </div>
    )
}

export default ViewElectionsPage;