import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",
  hidden: "lg:flex",
  showSidebar: false,
};

export const sidebarSlice = createSlice({
  name: "sidebar",
  initialState: INITIAL_STATE,
  reducers: {
    setSidebarHidden: (state, action) => {
      state.ACTION_TYPE = setSidebarHidden.toString();
      state.hidden = action.payload;
    },

    setShowSidebar: (state, action) => {
      state.ACTION_TYPE = setShowSidebar.toString();
      state.showSidebar = action.payload;
    },
  },
});

export const { setSidebarHidden, setShowSidebar } = sidebarSlice.actions;

export default sidebarSlice.reducer;
