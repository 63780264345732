import { twMerge } from "tailwind-merge";
import {  link, text } from "../constants/tailwind-theme";
import client1 from '../assets/images/client1.jpg'
import client2 from '../assets/images/client2.jpg'
import client3 from '../assets/images/client3.jpg'
import { Accordion, Button, Carousel, Footer, Navbar, Tabs, TextInput, Textarea, Timeline } from "flowbite-react";

import FeatureCardComponent from "../components/website/FeatureCardComponent";
import WebsiteCreditsComponent from "../components/website/WebsiteCreditsComponent";
import PricingComponent from "../components/website/PricingComponent";
import { pricingPlan } from "../constants/PRICINGPLAN";
import { carouselText, faq, hotTos, navLinks, whyCooseUs } from "../constants/HOMEPAGECONTENTS";
import {  BsBookmarkCheck } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
const HomePage = (props) => {

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            name: "",
            subject: "",
            message: "",
        },

        onSubmit: (values) => {

            let link = `mailto:info.heyphord@gmail.com?subject=${values.subject}&body=${values.message}%0D%0A%0D%0ASent by: ${values.name}`
            window.open(
                link,
                '_blank' // <- This is what makes it open in a new window.
            );


        },
    });


    return (
        <>
            <div id="home" className=" h-[600px]">
                <Carousel
                    // slide={false}
                    slideInterval={6000}
                    // rightControl={<></>}
                    // leftControl={<></>}
                    className="rounded-none bg-gradient-to-r from-primary-500 to-cyan-500">

                    {
                        carouselText.map((carousel, index) =>

                            <div key={index.toString()} className="flex flex-row flex-wrap items-center justify-center  w-full h-full bg-center bg-cover  px-4 overflow-hidden">

                                <div className="border-0 flex flex-col w-full md:w-5/12 gap-8 my-10">
                                    <h1 className={twMerge(text.heading1, "text-center md:text-start text-white")}>
                                        {carousel.title}
                                    </h1>
                                    <h4 className={twMerge(text.heading3, "text-center md:text-start text-white")}>{carousel.description} </h4>
                                    <Button
                                        color={"secondary"} onClick={()=>navigate("/auth/admin-login")} className="animate-bounce w-40 self-center md:self-start">
                                        GET STARTED!
                                    </Button>
                                </div>
                                <div className="flex flex-col justify-start md:justify-center items-center w-full md:w-5/12 h-full" >
                                    {/* <img className=" h-full w-full object-contain" src={banner1} class="m-2" alt="..." /> */}
                                    <img className=" h-full w-full object-contain" src={carousel.banner} class="m-2" alt="..." />

                                </div>

                            </div>
                        )
                    }
                   

                </Carousel>
            </div>

            {/* OUR CLIENTS */}
            <div id="clients" className="flex flex-col px-10 py-20 items-center bg-blue-50">

                <div className="flex flex-col items-center w-full mb-20">
                    <h3 className={twMerge(text.heading3, "text-secondary-500 text-center")}>OUR CLIENTS</h3>
                    <h3 className={twMerge(text.heading1, " text-center")}>Meet some of our happy clients
                    </h3>
                </div>

                <div className="flex flex-row flex-wrap items-center justify-center md:justify-around gap-8 w-full">
                    <div className="bg-white border rounded-md border-primary-500 m-3 shadow-lg overflow-hidden">
                        <img style={{ width: 300, height: 100 }} src={client1} class="m-2" alt="..." />
                    </div>
                    <div className="bg-white border rounded-md border-primary-500 m-3 shadow-lg overflow-hidden">
                        <img style={{ width: 300, height: 100 }} src={client2} class="m-2" alt="..." />
                    </div>
                    <div className="bg-white border rounded-md border-primary-500 m-3 shadow-lg overflow-hidden">
                        <img style={{ width: 300, height: 100 }} src={client3} class="m-2" alt="..." />
                    </div>


                </div>
            </div>


            {/* KEY FEATURES */}
            <div id="features" className="flex flex-col space-y-4 px-10 py-20 items-center bg-primary-500">

                <div className="flex flex-col items-center w-full mb-20">
                    <h3 className={twMerge(text.heading3, "text-white text-center")}>KEY FEATURES</h3>
                    <h3 className={twMerge(text.heading1, "text-white text-center")}>Why choose us</h3>
                </div>

                <div className="flex flex-row flex-wrap  justify-center gap-4  w-full">

                    {whyCooseUs.map((reason,index) =>

                        <FeatureCardComponent key={index.toString()} icon={reason.icon}
                            title={reason.title}
                            title2={reason.body}
                        />
                    )}


                </div>
            </div>

            {/* HOW TO  */}
            <div id="howto" className="flex flex-col space-y-4 px-10 py-20 items-center bg-white">

                <div className="flex flex-col items-center w-full mb-20 ">
                    <h3 className={twMerge(text.heading3, "text-secondary-500 text-center")}>HOW TOs</h3>
                    <h3 className={twMerge(text.heading1, "text-primary-500 text-center")}>Vote or Create an election in a few steps</h3>
                </div>


                <Tabs.Group
                    aria-label="Default tabs"
                    style="default"
                >
                    {
                        hotTos.map((howTo, index) =>

                            <Tabs.Item  key={index.toString()} active={true} title={howTo.title}
                            >
                                <Timeline horizontal={false}>

                                    {howTo.steps.map((step, indexx, arrray) =>
                                        <Timeline.Item key={indexx.toString()}>
                                            <Timeline.Point icon={BsBookmarkCheck} />
                                            <Timeline.Content>
                                                <Timeline.Title>
                                                    {`${indexx + 1}. ${step.title}`}
                                                </Timeline.Title>
                                                <Timeline.Body>
                                                    {step.body}
                                                </Timeline.Body>


                                            </Timeline.Content>
                                        </Timeline.Item>
                                    )}


                                </Timeline>
                            </Tabs.Item>
                        )
                    }


                </Tabs.Group>


            </div>

            {/* PRICING */}
            <div id="pricing" className="flex flex-col space-y-4 px-10 py-20 items-center bg-blue-50">

                <div className="flex flex-col items-center w-full mb-20">
                    <h3 className={twMerge(text.heading3, "text-secondary-500 text-center")}>PRICING</h3>
                    <h3 className={twMerge(text.heading1, "text-center")}>Check out our prices</h3>
                </div>

                <div className="flex flex-row flex-wrap justify-center gap-4">
                    {pricingPlan.map((plan, index) =>
                        <PricingComponent
                            key={index.toString()}
                            pricingPlan={plan}
                            containerClassNames={"w-full md:5/12 lg:w-3/12"} />
                    )}
                </div>
            </div>

            {/* FAQ */}
            <div id="faq" className="flex flex-col space-y-4 px-10 py-20 items-center bg-gray-10">

                <div className="flex flex-col items-center w-full mb-20">
                    <h3 className={twMerge(text.heading3, "text-secondary-500 text-center")}>FAQ</h3>
                    <h3 className={twMerge(text.heading1, "text-primary-500 text-center")}>Frequently asked questions</h3>
                </div>

                <div className="w-full lg:w-8/12">
                    {/* FAQ QUESTION */}
                    <Accordion className="w-full">

                        {faq.map((faq, index) =>
                            <Accordion.Panel  key={index.toString()}>
                                <Accordion.Title >
                                    <h3 className={twMerge(text.heading3, "text-center text-white")}>{faq.title}</h3>
                                </Accordion.Title>
                                <Accordion.Content className="bg-white">
                                    {faq.isHTML ?
                                        faq.body :
                                        <p className={twMerge(text.description1)}>
                                            {faq.body}
                                        </p>
                                    }

                                </Accordion.Content>
                            </Accordion.Panel>
                        )}


                    </Accordion>
                </div>
            </div>

            {/* FOOTER */}
            <div id="contact" className="flex flex-row flex-wrap justify-between py-20 border-b bg-primary-500">
                <div className="w-full md:w-4/12 p-4 space-y-4">
                    <h3 className={twMerge(text.heading1, "text-white text-center")}>ABOUT US</h3>

                    <p className={twMerge(text.heading3, "text-white text-justify")}>Vote by heyphord is an election software developed by Heyphord I.T Solutions to easily and conveniently create and
                        manage all your online elections. The software solves the main issues that arise when electing leaders
                        through polls.</p>
                    {/* <p className={twMerge(text.description1, "text-justify")}>Heyphord I.T Solutions is a registered Ghanaian business that deals in the sales, lease and creation of
                        software
                        applications for private and commercial use. The team is made up of highly skilled individuals with
                        hand-on years of experience. The team has created problem-solving and result-oriented softwares for
                        reputable institutions, both locally and globally.
                        </p> */}
                </div>

                <div className="w-full md:w-2/12 p-4">

                    <h3 className={twMerge(text.heading2, "text-left text-white mb-4")}>USEFUL LINKS</h3>

                    <Footer.LinkGroup col={true}>

                        {
                            navLinks.map((navLink, index) =>

                                <Navbar.Link
                                key={index.toString()}
                                    href={navLink.href}
                                    active={true}
                                >
                                    <h3 className={twMerge(link.heading3)}>{navLink.name}</h3>
                                </Navbar.Link>
                            )
                        }


                    </Footer.LinkGroup>

                </div>

                <form onSubmit={formik.handleSubmit} 
                    
                    className="w-full md:w-5/12 p-4 space-y-4"
                    >
                    <h3 className={twMerge(text.heading2, "text-white text-center")}>SEND US A MESSAGE</h3>
                    <p className={twMerge(text.heading3, "text-white text-center")}>Do you have enquires? Do you want to know how our system works? or you just want to tell us about the awesome experience you had using the app. Go ahead talk to us 😀</p>

                    <div className="flex flex-col space-y-2">

                        <TextInput name="name" onChange={formik.handleChange} value={formik.values.name} required color={"primary"} placeholder="Your name" />
                        {/* <TextInput type="email" required color={"primary"} placeholder="Your email" /> */}
                        <TextInput name="subject" onChange={formik.handleChange} value={formik.values.subject} required color={"primary"} placeholder="Subject" />
                        <Textarea name="message" onChange={formik.handleChange} value={formik.values.message} required rows={5} color={"primary"} placeholder="Message" />
                        <Button type="submit" color={"secondary"}>Submit</Button>
                    </div>

                </form>


            </div>

            <WebsiteCreditsComponent />



        </>
    )
}

export default HomePage;