import { Badge, Button, Progress } from "flowbite-react";
import React from "react";
import { text } from "../../constants/tailwind-theme";
import moment from "moment";
import { ELECTION_TYPES } from "../../constants/ELECTIONTYPES";
import { Link } from "react-router-dom";
import { searchFilter } from "../../constants/HOMEPAGECONTENTS";
import { calculateProgressColor, calculateProgressPercentage } from "../../util/miscUtil";

const ElectionSearchItemComponent = (props) => {
    const { name, totalVotes, voteLimit, startDateTimeUTC, endDateTimeUTC, imageUrl, paymentPlan, type, categories, status } = props.item;
    const { voteLink, viewResultsLink, tag } = props;

    const progressPercentage =  calculateProgressPercentage (totalVotes ,voteLimit ) ;
    const progressColor = calculateProgressColor ( progressPercentage);

    return (
        <div className=" flex flex-col gap-2 border-2 rounded bg-white shadow-md p-4 w-72">
            <div className=" relative border w-full h-40">
                <img className="absolute top-0 w-full h-full" src={imageUrl} alt="logo" />
                <Badge color={"success"} className="absolute top-2 left-0  uppercase ">{tag}</Badge>
                <Badge color={"warning"} className="absolute top-8 left-0  uppercase ">{type}</Badge>
                <Badge color={"purple"} className="absolute top-14 left-0  uppercase ">{paymentPlan?.toString().replace(/_/g, " ")}</Badge>
            </div>

            <div className="flex flex-row">
                <h2 className={text.heading3}>{name}</h2>
            </div>
            <div className="flex flex-row">
                <h2>Total votes:</h2>
                <span className="ml-2">{totalVotes}</span>
            </div>
            <div className="flex flex-col">
                <h2>Vote Limit: {totalVotes}/{type === ELECTION_TYPES.PUBLIC ? "∞" : voteLimit}</h2>
                <Progress
                    size={"lg"}
                    color={progressColor}
                    className={type === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}
                    progress={progressPercentage} />
            </div>
            <div className="flex flex-row">
                <h2>Categories:</h2>
                <span className="ml-2">{categories?.length ?? 0}</span>
            </div>
            <div className="flex flex-row">
                <h2>Starts on:</h2>
                <span className="ml-1">  {moment(startDateTimeUTC).local().format("lll")}</span>
            </div>
            <div className="flex flex-row">
                <h2>Ends on:</h2>
                <span className="ml-1">{moment(endDateTimeUTC).local().format("lll")} </span>
            </div>
            <div className="flex flex-row justify-center flex-wrap gap-1">

                <Link to={voteLink} target="_blank">
                    <Button color="primary" size={"md"}
                        className={tag === searchFilter.INPROGRESS ? 'block' : 'hidden'}
                    >
                        Vote
                    </Button>
                </Link>
                <Link to={viewResultsLink} target="_blank">
                    <Button color="warning" size={"md"}
                        className={tag === searchFilter.PAST ? 'block' : 'hidden'}
                    >
                        View Results
                    </Button>
                </Link>


            </div>

        </div>
    )
}
export default ElectionSearchItemComponent;