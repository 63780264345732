import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    ACTION_TYPE: "",

    isGetCandidatesLoading: false,
    isEditCandidateLoading: false,
    isAddCandidateLoading: false,
    isDeleteCandidateLoading: false,

    addCandidateMessage: "",
    getCandidatesMessage: "",
    editCandidateMessage: "",
    deleteCandidateMessage: "",

    selectedCandidate: {},
    candidates: [],

}

export const CandidateSlice = createSlice({
    name: 'candidate',
    initialState: INITIAL_STATE,
    reducers: {
        addCandidateStart: state => {
            state.ACTION_TYPE = addCandidateStart.toString()
            state.isAddCandidateLoading = true
            state.addCandidateMessage = ""
        },
        addCandidateSuccess: (state, action) => {
            state.ACTION_TYPE = addCandidateSuccess.toString()
            state.isAddCandidateLoading = false
            state.addCandidateMessage = ""
        },
        addCandidateError: (state, action) => {
            state.ACTION_TYPE = addCandidateError.toString()
            state.isAddCandidateLoading = false
            state.addCandidateMessage = action.payload.message
        },


        getCandidatesStart: state => {
            state.ACTION_TYPE = getCandidatesStart.toString()
            state.isGetCandidatesLoading = true
            state.getCandidatesMessage = ""
            state.candidates = []
        },

        getCandidatesSuccess: (state, action) => {
            state.ACTION_TYPE = getCandidatesSuccess.toString()
            state.isGetCandidatesLoading = false
            state.getCandidatesMessage = ""
            state.candidates = action.payload.candidates
        },

        getCandidatesError: (state, action) => {
            state.ACTION_TYPE = getCandidatesError.toString()
            state.isGetCandidatesLoading = false
            state.getCandidatesMessage = action.payload.message
            state.candidates = []
        },

        setSelectedCandidate: (state, action) => {
            state.ACTION_TYPE = setSelectedCandidate.toString()
            state.selectedCandidate = action.payload.selectedCandidate
        },

        editCandidateStart: state => {
            state.ACTION_TYPE = editCandidateStart.toString()
            state.isEditCandidateLoading = true
            state.editCandidateMessage = ""
        },
        editCandidateSuccess: (state, action) => {
            state.ACTION_TYPE = editCandidateSuccess.toString()
            state.isEditCandidateLoading = false
            state.editCandidateMessage = ""
        },
        editCandidateError: (state, action) => {
            state.ACTION_TYPE = editCandidateError.toString()
            state.isEditCandidateLoading = false
            state.editCandidateMessage = action.payload.message
        },

        deleteCandidateStart: state => {
            state.ACTION_TYPE = deleteCandidateStart.toString()
            state.isDeleteCandidateLoading = true
            state.deleteCandidateMessage = ""
        },

        deleteCandidateSuccess: state => {
            state.ACTION_TYPE = deleteCandidateSuccess.toString()
            state.isDeleteCandidateLoading = false
            state.deleteCandidateMessage = ""
        },

        deleteCandidateError: (state, action) => {
            state.ACTION_TYPE = deleteCandidateError.toString()
            state.isDeleteCandidateLoading = false
            state.deleteCandidateMessage = action.payload.message
        },

        

       

        resetActionType: state => {
            state.ACTION_TYPE = resetActionType.toString();
        },
    }
});

export const {
    addCandidateStart, addCandidateSuccess, addCandidateError,
    getCandidatesStart, getCandidatesSuccess, getCandidatesError,
    editCandidateStart, editCandidateSuccess, editCandidateError,
    deleteCandidateStart, deleteCandidateSuccess, deleteCandidateError,
    resetActionType,
    setSelectedCandidate
} = CandidateSlice.actions

export default CandidateSlice.reducer