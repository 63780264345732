import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isGetUsersLoading: false,
  isGetUserLoading: false,
  isUpdateUserProfileLoading: false,
  isDeactivateUserLoading: false,
  isActivateUserLoading: false,

  getUsersMessage: "",
  getUserMessage: "",
  updateUserProfileMessage: "",
  deactivateUserMessage: "",
  activateUserMessage: "",
  
  users: [],
  user: {}


}

export const UserSlice = createSlice({
  name: 'user',
  initialState: INITIAL_STATE,
  reducers: {

    getUsersStart: state => {
      state.ACTION_TYPE = getUsersStart.toString();
      state.isGetUsersLoading = true;
      state.getUsersMessage = "";
      state.users = []

    },
    getUsersSuccess: (state, action) => {
      state.ACTION_TYPE = getUsersSuccess.toString();
      state.isGetUsersLoading = false;
      state.getUsersMessage = "";
      state.users = action.payload;
    },
    getUsersError: (state, action) => {
      state.ACTION_TYPE = getUsersError.toString();
      state.isGetUsersLoading = false;
      state.getUsersMessage = action.payload.message;
    },

    //get user
    getUserStart: state =>{
      state.ACTION_TYPE = getUserStart.toString();
      state.isGetUserLoading = true;
    },

    getUserSuccess: (state, action) => {
      state.ACTION_TYPE = getUserSuccess.toString();
      state.isGetUserLoading = false;
      state.user = action.payload;
    },

    getUserError: (state, action) => {
      state.ACTION_TYPE = getUserError.toString();
      state.isGetUserLoading = false;
      state.getUserMessage = action.payload.message;
    },
    

    //update user profile
    updateUserProfileStart: state => {
      state.ACTION_TYPE = updateUserProfileStart.toString();
      state.isUpdateUserProfileLoading = true;
      state.updateUserProfileMessage = "";

    },
    updateUserProfileSuccess: (state, action) => {
      state.ACTION_TYPE = updateUserProfileSuccess.toString();
      state.isUpdateUserProfileLoading = false;
      state.updateUserProfileMessage = "";
    },
    updateUserProfileError: (state, action) => {
      state.ACTION_TYPE = updateUserProfileError.toString();
      state.isUpdateUserProfileLoading = false;
      state.updateUserProfileMessage = action.payload.message;
    },

    //activate user
    activateUserStart: state => {
      state.ACTION_TYPE = activateUserStart.toString();
      state.isActivateUserLoading = true;
      state.activateUserMessage = "";

    },
    activateUserSuccess: state => {
      state.ACTION_TYPE = activateUserSuccess.toString();
      state.isActivateUserLoading = false;
      state.activateUserMessage = "User Activated successfully";
    },
    activateUserError: (state, action) => {
      state.ACTION_TYPE = activateUserError.toString();
      state.isActivateUserLoading = false;
      state.activateUserMessage = action.payload.message;
    },

    //deactivate user
    deactivateUserStart: state => {
      state.ACTION_TYPE = deactivateUserStart.toString();
      state.isDeactivateUserLoading = true;
      state.deactivateUserMessage = "";

    },
    deactivateUserSuccess: state => {
      state.ACTION_TYPE = deactivateUserSuccess.toString();
      state.isDeactivateUserLoading = false;
      state.deactivateUserMessage = "User Deactivated successfully";
    },
    deactivateUserError: (state, action) => {
      state.ACTION_TYPE = deactivateUserError.toString();
      state.isDeactivateUserLoading = false;
      state.deactivateUserMessage = action.payload.message;
    },


    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
    },

  }
})

// Action creators are generated for each case reducer function
export const
  {
    getUserStart, getUserSuccess, getUserError,

    getUsersStart, getUsersSuccess, getUsersError,
    updateUserProfileStart, updateUserProfileSuccess, updateUserProfileError,
    deactivateUserStart, deactivateUserSuccess, deactivateUserError,
    activateUserStart, activateUserSuccess, activateUserError,
    resetActionType
  } = UserSlice.actions

export default UserSlice.reducer