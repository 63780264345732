// import { db } from "../firebase/firebase-config";

import { collection, deleteDoc, doc, getDoc, query, setDoc, updateDoc, writeBatch } from "firebase/firestore";
import { db } from "../constants/firebase-config";

/**
 * Get a firebase doc ref, or a doc ref inside a collection. just provide a correct path
 * @param {*} documentPath 
 * @param {*} docFieldObject 
 */
export const setFirebaseDoc = async (documentPath, docFieldObject) => {
    try {
        const docRef = doc(db, documentPath);
        await setDoc(docRef, docFieldObject);
    } catch (error) {
        throw error.message;
    }
}



/**
 * update a firebase doc. just provide a correct path
 * @param {*} documentPath 
 * @param {*} docFieldObjects 
 */
export const updateFirebaseDoc = async (documentPath,  docFieldObjects) => {
    try {
        const docRef = doc(db, documentPath);
        await updateDoc(docRef, docFieldObjects);

    } catch (error) {
        throw error.message;
    }
}

/**
 * Get a reference to a doc or a doc inside a sub collection. just provide the correct path
 * @param {*} documentPath 
 * @returns 
 */
export const getFirebaseDoc= async (documentPath) => {
    try {

        const docRef = doc(db, documentPath);
        const docSnap = await getDoc(docRef);
        return docSnap;
    } catch (error) {
        console.log(error);
    }
}

export const getFirebaseDocRef=  (documentPath) => {
    return   doc(db, documentPath);
   
}


/**
 *  get all documents withing a collection/subcollection
 * @param {*} collectionPath 
 * @returns 
 */
export const getFirebaseDocs =   (collectionPath, queryConstraints =[]) => {
    try {
        const subcollectionRef = collection(db, collectionPath ) ;
        const query1 =  query(subcollectionRef, ...queryConstraints);
        return query1;
    } catch (error) {
        console.log(error);
    }

}

export const deleteFirebaseDoc = async (documentPath) =>{
    try {
        await deleteDoc(doc(db, documentPath))
    } catch (error) {
        console.log(error)
    }
}
