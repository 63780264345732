import { Sidebar } from "flowbite-react";
import { Link, useLocation } from "react-router-dom";

import { BsClipboardData, BsClipboardPlus, BsCreditCard2Back } from 'react-icons/bs';
import { FiLogOut } from 'react-icons/fi';

import React from "react";
import { useDispatch } from "react-redux";
import vote_logo_transparent_bg from '../assets/images/vote_logo_white_bg.png';
import { logOutMiddleware } from '../redux/middlewares/AuthenticateMiddleware';
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";
const AdminSidebarComponent = (props) => {
    const { pathname } = useLocation();

    const { collapsed, closeSideBar } = props;

    const dispatch = useDispatch();

    return (
        <div className={`flex w-full z-10 h-full fixed md:relative md:block  ${collapsed ? "hidden md:w-16" : "md:w-64"}`}>

            <Sidebar collapsed={collapsed} aria-label="Sidebar with multi-level dropdown example">
                <Sidebar.Logo
                    href="#"
                    img={vote_logo_transparent_bg}
                    imgAlt="Vote logo"
                >
                    <h1 className={twMerge(text.heading3, "text-white")}>Vote</h1>
                </Sidebar.Logo>
                <Sidebar.Items>
                    <Sidebar.ItemGroup>
                        <Sidebar.Item
                            as={Link} to={"/admin/elections"} active={"/admin/elections" === pathname}
                            icon={BsClipboardData}
                            onClick={closeSideBar}
                        >
                            Elections
                        </Sidebar.Item>

                        <Sidebar.Item
                            as={Link} to={"/admin/elections/add"} active={"/admin/elections/add" === pathname}
                            icon={BsClipboardPlus}
                            onClick={closeSideBar}
                        >
                            New Election
                        </Sidebar.Item>
                        <Sidebar.Item
                            as={Link} to={"/admin/elections/payments"} active={"/admin/elections/payments" === pathname}
                            icon={BsCreditCard2Back}
                            onClick={closeSideBar}
                        >
                            Payments
                        </Sidebar.Item>


                    </Sidebar.ItemGroup>
                    
                    <Sidebar.ItemGroup>
                        <Sidebar.Item
                            onClick={() => { dispatch(logOutMiddleware()) }}
                            icon={FiLogOut}
                        >
                            Log out
                        </Sidebar.Item>

                    </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>

            {/* click outside sidebar to collapse */}
            <div
                onClick={closeSideBar}
                className="flex flex-grow opacity-70  bg-black">

            </div>
        </div>

    )
}

export default AdminSidebarComponent;