import { Button, Modal, Table } from "flowbite-react";
import React from "react";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";


const ReviewBallotModal = ({ onToggle, show, onConfirm, onCancel, candidateArray }) => {

    console.log(candidateArray);

    return (
        <Modal
            show={show}
            onClose={onCancel}
        >

            <Modal.Header>Do you want to submit your vote?</Modal.Header>

            <Modal.Body>
                <h3 className={twMerge(text.heading3, "my-2")}>Vote summary</h3>
                <Table>
                    <Table.Head>
                        <Table.HeadCell>
                            <h3 className={twMerge(text.heading3, "text-white")}>CANDIDATE</h3>
                        </Table.HeadCell>

                        <Table.HeadCell>
                            <h3 className={twMerge(text.heading3, "text-white")}>CATEGORY</h3>
                        </Table.HeadCell>
                        <Table.HeadCell>
                            <h3 className={twMerge(text.heading3, "text-white")}>VOTE TYPE</h3>
                        </Table.HeadCell>
                       
                    </Table.Head>

                    <Table.Body>
                        {
                            candidateArray.map((val, ind, arr) =>
                                <Table.Row key={ind.toString()}>
                                    <Table.Cell> {val[1].candidateName} </Table.Cell>
                                    <Table.Cell> {val[1].category} </Table.Cell>
                                    <Table.Cell> {val[1].voteType} </Table.Cell>

                                </Table.Row>)

                        }
                    </Table.Body>
                </Table>



            </Modal.Body>

            <Modal.Footer className="flex flex-row justify-center">
                <Button
                    color={"success"}
                    onClick={onConfirm}>
                    Yes, Submit
                </Button>
                <Button outline onClick={onCancel}>
                    No, not yet
                </Button>
            </Modal.Footer>
        </Modal>

    )
}

export default ReviewBallotModal;