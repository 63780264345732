import { doc, increment, onSnapshot, orderBy, writeBatch } from "firebase/firestore"
import { Button, Spinner, Table } from "flowbite-react"
import React, { useState } from "react"
import toast from "react-hot-toast"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { twMerge } from "tailwind-merge"
import IndividualBallotComponent from "../../components/IndividualBallotComponent"
import MultipleBallotComponent from "../../components/MultipleBallotComponent"
import PageTitleComponent from "../../components/PageTitleComponent"
import ReviewBallotModal from "../../components/ReviewBallotModal"
import { db } from "../../constants/firebase-config"
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS"
import { text } from "../../constants/tailwind-theme"
import { getCandidatesError, getCandidatesStart, getCandidatesSuccess } from "../../redux/slices/CandidateSlice"
import { getElectionError, getElectionStart, getElectionSuccess, setCategories } from "../../redux/slices/ElectionSlice"
import { hardReset } from "../../redux/slices/VoterSlice"
import { getFirebaseDocRef, getFirebaseDocs } from "../../util/firebaseFirestoreUtils"
import { setAnonymousVotedMiddleware } from "../../redux/middlewares/VoterMiddleware"
import LoadingPage from "../LoadingPage"
import ReactGA from 'react-ga';
import { ELECTION_TYPES } from "../../constants/ELECTIONTYPES"


export const BallotPage = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const { state } = useLocation();


    const { adminId, electionId } = useParams();


    const electionState = useSelector(state => state.election);
    const electionType = electionState?.election?.type;

    const candidateState = useSelector(state => state.candidate)
    const voterState = useSelector(state => state.voter)

    const [isSubmitVoteLoading, setIsSubmitVoteLoading] = useState(false);
    const [showReviewBallotModal, setShowReviewBallotModal] = React.useState(false)

    const candidateMap = React.useMemo(() => new Map(), []);

    const onVoteSubmitted = async () => {

        setIsSubmitVoteLoading(true);
        const batch = writeBatch(db);

        //mark user as voted if private election
        if (electionType === ELECTION_TYPES.PRIVATE) {
            const voterRef = doc(db, `${FIREBASECOLLECTIONS.VOTERS}/${electionId}-${state?.voterId}`);
            batch.update(voterRef, { isVoted: true, votedAt: new Date().toISOString() });
        }


        //increase vote count by one
        const electionRef = doc(db, `${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}`);
        batch.update(electionRef, { totalVotes: increment(1) });


        //record  candidates vote count by 1
        for (let [key, value] of candidateMap) {

            const candidateRef = doc(db, `${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}/${FIREBASECOLLECTIONS.CANDIDATES}/${value.candidateId}`)

            const valuesToUpdate = (value.voteType === "YES") ? { totalVotesYes: increment(1) } : { totalVotesNo: increment(1) }
            batch.update(candidateRef, valuesToUpdate);

        }

        await batch.commit();

        //set user voted and store it local storage for later retrieval
        setAnonymousVotedMiddleware(adminId, electionId);

        setIsSubmitVoteLoading(false);

        //clear all redux state and prevent user from re-voting
        toast.success("Vote submitted successfully");
        navigate(`/voter/ballot-success/${adminId}/${electionId}`, { replace: true, state: {} })
        dispatch(hardReset());

        //analytics
        ReactGA.event({ category:"VOTE", action :"VOTE SUCCESS" });


    }

    //realtime election listener
    React.useEffect(() => {
        const docRef = getFirebaseDocRef(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}`);
        dispatch(getElectionStart());

        const unsuscribe = onSnapshot(docRef, (doc) => {
            if (doc?.data() === undefined) {
                dispatch(getElectionError({ message: "Invalid URL" }));
                return;
            }

            dispatch(getElectionSuccess({ election: doc.data() }))
        },
            error => {
                dispatch(getElectionError({ message: error.message }))
            });

        return () => {
            unsuscribe();
        }
    }, []);

    //candidates realtime listener
    React.useEffect(() => {
        const collecitonRef = getFirebaseDocs(
            `${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}/${FIREBASECOLLECTIONS.CANDIDATES}`
            ,
            [orderBy("position", "asc")]);

        const unsubscribeListener = onSnapshot(collecitonRef, (querySnapshot) => {
            console.log("on snap callback called for get candidates ON BALLOT PAGE");
            getCandidatesStart()
            try {
                const candidates = []

                querySnapshot.forEach((doc) => {
                    candidates.push(doc.data());
                });

                dispatch(getCandidatesSuccess({ candidates: candidates }));

            } catch (error) {
                const errorCode = error.code
                dispatch(getCandidatesError({ message: errorCode ?? error }))
            }

        });

        return () => {
            unsubscribeListener();
        }

    }, []);

    if (!state?.voterId) {
        return <h1>UNAUTHORIZED</h1>
    }

    if (electionState.isGetElectionLoading || candidateState.isGetCandidatesLoading) {
        return<LoadingPage/>
    }
    if (electionState.ACTION_TYPE === getElectionError.toString()) {
        return <h1>An error occured loading elections</h1>
    }

    return (
        <div className="flex flex-col items-center  bg-tertiary-300 p-4">

            <PageTitleComponent title={`Welcom ${voterState.voter?.name ?? 'user'}`}

                rightComponent={
                    <div className="flex items-center justify-center my-1 mx-4 border-0">
                        <img className="w-10  border " src={electionState?.election?.imageUrl} alt="logo" />
                        <h1 className={twMerge(text.heading3, " text-center")} >{electionState?.election?.name}</h1>
                    </div>} />

            <form className=" w-full lg:w-8/12" onSubmit={(e) => {
                e.preventDefault();

                //voting is required
                if (candidateMap.size === 0) {
                    toast.error("please select an option before submitting")
                    return;
                }

                //show vote confirmation
                setShowReviewBallotModal(true);
                return;
            }}>

                {candidateState.isGetCandidatesLoading ? <Spinner /> : candidateState.candidates?.length > 0 ?
                    electionState?.election?.categories?.map(category => {
                        const candidates = candidateState.candidates.filter((candidate) => category === candidate.category)
                        return (
                            <div className="flex flex-col" key={category}>

                                <h1 className={twMerge(text.heading2, "m-4 mt-10 text-center")}>{category} </h1>

                                <Table>

                                    {/* Table head */}
                                    <Table.Head >
                                        <Table.HeadCell>
                                            <h3 className={twMerge(text.heading3, "text-white")}>Image</h3>
                                        </Table.HeadCell>

                                        <Table.HeadCell>
                                            <h3 className={twMerge(text.heading3, "text-white")}>Candidate</h3>

                                        </Table.HeadCell>
                                        <Table.HeadCell>
                                            <h3 className={twMerge(text.heading3, "text-white")}>Vote</h3>

                                        </Table.HeadCell>
                                    </Table.Head>

                                    <Table.Body>
                                        {candidates.length === 1 ?

                                            <IndividualBallotComponent
                                                candidate={candidates[0]}
                                                onVote={(items) => {
                                                    candidateMap.set(items.category, items);
                                                    // console.log(candidateMap);
                                                }}
                                            />

                                            : candidates.map(candidate => (
                                                <MultipleBallotComponent
                                                    key={candidate.id}
                                                    candidate={candidate}
                                                    onVote={(items) => {
                                                        candidateMap.set(items.category, items);
                                                        // console.log(candidateMap);


                                                    }}
                                                />
                                            ))}
                                    </Table.Body>

                                </Table>


                            </div>
                        )


                    })
                    : null}

                <div className="border flex justify-center items-center">
                    <Button className="w-full md:w-96 my-10" disabled={isSubmitVoteLoading} type="submit" color={"primary"}>
                        {candidateState.isGetCandidatesLoading ?
                            <>
                                <Spinner className="mr-3" /> LOADING ...
                            </>
                            :
                            isSubmitVoteLoading ?
                                <>
                                    <Spinner className="mr-3" /> SUBMITTING...
                                </> :
                                <>
                                    REVIEW AND SUBMIT
                                </>}

                    </Button>
                </div>



            </form>

            {/* modals */}
            {showReviewBallotModal ?
                <ReviewBallotModal
                    onConfirm={() => { setShowReviewBallotModal(false); onVoteSubmitted(); }}
                    onCancel={() => setShowReviewBallotModal(false)}
                    candidateArray={[...candidateMap]}
                    show={showReviewBallotModal} />
                : null}
        </div>
    )
}