import { Button } from "flowbite-react";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import VoteCreditsComponent from "../../components/VoterCreditsComponent";
import { text } from "../../constants/tailwind-theme";
import { isAnonymousVotedMiddleware } from "../../redux/middlewares/VoterMiddleware";
import { toast } from "react-hot-toast";

export const VoterLoginOpenElectionPage = (props) => {

    const navigate = useNavigate();

    //check if admin id and election id is valid
    const { adminId, electionId } = useParams();

    const electionState = useSelector(state => state.election)
    return (
        <div className="flex flex-col items-center gap-4 w-full h-screen">

            <div className="flex flex-col items-center justify-center my-10">
                <img className="h-40  border " src={electionState?.election?.imageUrl} alt="logo" />
                <h1 className={twMerge(text.heading2, " text-center")} >{electionState?.election?.name}</h1>
            </div>

            <div>
                <h1 className={twMerge(text.heading2, "text-center px-2")}>{"Welcome to Vote By Heyphord."} </h1>
                <h1 className={twMerge(text.heading3, "text-center px-2")}>{"Click on the button below to start voting."} </h1>
            </div>

            <div className="w-3/5 h-24 bg-green-200 text-center">
                google ads go here
            </div>


            <Button
                onClick={() => {
                    //check if user has already voted
                    const anonymostVoterObject = isAnonymousVotedMiddleware(adminId, electionId);
                    if (anonymostVoterObject != null) {
                        toast.error("You have already voted")
                        return
                    }
                    navigate(`/voter/ballot/${adminId}/${electionId}`, { replace: false, state: { voterId: "anonymous" } })
                }}

                className="my-4"
                color={"primary"}
            >
                Click here to vote
            </Button>

            <div>
                <h1 className={twMerge(text.heading3, "text-center")}>{electionState.election?.totalVotes} Votes </h1>
                <h1 className={twMerge(text.heading3, "text-center")}>Election ends <Moment fromNow  >{electionState.election?.endDate + " " + electionState.election?.endTime}</Moment></h1>
                <Link to={`/voter/ballot-results/${adminId}/${electionId}`} target="_blank"><p className={twMerge(text.heading3, " text-center  text-xs w-full underline mt-4")}>View Results instead</p></Link>
            </div>

            <VoteCreditsComponent tailwindClassname="w-full  md:fixed bottom-0" />

        </div>
    )
}