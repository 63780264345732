import { Button, Modal } from "flowbite-react";
import React from "react";
import {HiOutlineExclamationCircle} from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";


const DeleteConfirmationModal = ({ onToggle, show, onConfirm, onCancel }) => {


    return (
        <Modal
        size={"sm"}
            show={show}
            onClose={onCancel}
        >

            <Modal.Header />

            <Modal.Body>
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14 text-red-500 dark:text-gray-200" />
                <h3 className={twMerge( text.heading2, "text-center")}>
                    Are you sure you want to delete ?
                </h3>


            </Modal.Body>

            <Modal.Footer className="flex flex-row justify-center">
                <Button
                    color={"failure"}
                    onClick={onConfirm}>
                    Yes, i'm sure
                </Button>
                <Button outline onClick={onCancel}>
                    No, cancel
                </Button>
            </Modal.Footer>
        </Modal>

    )
}

export default DeleteConfirmationModal;