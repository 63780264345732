


import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Outlet } from 'react-router-dom';
import ElectionNavbarComponent from "../components/ElectionNavbarComponent";
import ElectionSidebarComponent from "../components/ElectionSidebarComponent";


export const ElectionsLayout = () => {

    const [collapsed, setCollapsed] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const authState = useSelector(state => state.authenticate);

    React.useEffect(() => {

        //collaps sidebar on mobile view
        if (window.innerWidth < 768) {
            setCollapsed(true)
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }

        window.addEventListener("resize", (ev) => {

            if (ev.currentTarget.innerWidth < 768) {
                setCollapsed(true);
                setIsSmallScreen(true)
            } else {
                setIsSmallScreen(false);
            }
        });

        return () => {
            // Anything in here is fired on component unmount.
            window.removeEventListener("resize", (ev) => { });
        }
    }, [])

    if (!authState.isLoggedIn) {
        return <Navigate to="/" />
    }
    return (

        <div className="flex h-full md:h-screen w-full flex-col overflow-clip">
            <ElectionNavbarComponent toggleCollapse={() => setCollapsed(value => !value)} />

            <div className="flex h-full overflow-clip bg-white border-0">
                <ElectionSidebarComponent
                    collapsed={collapsed}
                    closeSideBar={() => {
                        if (isSmallScreen) {
                            setCollapsed(true)
                        }
                    }}
                />
                <main className="flex-1 overflow-scroll p-4 bg-tertiary-300" >
                    <Outlet />
                </main>
            </div>
        </div>

    )
}

export default ElectionsLayout;