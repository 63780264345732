import { Outlet } from "react-router-dom"
import WebsiteNavbarComponent from "../components/website/WebsiteNavbarComponent";


export const AuthLayout = () => {
    return (
        <div className="flex h-screen w-full flex-col ">
            <WebsiteNavbarComponent />

            <Outlet />
        </div>)
}

export default AuthLayout;