import { Button } from "flowbite-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import PageTitleComponent from "../../components/PageTitleComponent";
import { text } from "../../constants/tailwind-theme";

const PaymentStatus = () => {

  const dispatch = useDispatch();
  const electionState = useSelector(state => state.election);
  const authenticateState = useSelector(state => state.authenticate);
  const navigate = useNavigate();
  const { state } = useLocation();


  return (
    <div className="flex flex-col items-center">

      <PageTitleComponent
        title="Payment Status"
      />
      <div className="flex flex-col  items-center gap-4 ">

        <h1 className={twMerge(text.heading1, "text-center px-2 my-10")}>{state?.message ?? "Payment Success"} </h1>

        <p className={text.description1}>Here are some things you can do</p>


        <div className="space-y-2  mb-20">
          <Button color={"warning"} href={`/admin/elections/payments`}>View Payments</Button>
          <Button color={"success"} href="/admin/election">Go to Election Dashboard</Button>
          <Button color={"primary"} onClick={()=>navigate(-1)} fullSized>Go Back</Button>
        </div>
      </div>



    </div>
  )
}

export default PaymentStatus;