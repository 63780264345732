// const SERVER_URL = "http://localhost:8080/api/v1/vote";
const SERVER_URL = "https://heyphord-backend-apis.herokuapp.com/api/v1/vote"

//spreadsheet endpoints
export const CREATE_ADMIN_GOOGLESPREADSHEET_URL = SERVER_URL +'/create-admin-spreadsheet';
export const CREATE_ADMIN_SHEET_URL = SERVER_URL +'/create-election-sheet';

//voter
export const VOTER_LOGIN_URL = SERVER_URL +'/voters/authenticate';
export const VOTER_VERIFY_OTP_URL = SERVER_URL +'/voters/verify-otp';

export const SHARE_VOTING_LINK_URL = SERVER_URL +'/voters/send-reminder';
export const SHARE_VOTING_LINK_TO_ALL_URL = SERVER_URL +'/voters/send-mass-reminder';