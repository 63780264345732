import { Button } from "flowbite-react";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";
import logo_white from "../assets/images/vote_logo_white_bg.png";

const VoteCreditsComponent = ({ tailwindClassname }) => (

    <div className={twMerge(" flex flex-row flex-wrap items-center justify-center  md:justify-between bg-primary-500 p-3", tailwindClassname)}>
        <a href="/" target="_blank" className="mr-10">
            <img src={logo_white} className="h-8 " />
        </a>

        <div className="flex flex-wrap justify-center items-center mx-2">
            <p className={twMerge(text.description1, "text-xs text-white mr-2")}>Now create your own election. It's free!</p>
            <Button size={"xs"} href="/auth/admin-login" target="_blank" color={"warning"}>Get started</Button>
        </div>
    </div>
)

export default VoteCreditsComponent;