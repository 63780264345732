import { Button } from "flowbite-react";
import Lottie from "lottie-react";
import React from "react";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";


export const EmptyListPage = ({lottieJson, texxt, buttonText, onButtonClick}) => {

    return (
        <div className="flex flex-col items-center justify-center space-y-4">
            <Lottie style={{width:300}} animationData={lottieJson} />
            <h2 className={twMerge( text.heading2,"text-center")}>{texxt}</h2>
            {buttonText? <Button color="primary" onClick={onButtonClick}>  {buttonText}</Button> : null}
        </div>
    )
}

export default EmptyListPage;