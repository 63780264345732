import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    ACTION_TYPE: "",

    isAddPaymentLoading: false,
    isGetPaymentsLoading: false,
    isGetPaymentLoading: false,
    isEditPaymentLoading: false,

    addPaymentMessage: "",
    getPaymentsMessage: "",
    getPaymentMessage: "",
    editPaymentMessage: "",

    payments: [],
    payment: {},

}

export const PaymentSlice = createSlice({
    name: 'payment',
    initialState: INITIAL_STATE,
    reducers: {
        addPaymentStart: state => {
            state.ACTION_TYPE = addPaymentStart.toString()
            state.isAddPaymentLoading = true
            state.addPaymentMessage = ""
        },
        addPaymentSuccess: (state, action) => {
            state.ACTION_TYPE = addPaymentSuccess.toString()
            state.isAddPaymentLoading = false
            state.addPaymentMessage = "";
            state.selectedPayment = action.payload.payment
        },
        addPaymentError: (state, action) => {
            state.ACTION_TYPE = addPaymentError.toString()
            state.isAddPaymentLoading = false
            state.addPaymentMessage = action.payload.message
        },


        getPaymentsStart: state => {
            state.ACTION_TYPE = getPaymentsStart.toString()
            state.isGetPaymentsLoading = true
            state.getPaymentsMessage = ""
            state.payments = []
        },

        getPaymentsSuccess: (state, action) => {
            state.ACTION_TYPE = getPaymentsSuccess.toString()
            state.isGetPaymentsLoading = false
            state.getPaymentsMessage = ""
            state.payments = action.payload.payments
        },

        getPaymentsError: (state, action) => {
            state.ACTION_TYPE = getPaymentsError.toString()
            state.isGetPaymentsLoading = false
            state.getPaymentsMessage = action.payload.message
            state.payments = []
        },

        getPaymentStart: state => {
            state.ACTION_TYPE = getPaymentStart.toString()
            state.isGetPaymentLoading = true
            state.getPaymentMessage = ""
            state.payment = {}
        },

        getPaymentSuccess: (state, action) => {
            state.ACTION_TYPE = getPaymentSuccess.toString()
            state.isGetPaymentLoading = false
            state.getPaymentMessage = ""
            state.payment = action.payload.payment
        },

        getPaymentError: (state, action) => {
            state.ACTION_TYPE = getPaymentError.toString()
            state.isGetPaymentLoading = false
            state.getPaymentMessage = action.payload.message
            state.payments = {}
        },

       

        editPaymentStart: state => {
            state.ACTION_TYPE = editPaymentStart.toString()
            state.isEditPaymentLoading = true
            state.editPaymentMessage = ""
        },
        editPaymentSuccess: (state, action) => {
            state.ACTION_TYPE = editPaymentSuccess.toString()
            state.isEditPaymentLoading = false
            state.editPaymentMessage = ""
            state.selectedPayment ={ ...state.selectedPayment, ...action.payload.payment}

        },
        editPaymentError: (state, action) => {
            state.ACTION_TYPE = editPaymentError.toString()
            state.isEditPaymentLoading = false
            state.editPaymentMessage = action.payload.message
        },


        resetActionType: state => {
            state.ACTION_TYPE = resetActionType.toString();
        },
    }
})

export const {
    addPaymentStart, addPaymentSuccess, addPaymentError,
    getPaymentsStart, getPaymentsSuccess, getPaymentsError,
    getPaymentStart, getPaymentSuccess, getPaymentError,
    editPaymentStart, editPaymentSuccess, editPaymentError,
    resetActionType,
} = PaymentSlice.actions

export default PaymentSlice.reducer