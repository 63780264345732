import { Button } from "flowbite-react";
import React from "react";
import { BsCheck2, BsCheckLg, BsDashCircleDotted, BsDot } from "react-icons/bs";
import { twMerge } from "tailwind-merge";
import { text } from "../../constants/tailwind-theme";
import { Link } from "react-router-dom";

const PricingComponent = ({ containerClassNames, pricingPlan, title, title2 }) => {

    return (
        <div className={twMerge("flex flex-col items-center justify-centedr space-y-4 shadow-md rounded-md py-8 p-4 border bg-primary-500 relative", containerClassNames)}>

            <div>
                <h3 className={twMerge(text.heading2, "text-white text-center")}>{pricingPlan?.name}</h3>
                <p className={twMerge(text.description1, "text-white text-center")}>{pricingPlan?.description}</p>
            </div>

            {/* horizontal ruler */}
            <div className="h-1 w-full bg-secondary-500"></div>

            {/* <h3 className={twMerge(text.heading1, " text-center")}>{pricingPlan?.currency + " " + pricingPlan?.price}</h3> */}
            <h3 className={twMerge(text.heading1, "text-white text-center")}>{pricingPlan?.priceText}</h3>

            {/* horizontal ruler */}
            <div className="h-1 w-full bg-secondary-500"></div>

            <ol className="list-disc" >
                {pricingPlan.features?.map((feature, index) =>
                    <li key={index.toString()} className={twMerge(text.description1, "my-3 font-bold text-white ml-5 ")}>  {feature} </li>
                    // <li className="px-2" > {feature} </li>
                )}
            </ol>

            <Link to="/auth/admin-login">
                <Button className="" fullSized color={"warning"}>GET STARTED</Button>
            </Link>
        </div>
    )
}
export default PricingComponent;