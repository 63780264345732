import { createSlice } from "@reduxjs/toolkit";

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isGetVotersLoading: false,
  isGetVotedLoading: false,
  isGetVoterLoading: false,
  isShareVotingLinkLoading: false,
  isShareVotingLinkToAllLoading: false,
  isVerifyVoterOTPLoading: false,
  isAuthenticateVoterLoading: false,
  isAddVoterLoading: false,
  isUpdateVoterLoading: false,

  getVotersMessage: "",
  getVoterMessage: "",
  getVotedMessage: "",
  shareVotingLinkMessage: "",
  shareVotingLinkToAllMessage: "",
  authenticateVoterMessage: "",
  addVoterMessage: "",
  updateVoterMessage: "",
  sendEmailMessage: "",

  voters: [],
  voter: {},
  spreadSheetDetails:{},

  voted: [], //users who have already casted their votes
}

export const VotersSlice = createSlice({
  name: 'voters',
  initialState: INITIAL_STATE,
  reducers: {

    getVotersStart: state => {
      state.ACTION_TYPE = getVotersStart.toString();
      state.isGetVotersLoading = true;
      state.getVotersMessage = "";
      state.spreadSheetDetails = {}
    },
    getVotersSuccess: (state, action) => {
      state.ACTION_TYPE = getVotersSuccess.toString();
      state.isGetVotersLoading = false;
      state.getVotersMessage = "";
      state.spreadSheetDetails = action.payload.spreadSheetDetails
    },
    getVotersError: (state, action) => {
      state.ACTION_TYPE = getVotersError.toString();
      state.isGetVotersLoading = false;
      state.getVotersMessage = action.payload.message;
      state.spreadSheetDetails = {}
    },

    getVotedStart: state => {
      state.ACTION_TYPE = getVotedStart.toString();
      state.isGetVotedLoading = true;
      state.getVotedMessage = "";
      state.voted = []
    },
    getVotedSuccess: (state, action) => {
      state.ACTION_TYPE = getVotedSuccess.toString();
      state.isGetVotedLoading = false;
      state.getVotedMessage = "";
      state.voted = action.payload.voted
    },
    getVotedError: (state, action) => {
      state.ACTION_TYPE = getVotedError.toString();
      state.isGetVotedLoading = false;
      state.getVotedMessage = action.payload.message;
      state.voted = []
    },


    authenticateVoterStart: state => {
      state.ACTION_TYPE = authenticateVoterStart.toString()
      state.isAuthenticateVoterLoading = true
      state.authenticateVoterMessage = ""
      state.voter = {}
    },
    authenticateVoterSuccess: (state, action) => {
      state.ACTION_TYPE = authenticateVoterSuccess.toString()
      state.isAuthenticateVoterLoading = false
      state.authenticateVoterMessage = ""
      // state.voter = action.payload.voter;
    },
    authenticateVoterError: (state, action) => {
      state.ACTION_TYPE = authenticateVoterError.toString()
      state.isAuthenticateVoterLoading = false
      state.authenticateVoterMessage = action.payload.message
      state.voter = {}
    },



    verifyVoterOTPStart: state => {
      state.ACTION_TYPE = verifyVoterOTPStart.toString()
      state.isVerifyVoterOTPLoading = true
      state.verifyVoterOTPMessage = ""
    },

    verifyVoterOTPSuccess: (state, action) => {
      state.ACTION_TYPE = verifyVoterOTPSuccess.toString()
      state.isVerifyVoterOTPLoading = false
      state.verifyVoterOTPMessage = ""
      state.voter = action.payload.voter

    },

    verifyVoterOTPError: (state, action) => {
      state.ACTION_TYPE = verifyVoterOTPError.toString()
      state.isVerifyVoterOTPLoading = false
      state.verifyVoterOTPMessage = action.payload.message
    },

    shareVotingLinkStart: state => {
      state.ACTION_TYPE = shareVotingLinkStart.toString()
      state.isShareVotingLinkLoading = true
      state.shareVotingLinkMessage = ""
    },
    shareVotingLinkSuccess: (state, action) => {
      state.ACTION_TYPE = shareVotingLinkSuccess.toString()
      state.isShareVotingLinkLoading = false
      state.shareVotingLinkMessage = ""

    },
    shareVotingLinkError: (state, action) => {
      state.ACTION_TYPE = shareVotingLinkError.toString()
      state.isShareVotingLinkLoading = false
      state.shareVotingLinkMessage = action.payload.message
    },


    shareVotingLinkToAllStart: state => {
      state.ACTION_TYPE = shareVotingLinkToAllStart.toString()
      state.isShareVotingLinkToAllLoading = true
      state.shareVotingLinkToAllMessage = ""
    },
    shareVotingLinkToAllSuccess: (state, action) => {
      state.ACTION_TYPE = shareVotingLinkToAllSuccess.toString()
      state.isShareVotingLinkToAllLoading = false
      state.shareVotingLinkToAllMessage = ""

    },
    shareVotingLinkToAllError: (state, action) => {
      state.ACTION_TYPE = shareVotingLinkToAllError.toString()
      state.isShareVotingLinkToAllLoading = false
      state.shareVotingLinkToAllMessage = action.payload.message
    },



    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = resetActionType.toString();
    },
    hardReset: state => {
      state.ACTION_TYPE = resetActionType.toString();
      state.voter = {}
    },

  }
})

export const {
  getVotersStart, getVotersSuccess, getVotersError,
  authenticateVoterStart, authenticateVoterSuccess, authenticateVoterError,
  verifyVoterOTPStart, verifyVoterOTPSuccess, verifyVoterOTPError,
  getVotedStart, getVotedSuccess, getVotedError,
  shareVotingLinkStart, shareVotingLinkSuccess, shareVotingLinkError,
  shareVotingLinkToAllStart, shareVotingLinkToAllSuccess, shareVotingLinkToAllError,
  resetActionType, hardReset
} = VotersSlice.actions

export default VotersSlice.reducer


