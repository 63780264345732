import React from "react";

import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";
import { Progress } from "flowbite-react";
import Moment from "react-moment";
import { Link } from "react-router-dom";
import { ELECTION_TYPES } from "../constants/ELECTIONTYPES";
import { calculateProgressColor, calculateProgressPercentage } from "../util/miscUtil";

const VoterLoginElectionDetailsComponent = (props) => {

    const { election, adminId, electionId } = props;

    const progressPercentage = calculateProgressPercentage( election?.totalVotes ?? 0 , election?.voteLimit ?? 0)
    const progressColor = calculateProgressColor(progressPercentage)


    return (


        <div >

            <h1 className={twMerge(text.heading3, "text-center")}>{election?.totalVotes} Votes </h1>
            <Progress
                color={progressColor}
                labelText
                textLabelPosition="inside"
                textLabel={`${election?.totalVotes} / ${election?.voteLimit} `}
                size={"lg"}
                className={election?.type === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block my-2'}
                progress={progressPercentage}
            // progress={100}

            />
            <h1 className={twMerge(text.heading3, "text-center")}>Election ends <Moment fromNow  >{election?.endDate + " " + election?.endTime}</Moment></h1>
            <Link to={`/voter/ballot-results/${adminId}/${electionId}`} target="_blank"><p className={twMerge(text.heading3, " text-center  text-xs w-full underline mt-4")}>View Results instead</p></Link>
        </div>
    )
}
export default VoterLoginElectionDetailsComponent;