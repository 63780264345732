import { BiDevices, BiMoney } from "react-icons/bi";
import { BsAward, BsFileEarmarkSpreadsheet, BsFilePerson } from "react-icons/bs";
import { GiRunningNinja } from "react-icons/gi";
import { twMerge } from "tailwind-merge";
import { text } from "./tailwind-theme";

import banner1 from '../assets/images/banner1.png';
import banner2 from '../assets/images/banner2.png';
import banner3 from '../assets/images/banner3.png';
export const carouselText =[
    {
        title:"Welcome to Vote by Heyphord",
        description:"Quickly, Sececurely, and Conveniently cast your vote or set up your upcoming Election.",
        banner: banner1,
    },
    {
        title:"Easy to set up. Easy to use",
        description:"Get started in a few steps. Quickly setup your election and let your voters decide",
        banner: banner3,
    },
    {
        title:"Accessible on all your favourite devices.",
        description:"Manage your election and vote with your Desktop ,  Tablet,  or Mobile phone",
        banner: banner2,
    },
    
]

export const navLinks =[
    {
        href:"/#home",
        name:"Home",
    },
    {
        href:"/#clients",
        name:"Clients",
    },
    {
        href:"/#features",
        name:"Features",
    },
    {
        href:"/#howto",
        name:"How To",
    },
    {
        href:"/#pricing",
        name:"Pricing",
    },
    {
        href:"/#faq",
        name:"FAQ",
    },
    {
        href:"/#contact",
        name:"Contact",
    },
    {
        href:"/search",
        name:"Search",
    },
  
   
    
]
export const whyCooseUs =[
    {
        title:"Affordable",
        body:"At HEYPHORD I.T SOLUTIONS , we have our users at heart. Our services are inexpensive and reasonably priced. Public election are free of charage.  We also offer a free service for a maximum of 20 voters for private elections.",
        icon:<BiMoney size={40} color="#024681" />,
    },
    {
        title:"Seamless Google sheets integration",
        body:"Easily update and manage voter information with Google Sheets, securely and conveniently from anywhere at any time. Plus, our automatic error-checking feature ensures that any mistakes in your spreadsheet are caught and brought to your attention. ",
        icon:<BsFileEarmarkSpreadsheet size={40} color="#024681" />,
    },
    {
        title:"Realtime Election Updates",
        body:"Stay up-to-date with real-time election updates. Receive near-instantaneous reports on your elections, including live updates on the total number of voters, time remaining, and election results. Keep yourself and your voters informed every step of the way.",
        icon:< GiRunningNinja  size={40} color="#024681" />,
    },
    {
        title:"Unopposed candidate support",
        body:"Our voting software provides support for single candidate elections",
        icon:<BsFilePerson size={40} color="#024681" />,
    },
    {
        title:"Election Integrity And Accuracy",
        body:"The software offers high election integrity. Private elections are secure and private. Voting is anonymous, and each voter may only cast one ballot. Each ballot includes a unique voting key and may be independently verified and audited",
        icon:<BsAward size={40} color="#024681" />,
    },
    {
        title:"Accessibility & User-friendy",
        body:"Administrators can manage elections from multiple devices such as computers, tablets, mobile phones, with ease. Likewise, voters can cast their votes from their computers or mobile devices, anytime and anywhere",
        icon:<BiDevices size={40} color="#024681" />,
    },
    // {
    //     title:"Accuracy",
    //     body:"Thanks to the vigorous auditing capabilities of our system, you can show an accountable voting process that provides accurate election results. To ensure votes are cast correctly and prevent election rigging, voter ballots and results can be made visible and auditable",
    //     icon:<FiTarget size={40} color="#024681" />,
    // },
    // {
    //     title:"User-friendly",
    //     body:"The design and interface of the software makes it easy for people to use. Administrators and voters can easily navigate the platform to monitor elections and cast votes, respectively",
    //     icon:<IoBalloonOutline size={40} color="#024681" />,
    // },
    
]
export const faq = [
    {
        title: "Can I try it out for free?",
        body: " Yes, Public election are free of charge. We also offer a free service for a maximum of 20 votes for Private elections. More than that you will have to upgrade to the PAY PER VOTE or ENTERPRISE payment plan",
        isHTML: false
    },
    {
        title: "Do I have to sign up as a voter?",
        body: " No. As a voter, all you need is the election link to vote. The Election admin will make the link available to you. You can equally search for published elections on this website to cast your vote ",
        isHTML: false
    },
    {
        title: "Who needs to sign up?",
        body: "One person needs to sign up as an administrator/electoral commissioner to manage the progress of elections. Your voters should not sign up",
        isHTML: false
    },
    {
        title: "As an administrator, can I update the voters database after the election has started?",
        body: " Yes. For private elections, you can update your Voters database via google sheets  at any time.",
        isHTML: false
    },
    {
        title: "Will you accept money to rig/tamper with the elections?",
        body: "No. Vote by Heyphord has been used by reputable institutions, and we get callbacks due to our trustworthiness and ability to conduct free and fair elections. Rest assured, only the administrator will have full access to the ongoing election. We will only come in when you need support.",
        isHTML: false
    },
    {
        title: "How do I manage the details of my Voters?",
        body: "For Private Elections, you can manage all your voters from a google spreadsheet. This spreadsheet is automatically created when you sign up. Go to the “Manage Voters” section to see the button that will redirect you to the spreadsheet",
        isHTML: false
    },
    {
        title: "How do i make payment?",
        body: <>
            <li> <span> Go to “Elections” </span></li>
            <li> <span> Locate the private election you want to make payment for. </span></li>
            <li> <span> Click on “UPGRADE PLAN” </span></li>
            <li> <span> Select the price plan that is suitable for you, then proceed to “MAKE PAYMENT”
            </span></li>
        </>,
        isHTML: true
    },
    {
        title: "Which plan is best for me?",
        body:
            <>
                <p className={twMerge(text.description1, "mb-2")}>
                    Depends on the scenario
                </p>

                <li className={twMerge(text.description1,"mb-2")}>All public elections comes with the Free Plan. Select this if you want anyone across the globe to cast their vote.  </li>
                <li className={twMerge(text.description1,"mb-2")}>For private elections where the exact number of voters is unknown, the PAY PER VOTE plan is the most suitable option. This plan allows you to pay based on the number of votes cast, making it a flexible and cost-effective solution.</li>
                <li className={twMerge(text.description1,"")}>If you're a corporate entity or a large institution looking to conduct a private election, our ENTERPRISE plan is the perfect fit. Opting for this plan will help you save costs as it allows an unlimited number of voters to participate in the election. </li>
            </>
        ,
        isHTML: true
    },
]

export const hotTos = [
    {
        title: "Create Election : PUBLIC",
        steps: [
            {
                title:"Sign up",
                body:"Click on Get started and sign up using your google account"
            },
            {
                title:"Create Election",
                body:"Provide some details such as the  election name, start & end date, election logo and the election type. In this case Election type must be PUBLIC  "
            },
            {
                title:"Add Candidates",
                body:"Next step is to add your candidates. Candidates are grouped by categories. You can sort or edit the candidates as you see fit "
            },
            {
                title:"Publish the Election",
                body:"On the election dashboard, Click on the 'PUBLISH' button. "
            },
            {
                title:"Share the Election Link",
                body:"You can copy or manually share the Election link to your voters via whatsapp, Facebook, Twitter. Voters can also search for the election name on the website "
            },
        ]
    },
    {
        title: "Create Election: PRIVATE",
        steps: [
            {
                title:"Sign up",
                body:"Click on Get started and sign up using your google account"
            },
            {
                title:"Create Election",
                body:"Provide some details such as the  election name, start & end date, election logo and the election type. In this case Election type must be PRIVATE  "
            },
            {
                title:"Add Candidates",
                body:"Next step is to add your candidates. Candidates are grouped by categories. You can sort or edit the candidates as you see fit "
            },
            {
                title:"Add Autorized Voters",
                body:"Since this is a private election, only the people you add to the voters database can vote. "
            },
            {
                title:"Publish the Election",
                body:"On the election dashboard, Click on the 'PUBLISH' button. "
            },
            {
                title:"Share the Election Link",
                body:"You can copy or manually share the Election link to your voters via whatsapp, Facebook, Twitter. Voters can also search for the election name on the website "
            },
        ]
    },
    {
        title: "Cast your vote: PUBLIC ELECTION",
        steps: [
            {
                title:"Get Election link",
                body:"As a voter, you will receive the election link from the admin. You can also search for the election name on this webiste"
            },
            {
                title:"Cast your vote",
                body:"Click on the 'Click here to vote' Button to cast your vote. "
            },
            
        ]
    },
    {
        title: "Cast your vote: PRIVATE ELECTION",
        steps: [
            {
                title:"Get Election link",
                body:"As a voter, you will receive the election link from the admin. You can also search for the election name on this webiste"
            },
            {
                title:"Verify via Email ",
                body:"Enter your email address and click the 'REQUEST OTP' Button. If the admin has authorized your email, you will receive an OTP. Enter the OTP on the next page to vote"
            },
            {
                title:"Cast your vote",
                body:" Cast your vote "
            },
            
        ]
    },
    
]

export const searchFilter = {
    ALL:"ALL",
    UPCOMING:"UPCOMING",
    INPROGRESS:"INPROGRESS",
    PAST:"PAST",
}