export const isFormFieldValid = (formik, name) =>
  !(formik?.touched[name] && formik?.errors[name]);

export const getFormErrorMessage = (formik ,name) => {
  return (
    !isFormFieldValid(formik, name) && (
      <small className="font-bold text-sm">
        {formik?.errors[name]}
      </small>
    )
  );
};