import { getDocs } from "firebase/firestore"
import { auth } from "../../constants/firebase-config"
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS"
import { deleteFirebaseDoc, getFirebaseDocs, setFirebaseDoc, updateFirebaseDoc } from "../../util/firebaseFirestoreUtils"
import { addCandidateError, addCandidateStart, addCandidateSuccess, deleteCandidateError, deleteCandidateStart, deleteCandidateSuccess, editCandidateError, editCandidateStart, editCandidateSuccess, getCandidatesError, getCandidatesStart, getCandidatesSuccess } from "../slices/CandidateSlice"
import ReactGA from 'react-ga';

export const addCandidateMiddleware = (electionId ,candidateId ,candidate) => {
    return async (dispatch) => {
        dispatch(addCandidateStart())
        try {
            console.log(`candidate id : ${candidateId}`)
            await setFirebaseDoc(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}/${FIREBASECOLLECTIONS.CANDIDATES}/${candidateId}`,candidate)
            dispatch(addCandidateSuccess({ candidate: candidate }));
            ReactGA.event({ category:"CANDIDATES", action :"ADD CANDIDATE SUCCESS" });

        } catch (error) {
            const errorCode = error.code
            console.log(`error: ${error}`)
            dispatch(addCandidateError({ message: errorCode ?? error }))
            ReactGA.event({ category:"ERRORS", action :"ADD CANDIDATE ERROR" });

        }
    }
}



export const editCandidateMiddleware = (electionId, candidateId ,candidate) => {
    return async (dispatch) => {
        dispatch(editCandidateStart())
        try {
            const currentUser = auth.currentUser
            await updateFirebaseDoc(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}/${FIREBASECOLLECTIONS.CANDIDATES}/${candidateId}`, candidate)
            dispatch(editCandidateSuccess({ candidate: candidate }))
            ReactGA.event({ category:"CANDIDATES", action :"EDIT CANDIDATE SUCCESS" });

        } catch (error) {
            const errorCode = error.code
            dispatch(editCandidateError({ message: errorCode ?? error }))
            ReactGA.event({ category:"ERRORS", action :"EDIT CANDIDATE ERROR" });

        }

    }
}

export const deleteCandidateMiddleware = (electionId, candidateId) => {
    return async (dispatch) => {
        dispatch(deleteCandidateStart())
        try {
            const currentUser = auth.currentUser
            await deleteFirebaseDoc(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}/${FIREBASECOLLECTIONS.CANDIDATES}/${candidateId}`)
            dispatch(deleteCandidateSuccess())
            ReactGA.event({ category:"CANDIDATES", action :"EDIT CANDIDATE SUCCESS" });

        } catch (error) {
            const errorCode = error.code
            dispatch(deleteCandidateError({ message: errorCode ?? error }))
            ReactGA.event({ category:"CANDIDATES", action :"EDIT CANDIDATE ERROR" });

        }

    }
}

