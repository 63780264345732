import { Button, Timeline } from "flowbite-react";
import React from "react";
import { BsBookmarkCheckFill, BsCheckLg } from "react-icons/bs";
import { HiArrowRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";
import { ELECTION_TYPES } from "../constants/ELECTIONTYPES";

const WalkthroughComponent = ({ isSmallScreen, electionType }) => {

    const publicElectionWalkthrough = [
        {
            title: "Create a new Election",
            description: "Congrats, you have successfully created an election. You can edit this election anytime",
            buttonName: "Edit Election",
            buttonLink: "/admin/election/edit",
        },
        {
            title: "Add a new Candidate",
            description: "Next step is to add your candidates. Candidates are grouped by categories. Click on the button below to add a new candidate",
            buttonName: "Add Candidate",
            buttonLink: "/admin/election/candidates/add",
        },
       
        {
            title: "Publish & Share voting link",
            description: "Final step is to Publish this election and share the voting link. You can manually share the link or you can use our automated link sharing features.",
            buttonName: "Share voting link",
            buttonLink: "/admin/election/share-link",
        },
    ]
    const privateElectionWalkthrough = [
        {
            title: "Create a new Election",
            description: "Congrats, you have successfully created an election. You can edit this election anytime",
            buttonName: "Edit Election",
            buttonLink: "/admin/election/edit",
        },
        {
            title: "Add a new Candidate",
            description: "Next step is to add your candidates. Candidates are grouped by categories. Click on the button below to add a new candidate",
            buttonName: "Add Candidate",
            buttonLink: "/admin/election/candidates/add",
        },
        {
            title: "Add your voters",
            description: "Since this is a private election, only the people you add to the voters' spreadsheet can vote.",
            buttonName: "Open Voters' Database",
            buttonLink: "/admin/election/voters",
        },
        {
            title: "Publish & Share voting link",
            description: "Final step is to Publish this election and share the voting link. You can manually share the link or you can use our automated link sharing features.",
            buttonName: "Share voting link",
            buttonLink: "/admin/election/share-link",
        },
    ]

    const walkthrough = electionType === ELECTION_TYPES.PUBLIC ? publicElectionWalkthrough : privateElectionWalkthrough ;
   
    return (
        <div className="flex flex-col w-full ">

            {/* DASHBOARD TITLE */}
            <div className="flex flex-row flex-wrap justify-between items-baseline">

                <div className="m-3">
                    <h2 className={twMerge(text.heading2, "")}>Welcome to "Vote by Heyphord" 😀</h2>
                    <h2 className={twMerge(text.description1, "")}>Let us help you get started</h2>
                </div>
            </div>

            {/* DASHBOARD CONTENT */}

            <div className="flex items-center justify- border-2 rounded bg-white shadow-md p-10 w-full">

                <Timeline horizontal={!isSmallScreen}>
                    {
                        walkthrough.map(({title , description , buttonLink , buttonName}, index, arrray) =>
                            <Timeline.Item key={index.toString()}>
                                <Timeline.Point icon={BsBookmarkCheckFill} />
                                <Timeline.Content>
                                    <Timeline.Title>
                                        {`${index+1}. ${title}`}
                                    </Timeline.Title>
                                    <Timeline.Body>
                                        {description}
                                    </Timeline.Body>

                                    <Link to={buttonLink}>
                                        <Button size={"xs"} color="primary">
                                            {buttonName}
                                            <HiArrowRight className="ml-3 w-5" />
                                        </Button>
                                    </Link>

                                </Timeline.Content>
                            </Timeline.Item>
                        )
                    }

                </Timeline>
            </div>

        </div>
    )
}
export default WalkthroughComponent;