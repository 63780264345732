import { Badge, Button } from "flowbite-react";
import React from "react";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const CandidateItemComponent = (props) => {
    const { name, category, imageUrl } = props.item;
    const { onEditClicked, onDeleteClicked , onMoveToLastClicked, index ,size } = props;

    return (
        <div className=" flex flex-col space-y-1 border-2 rounded bg-white shadow-md p-4 w-64 m-5">
            <div className="relative border w-full h-52">
                <img className=" w-full h-full object-center object-cover" src={imageUrl} alt="logo" />
                <Badge color={"warning"} className="absolute top-2 left-0  uppercase ">{index +1}</Badge>

            </div>

            <div className="flex flex-row">
                <h2 className={text.heading3}>{name}</h2>
            </div>
            <div className="flex flex-row">
                <span className={twMerge(text.description1)}>Category: {category}</span>
            </div>

            <div className="flex flex-row flex-wrap gap-1">
                
                <Button color="primary" size={"xs"} onClick={onEditClicked}>
                    Edit
                </Button>
                <Button color={"failure"} size={"xs"} onClick={onDeleteClicked}>
                    Delete
                </Button>

                {index!==(size-1)?
                    <Button size={"sm"} color="primary" onClick={onMoveToLastClicked}>
                     Move To Last
                </Button>: null}
               

            </div>

        </div>
    )
}
export default CandidateItemComponent;