import React from "react";
import { useFormik } from "formik";
import { twMerge } from "tailwind-merge";
import { text } from "../../constants/tailwind-theme";
import { getFormErrorMessage, isFormFieldValid } from "../../util/formikUtils";
import PageTitleComponent from "../../components/PageTitleComponent";
import { Accordion, Button, Label, Radio, Select, Spinner, TextInput } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { PAYMENT_PLANS, pricingPlan } from "../../constants/PRICINGPLAN";
import { usePaystackPayment } from "react-paystack";
import { useLocation, useNavigate } from "react-router-dom";
import { addPaymentMiddleware } from "../../redux/middlewares/PaymentMiddleware";
import { addPaymentStart, resetActionType as resetActionTypePayment } from "../../redux/slices/PaymentSlice";
import { ELECTION_TYPES } from "../../constants/ELECTIONTYPES";

const UpgradeElectionPage = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const paymentState = useSelector(state => state.payment);
  const electionState = useSelector(state => state.election);
  const authenticateState = useSelector(state => state.authenticate);

  const [paystackResponse, setPaystackResponse] = React.useState({});

  const { state } = useLocation();


  const formik = useFormik({
    initialValues: {
      electionIndex: state?.electionIndex || "",
      paymentPlan: "",
      votes: 20,
      cost: null,
    },

    validate: (values) => {
      const errors = {};

      // if (!values.electionIndex) {
      //   errors.electionIndex = "Select an Election to upgrade.";
      //   return errors;
      // }
      if (!values.paymentPlan) {
        errors.paymentPlan = "Select payment plan";
        return errors;
      }

      // if (values.paymentPlan === PAYMENT_PLANS.PAY_PER_VOTE && !values.votes) {
      //   errors.paymentPlan = "Please enter the number of votes";
      //   return errors;
      // }

      
      // if (values.paymentPlan === PAYMENT_PLANS.PAY_PER_VOTE && values.votes < 20) {
      //   errors.paymentPlan = "Votes to purchase must be greater or equal to 20";
      //   return errors;
      // }
      
      
      const pickedElection = electionState.selectedElection;
      
      if (pickedElection?.type === ELECTION_TYPES.PUBLIC) {
        errors.paymentPlan = "All public elections are free of charge 😀. Select a private election to upgrade or create a new private election";
        return errors;
      }
      
      if (pickedElection.paymentPlan === PAYMENT_PLANS.ENTERPRISE) {
        errors.paymentPlan = "This election has already been upgraded to the enterprise plan. Choose a different election to upgrade";
        return errors;

      }

      return errors;
    },

    onSubmit: (values) => {
      const pickedElection = electionState.selectedElection;

      initializePayment(
        //on payment success
        function onSuccess(response) {
          setPaystackResponse(response);
          console.log("on success response", response);
          console.log("setPaystackResponse", paystackResponse);

          //todo: add this transaction to the payments table
          const paymentObject = {
            id: response.reference,
            userId: authenticateState.user_id,
            paymentStatus: "SUCCESS",
            valueStatus: "PENDING",
            paymentPlan: values.paymentPlan,
            electionId: pickedElection.id,
            election: pickedElection,
            electionName: pickedElection.name,
            amount: values.cost,
            votes: values?.votes ?? 0,
            createdAt: new Date().toISOString()
          }
          dispatch(addPaymentMiddleware(paymentObject));

        },

        //on close
        function onClose() {
          console.log("On close");
        }
      );

    },
  });

  const config = React.useMemo(() => {
    const pickedElection = electionState.selectedElection;
    return {
      email: authenticateState.user.email,
      amount: formik.values.cost * 100,
      publicKey: process.env.REACT_APP_PAYSTACK_PUBLIC_KEY,
      currency: "GHS",
      metadata: {
        adminId: authenticateState.user_id,
        email: authenticateState.user.email,
        paymentPlan: formik.values.paymentPlan,
        electionId: pickedElection?.id,
        electionName: pickedElection?.name,
        votes: formik.values.votes,
      },

    }
  }, [electionState.selectedElection, formik.values.cost, formik.values.paymentPlan, formik.values.votes]);
  const initializePayment = usePaystackPayment(config);

  React.useEffect(() => {
    if (paymentState.ACTION_TYPE === addPaymentStart.toString()) {
      console.log("PAYSTACK RESPONSE", paystackResponse);
      navigate("/admin/election/upgrade/status", {
        state: {
          message: "Payment Success. You will receive an email shortly after your payment plan has been updated. ",
          reference: paystackResponse
        }
      });
      dispatch(resetActionTypePayment())
    }

  }, [paymentState.ACTION_TYPE, paystackResponse]);

  return (
    <div className="flex flex-col items-center">

      <PageTitleComponent
        title={`Upgrading Election: ${electionState.selectedElection?.name}`}
      />

      <form className="flex flex-col items-center w-full md:w-6/12 gap-4 border-0 p-2 mt-10" onSubmit={formik.handleSubmit} >


        {/* Select pricing plan */}
        <div className="w-full">
          <div className="mb-2 block">
            <Label
              htmlFor="type"
              value="Select Price plan "
            />
          </div>
          <Accordion collapseAll={true}>

            {pricingPlan.map((value, index, arrray) => {

              if (value.value === PAYMENT_PLANS.FREE) {
                return <></>;
              }

              return (
                <Accordion.Panel key={index.toString()}>
                  <Accordion.Title
                  >
                    <div className="flex items-center gap-2">
                      <Radio
                        // disable if plan in free. disable pay per view is enterprise plan is selected
                        // disabled={value.value === PAYMENT_PLANS.FREE || electionState.elections[formik.values.electionIndex]?.paymentPlan === PAYMENT_PLANS.ENTERPRISE}
                        style={{ width: 28, height: 28, borderWidth: 2 }}
                        id="paymentPlan"
                        name="paymentPlan"
                        value={value.value}
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange(e);

                          //calculate amount
                          if (value.value === PAYMENT_PLANS.ENTERPRISE) {
                            formik.setFieldValue("cost", (value.price).toFixed(2));

                          } else if (value.value === PAYMENT_PLANS.PAY_PER_VOTE) {
                            formik.setFieldValue("cost", (formik.values.votes / value.pricePerVotes).toFixed(2));

                          }
                        }}

                        required={true}
                        color={isFormFieldValid(formik, "paymentPlan") ? "primary" : "failure"}
                      />
                      <Label htmlFor="paymentPlan">
                        <h3 className={twMerge(text.heading2, "text-white text-center")}>{value.name}</h3>
                      </Label>
                    </div>
                    <div>
                      <h3 className={twMerge(text.heading3, "text-white mt-2")}>{value.description}</h3>
                    </div>
                  </Accordion.Title>
                  <Accordion.Content className="bg-white">
                    {
                      value.value === PAYMENT_PLANS.PAY_PER_VOTE &&

                      <div className="w-full px-5 mb-5">
                        <div className="mb-2 block">
                          <Label className="mb-2 block" htmlFor="email1" value="How many votes do you want to purchase? *" />
                        </div>
                        <TextInput
                          id="votes"
                          name="votes"
                          disabled={formik.values.paymentPlan !== PAYMENT_PLANS.PAY_PER_VOTE}
                          min={20}
                          onChange={(e) => {
                            formik.setFieldValue("votes", e.target.value);
                            formik.setFieldValue("cost", (e.target.value / value.pricePerVotes).toFixed(2));

                          }}
                          onBlur={formik.handleBlur}
                          value={formik.values.votes}
                          type="number"
                          step={1}
                          placeholder="Enter the number of votes to purchase"
                          required={true}
                          color={isFormFieldValid(formik, "votes") ? "primary" : "failure"}
                          helperText={getFormErrorMessage(formik, "votes")}
                        />
                        {formik.values.paymentPlan !== PAYMENT_PLANS.PAY_PER_VOTE && <span className={twMerge( text.description1,"text-red-500")}>Select this price plan to enable this field</span>}
                      </div>
                    }

                    {value.features.map((feature, index) =>
                      <li key={index.toString()} className={twMerge(text.description1, "my-1 text-black ml-5")}>  {feature} </li>
                    )}

                  </Accordion.Content>
                </Accordion.Panel>
              );
            }
            )}

          </Accordion>
          <p className="text-red-500">{getFormErrorMessage(formik, "paymentPlan")}</p>

        </div>


        <div className="w-full">
          <div className="mb-2 block borde">
            <Label
              htmlFor="electionIndex"
              value="Payment Summary "
            />
          </div>
          <div className="border-2 border-primary-500 rounded-lg p-4 w-full bg-white">
            <h2 className={twMerge(text.heading3)}>Election Name : {electionState.selectedElection?.name ?? "--"} </h2>
            <h2 className={twMerge(text.heading3)}>Upgrading plan from: {electionState.selectedElection?.paymentPlan.toString().replace(/_/g, " ") ?? "--"} TO {formik.values.paymentPlan === "" ? "--" : (formik.values.paymentPlan).toString().replace(/_/g, " ")} </h2>
            <h2 className={twMerge(text.heading3)}>Votes to purchase : {formik.values.paymentPlan === PAYMENT_PLANS.ENTERPRISE ? "UNLIMITED" : formik.values.votes }</h2>
            <h2 className={twMerge(text.heading3)}>Vote Limit after purchase : {formik.values.paymentPlan === PAYMENT_PLANS.ENTERPRISE ? "UNLIMITED" : ( Number(formik.values.votes) +  Number(electionState.selectedElection.voteLimit) ) }</h2>
            <h2 className={twMerge(text.heading3)}>Amount to pay (minus tax) :  </h2>
            <h1 className={twMerge(text.heading1)}>$ {formik.values?.cost ?? "--"}</h1>
          </div>
        </div>

        <p className={twMerge(text.description2)}>You will be redirected to complete your payment after clicking 'MAKE PAYMENT'</p>
        <Button

          type="submit"
          disabled={electionState.isEditElectionLoading}
          color="primary">
          {electionState.isEditElectionLoading ? <Spinner className="mr-3 h-5 w-5" /> : null}
          MAKE PAYMENT
        </Button>

      </form>

    </div>
  )
}

export default UpgradeElectionPage;