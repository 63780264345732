import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    ACTION_TYPE: "",

    isGetResultsLoading: false,
    isRefreshResultsLoading: false,

    getResultsMessage: "",
    refreshResultsMessage: "",

    results: [],
    categories:[]

}

export const ResultSlice = createSlice({
    name: 'results',
    initialState: INITIAL_STATE,
    reducers: {
       

        getResultsStart: state => {
            state.ACTION_TYPE = getResultsStart.toString()
            state.isGetResultsLoading = true
            state.getResultsMessage = ""
            state.results = []
        },

        getResultsSuccess: (state, action) => {
            state.ACTION_TYPE = getResultsSuccess.toString()
            state.isGetResultsLoading = false
            state.getResultsMessage = ""
            state.results = action.payload.results
            state.categories = action.payload.categories
        },

        getResultsError: (state, action) => {
            state.ACTION_TYPE = getResultsError.toString()
            state.isGetResultsLoading = false
            state.getResultsMessage = action.payload.message
            state.results = []
        },


        refreshResultsStart: state => {
            state.ACTION_TYPE = refreshResultsStart.toString()
            state.isRefreshResultsLoading = true
            state.refreshResultsMessage = ""
        },
        refreshResultsSuccess: (state, action) => {
            state.ACTION_TYPE = refreshResultsSuccess.toString()
            state.isRefreshResultsLoading = false
            state.results = action.payload.results;
            state.categories = action.payload.categories

        },
        refreshResultsError: (state, action) => {
            state.ACTION_TYPE = refreshResultsError.toString()
            state.isRefreshResultsLoading = false
            state.refreshResultsMessage = action.payload.message
        },

       

        resetActionType: state => {
            state.ACTION_TYPE = resetActionType.toString();
        },
    }
});

export const {
    addCandidateStart, addCandidateSuccess, addCandidateError,
    getResultsStart, getResultsSuccess, getResultsError,
    refreshResultsStart, refreshResultsSuccess, refreshResultsError,
    deleteCandidateStart, deleteCandidateSuccess, deleteCandidateError,
    resetActionType,
    setSelectedCandidate
} = ResultSlice.actions

export default ResultSlice.reducer