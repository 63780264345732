import './App.css';

// Redux imports
import React from 'react';
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import store, { persistor } from "./redux/store";
import RootLayout from './router/RootLayout';
import { Flowbite, useTheme, useThemeMode } from 'flowbite-react';
import { twMerge } from 'tailwind-merge';
import ReactGA from 'react-ga';



function App() {

  const themeHook = useTheme().theme;
  const [mode, setMode, toggleMode] = useThemeMode(true);

  React.useEffect(() => {
    //analytics for page views
    ReactGA.pageview(window.location.pathname + window.location.search);
    setMode("light");
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Flowbite theme={{
          theme: {
            textInput: {
              field: {
                input: {
                  base: twMerge(themeHook.textInput.field.base, "border-2 "),
                  colors: {
                    primary: twMerge(themeHook.textInput.field.input.colors.gray, "border-primary-500 disabled:bg-gray-200 bg-white"),
                  }
                }
              }
            },

            button: {
              base: "p-0.5 flex items-center justify-center text-white font-bold",
              inner: {
                outline: "rouned-none"
                // base: twMerge(themeHook.button.inner.base, "rounded-none")
              },
              color: {
                primary: twMerge("bg-primary-500"),
                secondary: "bg-secondary-500",
                default: "bg-yellow-500",
                warning: "bg-cyan-500"
              }
            },

            fileInput: {
              field: {
                input: {
                  colors: {
                    // primary: twMerge( themeHook.fileInput.field.input.colors.gray,"bg-primary-500")
                  }
                }
              }

            },
            navbar: {
              root: {

                base: twMerge(themeHook.navbar.root.base, "bg-primary-500 rounded-none"),
              },
            },

            sidebar: {
              root: {
                inner: twMerge(themeHook.sidebar.root.inner, "bg-primary-500 rounded-none"),
              },

              //main menu with submenus
              collapse: {
                button: twMerge(themeHook.sidebar.collapse.button, "text-white font-bold hover:bg-secondary-500"),
                icon: {
                  base: twMerge(themeHook.sidebar.collapse.icon.base, "text-white hover:text-white"),
                  open: {
                    off: '',
                    on: 'text-white',
                  },
                }

              },
              //sub menus
              item: {
                base: twMerge(themeHook.sidebar.item.base, "text-white font-bold hover:bg-secondary-500"),
                active: twMerge(themeHook.sidebar.item.active, 'bg-secondary-500'),
                icon: {
                  base: twMerge(themeHook.sidebar.item.icon.base, "text-white"),
                }

              }

            },

            radio: {
              root: {
                base: twMerge(themeHook.radio.root.base, "border-2 border-primary-500 focus:ring-primary-500 hover:ring-1 hover:ring-primary-500 text-primary-500 disabled:bg-tertiary-400")
              }
            },

            select: {
              field: {
                select: {
                  base: twMerge(themeHook.select.field.select.base, "border-2"),
                  colors: {
                    primary: twMerge(themeHook.select.field.select.colors.gray, "border-primary-500 bg-white")
                  }
                }
              }
            },

            table: {
              head: {
                cell: {
                  base: twMerge(themeHook.table.head.cell.base, "bg-primary-500")
                }
              }

            },
            accordion: {
              title: {
                base: twMerge(themeHook.accordion.title.base, "bg-primary-500 hover:bg-primary-500 text-white")

              }
            },

            tab: {
              tablist: {
                base: twMerge(themeHook.tab.tablist.base, "items-center justify-center"),
                styles: {
                  default: twMerge(themeHook.tab.tablist.styles.default, "border-primary-500 border-b-2")
                },
                tabitem:{
                  base:twMerge( themeHook.tab.tablist.tabitem.base, "font-bold"),
                  styles:{
                    default:{
                      active:{
                        on: twMerge( themeHook.tab.tablist.tabitem.styles.default.active.on, "rounded-t-md bg-primary-500 text-white font-bold")
                      }
                    }
                  }

                }
              },
            },

            timeline:{
              item:{
                point:{
                  marker:{
                    icon: {
                      base: twMerge( themeHook.timeline.item.point.marker.icon.base, "text-white"),
                      wrapper: twMerge( themeHook.timeline.item.point.marker.icon.wrapper,"bg-primary-500"),
                    }
                  }
                }
              }
            }

          }
        }}>
          <RootLayout />
        </Flowbite>
      </PersistGate>
    </Provider>
  )

}

export default App;
