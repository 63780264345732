import { createSlice } from '@reduxjs/toolkit'

const INITIAL_STATE = {
  ACTION_TYPE: "",

  isLoginWithGmailLoading: false,
  isSignupWithGmailLoading: false,
  isLogOutLoading: false,

  loginWithGmailMessage: "",
  signupWithGmailMessage: "",
  logOutMessage: "",

  isLoggedIn: false,

  user_id: "",
  user: {},


}

export const AuthenticateSlice = createSlice({
  name: 'authenticate',
  initialState: INITIAL_STATE,
  reducers: {

    loginWithGmailStart: state => {
      state.ACTION_TYPE = loginWithGmailStart.toString()
      state.isLoginWithGmailLoading = true
      state.loginWithGmailMessage = ""
      state.user = {}
    },

    loginWithGmailSuccess: (state, action) => {
      state.ACTION_TYPE = loginWithGmailSuccess.toString()
      state.isLoginWithGmailLoading = false
      state.loginWithGmailMessage = ""
      state.isLoggedIn = true;
      state.user_id = action.payload.user_id;
      state.user = action.payload.user;
    },
    signupWithGmailSuccess: (state, action) => {
      state.ACTION_TYPE = signupWithGmailSuccess.toString()
      state.isSignupWithGmailLoading = false
      state.isLoginWithGmailLoading = false

      state.signupWithGmailMessage = ""
      state.isLoggedIn = true;
      state.user_id = action.payload.user_id;
      state.user = action.payload.user;
    },
    
    loginWithGmailError: state => {
      state.ACTION_TYPE = loginWithGmailError.toString()
      state.isLoginWithGmailLoading = false
      state.isSignupWithGmailLoading = false

      state.loginWithGmailMessage = "Login failed"
      state.isLoggedIn = false;
    },
    
    
    validateUserSuccess: (state, action) => {
      state.ACTION_TYPE = validateUserSuccess.toString()
      state.isLoggedIn = true;
      state.user_id = action.payload.user_id;
      // state.user = {...state.user,  ...action.payload.user};
    },

    appendUserData: (state, action) => {
      state.ACTION_TYPE = appendUserData.toString();
      state.user = {...state.user ,...action.payload.data};
    },


    
    
    //logout
    logOutStart: state => {
      state.ACTION_TYPE = logOutStart.toString();
      state.isLogOutLoading = true;

    },
    logOutSuccess: (state) => {
      state.ACTION_TYPE = logOutSuccess.toString();
      state.isLogOutLoading = false;
      state.isLoggedIn = false;
      state.user = {};
      state.token = "";

    },
    logOutError: (state) => {
      state.ACTION_TYPE = logOutError.toString();
      state.isLogOutLoading = false;
    },


    //reset 
    resetActionType: state => {
      state.ACTION_TYPE = "";
    },

  }
})

// Action creators are generated for each case reducer function
export const
  {
    logOutStart, logOutSuccess, logOutError,
    loginWithGmailStart,signupWithGmailSuccess, loginWithGmailSuccess, loginWithGmailError,
    validateUserSuccess,appendUserData,
    resetActionType
  } = AuthenticateSlice.actions

export default AuthenticateSlice.reducer