import { Button, Spinner } from "flowbite-react";
import { useFormik } from "formik";
import { toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { text } from "../../constants/tailwind-theme";

import { IoLogoGoogle } from "react-icons/io5";
import { twMerge } from "tailwind-merge";
import logo_white from "../../assets/images/vote_logo_white_bg.png";
import WebsiteCreditsComponent from "../../components/website/WebsiteCreditsComponent";
import { loginWithGmailMiddleware } from "../../redux/middlewares/AuthenticateMiddleware";
import { loginWithGmailError, loginWithGmailSuccess, signupWithGmailSuccess, resetActionType } from "../../redux/slices/AuthenticateSlice";
import { createSpreadsheetMiddleware } from "../../redux/middlewares/SpreadsheetMiddleware";
import React from "react";
import { createSpreadsheetError, createSpreadsheetSuccess , resetActionType as resetActionTypeSpreadsheet } from "../../redux/slices/SpreadSheetSlice";

const AdminLoginPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const spreadsheetState = useSelector(state => state.spreadsheet)
    const authenticateState = useSelector(state => state.authenticate)

    React.useEffect(() => {

        if (authenticateState.ACTION_TYPE === loginWithGmailSuccess.toString()) {

            navigate("/admin")
            dispatch(resetActionType());

        } if (authenticateState.ACTION_TYPE === signupWithGmailSuccess.toString()) {

            dispatch(createSpreadsheetMiddleware(authenticateState.user.email, authenticateState.user_id));
            dispatch(resetActionType());
            
            
        } else if (authenticateState.ACTION_TYPE === loginWithGmailError.toString()) {
            toast.error(authenticateState.loginWithGmailMessage);
            dispatch(resetActionType());
        }

    }, [authenticateState.ACTION_TYPE]);


    React.useEffect(() => {

        if (spreadsheetState.ACTION_TYPE === createSpreadsheetSuccess.toString()) {
            
            
            toast.success(spreadsheetState.createSpreadsheetMessage,{ duration:12000});
            dispatch(resetActionTypeSpreadsheet());
            navigate("/admin")

        } if (spreadsheetState.ACTION_TYPE === createSpreadsheetError.toString()) {
            toast.error(spreadsheetState.createSpreadsheetMessage);
            dispatch(resetActionTypeSpreadsheet());

        }

    }, [spreadsheetState.ACTION_TYPE]);


    const formik = useFormik({
        initialValues: {
            votingId: "",
        },

        validate: (values) => {
            const errors = {};
            if (!values.votingId) {
                errors.votingId = "votingId is required.";
            }
            return errors;
        },

        onSubmit: (values) => {

        },
    });

    return (
        <div className="flex flex-col items-center spaced-y-4 w-full h-screen">

            <h1 className={twMerge(text.heading1, "mt-8 text-center")} >Welcome to 'Vote by heyphord'</h1>

            <img src={logo_white} className="w-32 mb-10" />


            <p className={twMerge(text.description1, "mb-2")}>Are you new? Signup to get started !</p>
            <Button disabled={authenticateState.isSignupWithGmailLoading || authenticateState.isLoginWithGmailLoading || spreadsheetState.isCreateSpreadsheetLoading} onClick={() => { dispatch(loginWithGmailMiddleware()) }} outline> {authenticateState.isSignupWithGmailLoading || authenticateState.isLoginWithGmailLoading || spreadsheetState.isCreateSpreadsheetLoading ? <Spinner className="mr-3 h-5 w-5" /> : <IoLogoGoogle className="mr-3 h-5 w-5" />}  Sign up with Google</Button>

            <p className={twMerge(text.description1, "my-4")}>or</p>

            <p className={twMerge(text.description1, "mb-2")}>Already have an account? Login to continue</p>
            <Button disabled={authenticateState.isSignupWithGmailLoading || authenticateState.isLoginWithGmailLoading || spreadsheetState.isCreateSpreadsheetLoading} onClick={() => { dispatch(loginWithGmailMiddleware()) }} outline> { authenticateState.isSignupWithGmailLoading || authenticateState.isLoginWithGmailLoading || spreadsheetState.isCreateSpreadsheetLoading? <Spinner className="mr-3 h-5 w-5" /> : <IoLogoGoogle className="mr-3 h-5 w-5" />}  Login with Google</Button>


            <WebsiteCreditsComponent tailwindClassname="w-full mt-20 absolute  bottom-0" />
        </div>
    )
}

export default AdminLoginPage;