import { auth } from "../../constants/firebase-config"
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS"
import {  setFirebaseDoc, updateFirebaseDoc } from "../../util/firebaseFirestoreUtils"
import { 
    addElectionError, addElectionStart, addElectionSuccess, 
    editElectionError, editElectionStart, editElectionSuccess, 
    publishElectionError, publishElectionStart, publishElectionSuccess, 
} from "../slices/ElectionSlice"
import ReactGA from 'react-ga';

export const addElectionMiddleware = (id, election) => {
    return async (dispatch) => {
        dispatch(addElectionStart())
        try {
            await setFirebaseDoc(`${FIREBASECOLLECTIONS.ELECTIONS}/${id}`, election)
            dispatch(addElectionSuccess({ election: election }));
            ReactGA.event({ category:"ELECTIONS", action :"ADD ELECTION SUCCESS" });

        } catch (error) {
            const errorCode = error.code
            console.log(`error: ${error}`)
            dispatch(addElectionError({ message: errorCode ?? error }))
            ReactGA.event({ category:"ERRORS", action :"ADD ELECTION ERROR" });

        }
    }
}



export const editElectionMiddleware = (electionId, election) => {
    return async (dispatch) => {
        dispatch(editElectionStart())
        try {
            await updateFirebaseDoc(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}`, election)
            dispatch(editElectionSuccess({ election: election }))
            ReactGA.event({ category:"ELECTIONS", action :"EDIT ELECTION SUCCESS" });

        } catch (error) {
            console.log(error);
            dispatch(editElectionError({ message: error.message }))
            ReactGA.event({ category:"ERRORS", action :"EDIT ELECTION ERROR" });

        }

    }
}

export const publishElectionMiddleware = (electionId, ELECTION_STATUS) => {
    return async (dispatch) => {
        dispatch(publishElectionStart())
        try {
            await updateFirebaseDoc(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}`, { status: ELECTION_STATUS})
            dispatch(publishElectionSuccess({ message: `Election ${ELECTION_STATUS} successfully`  }))
            ReactGA.event({ category:"ELECTIONS", action :"PUBLISH ELECTION SUCCESS" });

        } catch (error) {
            console.log(error);
            dispatch(publishElectionError({ message: error.message }))
            ReactGA.event({ category:"ERRORS", action :"PUBLISH ELECTION ERROR" });

        }

    }
}
