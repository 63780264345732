import React from "react";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const PageTitleComponent = ({ title, subtitle, rightComponent }) => {



    return (
        <div className="flex flex-row  flex-wrap items-center sm:justify-between justify-center border bg-white w-full p-4">
            <div className="flex flex-col justify-start items-start">
                <h2 className={twMerge(text.heading2)}>{title}</h2>
                <h3 className={twMerge(text.description2, "text-start mb-1")}>{subtitle}</h3>
            </div>

            {rightComponent}
        </div>

    )
}

export default PageTitleComponent;