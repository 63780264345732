import { uuidv4 } from "@firebase/util";
import { Button, FileInput, Label, Select, Spinner, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import PageTitleComponent from "../../components/PageTitleComponent";
import { addElectionMiddleware } from "../../redux/middlewares/ElectionMiddleware";
import { uploadFileToS3Middleware } from "../../redux/middlewares/FileMiddleware";
import { createElectionSheetMiddleware } from "../../redux/middlewares/SpreadsheetMiddleware";
import { addElectionError, addElectionSuccess } from "../../redux/slices/ElectionSlice";
import { resetActionType, setUploadType, uploadFileError, uploadFileSuccess } from "../../redux/slices/FileSlice";
import { getFormErrorMessage, isFormFieldValid } from "../../util/formikUtils";
import { PAYMENT_PLANS } from "../../constants/PRICINGPLAN";
import { ELECTION_TYPES } from "../../constants/ELECTIONTYPES";
import { ELECTION_STATUS } from "../../constants/ELECTIONSTATUS";

const AddElectionPage = () => {


  const dispatch = useDispatch();
  const authenticateState = useSelector(state => state.authenticate);
  const electionState = useSelector(state => state.election);
  const fileState = useSelector((state) => state.file);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      name: "",
      startDate: "",
      startTime: "",
      endDate: "",
      endTime: "",
      type: ELECTION_TYPES.PUBLIC,
      paymentPlan: PAYMENT_PLANS.FREE,
      voteLimit: 0,
      file: null,
    },

    validate: (values) => {
      const errors = {};
      if (!values.name) {
        errors.name = "name is required.";
      }

      if (!values.startDate) {
        errors.startDate = "Start date is required.";
      }

      if (!values.startTime) {
        errors.startTime = "Start time is required";
      }
      if (!values.endDate) {
        errors.endDate = "End date is required.";
      }

      if (!values.endTime) {
        errors.endTime = "End time is required";
      }

      let startDateTime = moment(values.startDate + "T" + values.startTime);
      let endDateTime = moment(values.endDate + "T" + values.endTime);

      if (startDateTime.isAfter(endDateTime)) {
        errors.startDate = "Start Date/Time must be before End Date/Time"
        errors.endDate = "Start Date/Time must be before End Date/Time"
      }

      if (!values.type) {
        errors.file = "Please select election type";
      }

      if (!values.file) {
        errors.file = "Please select a file to upload";
      }

      if (values.file && (values.file.size > 1000000)) {
        errors.file = "The file selected cannot exceed 1mb";
      }
      return errors;
    },

    onSubmit: (values) => {
      //upload file first, to get the file url

      dispatch(setUploadType("ADD_ELECTION"));
      dispatch(uploadFileToS3Middleware(values.file, "election_logo"));

    },
  });


  React.useEffect(() => {

    if (fileState.ACTION_TYPE === uploadFileSuccess.toString() && fileState.UPLOAD_TYPE === "ADD_ELECTION") {

      //upload successfull, now create an new election using the new imageURL

      const electionId = uuidv4()

      const startDateTimeUTC = moment.utc(formik.values.startDate + "T" + formik.values.startTime).toISOString()
      const endDateTimeUTC = moment.utc(formik.values.endDate + "T" + formik.values.endTime).toISOString()

      const election = {
        id: electionId,
        userId:authenticateState.user_id,
        name: formik.values.name,
        imageUrl: fileState.uploadURL,
        startDate: formik.values.startDate,
        startTime: formik.values.startTime,
        endDate: formik.values.endDate,
        endTime: formik.values.endTime,
        paymentPlan: formik.values.paymentPlan,
        voteLimit: formik.values.voteLimit,
        type: formik.values.type,
        totalVotes: 0,
        categories: [],
        status: ELECTION_STATUS.UNPUBLISHED,
        startDateTimeUTC: startDateTimeUTC,
        endDateTimeUTC: endDateTimeUTC,
        createdAt: moment.utc().toISOString()
      }

      dispatch(addElectionMiddleware(electionId, election))

      dispatch(resetActionType());
    } else if (fileState.ACTION_TYPE === uploadFileError.toString()) {
      toast.error(fileState.uploadFileMessage);
      dispatch(resetActionType());
    }

  }, [fileState.ACTION_TYPE]);

  React.useEffect(() => {

    if (electionState.ACTION_TYPE === addElectionSuccess.toString()) {
      toast.success("Election created successfully");
      formik.resetForm();

      //create a google sheet for this election
      dispatch(createElectionSheetMiddleware(
        authenticateState.user.spreadsheetId,
        electionState.selectedElection.id,
        authenticateState.user_id
      ));
      navigate("/admin/election/view")

    } else if (electionState.ACTION_TYPE === addElectionError.toString()) {

      toast.error("There was an error creating your election");

    }

  }, [electionState.ACTION_TYPE]);

  return (
    <div className="flex flex-col items-center">
      <PageTitleComponent
        title="Create New Election"
      />


      <div className="flex flex-col items-center w-full md:w-6/12 space-y-4 border-0 p-2 mt-10" >

        {/* Election name */}
        <div className="w-full">
          <div className="mb-2 block">
            <Label className="mb-2 block" htmlFor="email1" value="Name *" />
          </div>
          <TextInput
            id="name"
            name="name"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.name}
            placeholder="Election Name"
            required={true}
            color={isFormFieldValid(formik, "name") ? "primary" : "failure"}
            helperText={getFormErrorMessage(formik, "name")}
          />
        </div>

        {/* Start date */}
        <div className="w-full">
          <div className="mb-2 block">
            <Label className="mb-2 block" htmlFor="email1" value="Start Date *" />
          </div>
          <TextInput
            type={"date"}
            id="startDate"
            name="startDate"
            min={moment().format("YYYY-MM-DD")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.startDate}
            placeholder="Start Date"
            required={true}
            color={isFormFieldValid(formik, "startDate") ? "primary" : "failure"}
            helperText={getFormErrorMessage(formik, "startDate")}
          />
        </div>

        {/* Start time */}
        <div className="w-full">
          <div className="mb-2 block">
            <Label className="mb-2 block" htmlFor="email1" value="Start Time *" />
          </div>
          <TextInput
            type={"time"}
            id="startTime"
            name="startTime"
            min={moment().format("HH:mm")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.startTime}
            placeholder="Start Time"
            required={true}
            color={isFormFieldValid(formik, "startTime") ? "primary" : "failure"}
            helperText={getFormErrorMessage(formik, "startTime")}
          />
        </div>

        {/* End date */}
        <div className="w-full">
          <div className="mb-2 block">
            <Label className="mb-2 block" htmlFor="email1" value="End Date *" />
          </div>
          <TextInput
            type={"date"}
            id="endDate"
            name="endDate"
            min={moment().format("YYYY-MM-DD")}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.endDate}
            placeholder="End Date"
            required={true}
            color={isFormFieldValid(formik, "endDate") ? "primary" : "failure"}
            helperText={getFormErrorMessage(formik, "endDate")}
          />
        </div>

        {/* End time */}
        <div className="w-full">
          <div className="mb-2 block">
            <Label className="mb-2 block" htmlFor="email1" value="End Time *" />
          </div>
          <TextInput
            type={"time"}
            id="endTime"
            name="endTime"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.endTime}
            placeholder="Start Time"
            required={true}
            color={isFormFieldValid(formik, "endTime") ? "primary" : "failure"}
            helperText={getFormErrorMessage(formik, "endTime")}
          />
        </div>

        <div className="w-full">
          <div className="mb-2 block">
            <Label
              htmlFor="type"
              value="Select your election type *"
            />
          </div>
          <Select
            id="type"
            name="type"
            value={formik.values.type}
            onChange={(e) => {

              //free 20 vote limit for private election
              if (e.target.value === ELECTION_TYPES.PRIVATE) {

                formik.setFieldValue("voteLimit", 20)
                formik.setFieldValue("paymentPlan", PAYMENT_PLANS.PAY_PER_VOTE);
                formik.setFieldValue("type", e.target.value);

              } else {

                formik.setFieldValue("voteLimit", 0)
                formik.setFieldValue("paymentPlan", PAYMENT_PLANS.FREE);
                formik.setFieldValue("type", e.target.value);

              }
            }
            }

            required={true}
            color={isFormFieldValid(formik, "type") ? "primary" : "failure"}
            helperText={getFormErrorMessage(formik, "type")}
          >
            <option value={ELECTION_TYPES.PUBLIC}>
              Public Election - Free and Open to all
            </option>
            <option value={ELECTION_TYPES.PRIVATE}>
              Private Election - Only Selected people can vote
            </option>
          </Select>
        </div>

        <div className="w-full">
          <div className="mb-2 block">
            <Label
              htmlFor="file"
              value="Upload a logo for this election *"
            />
          </div>
          <FileInput
            accept="image/*"
            id="file"
            name="file"
            type={"file"}
            onChange={(e) => {
              formik.setFieldValue("file", e.target.files[0]);
            }}
            onBlur={formik.handleBlur}
            placeholder="End time"
            color={isFormFieldValid(formik, "file") ? "primary" : "failure"}
            helperText={getFormErrorMessage(formik, "file")}
          />
        </div>



        <Button disabled={fileState.isUploadFileLoading || electionState.isEditElectionLoading} color="primary" onClick={formik.handleSubmit}>
          {fileState.isUploadFileLoading || electionState.isAddElectionLoading ? <Spinner className="mr-3 h-5 w-5" /> : null}
          SUBMIT
        </Button>

      </div>


    </div>
  )
}

export default AddElectionPage;