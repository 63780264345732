import { Modal, Spinner, Button, Label, TextInput, FileInput } from "flowbite-react";
import { useFormik } from "formik";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCandidateMiddleware } from "../redux/middlewares/CandidateMiddleware";
import { uploadFileToS3Middleware } from "../redux/middlewares/FileMiddleware";
import { editCandidateSuccess, resetActionType as resetActionTypeCandidate } from "../redux/slices/CandidateSlice";
import { uploadFileSuccess, resetActionType, setUploadType } from "../redux/slices/FileSlice";
import { getFormErrorMessage, isFormFieldValid } from "../util/formikUtils";
import toast from "react-hot-toast";
import ReactGA from 'react-ga';

import CreatableSelect from 'react-select/creatable';
import { editElectionMiddleware } from "../redux/middlewares/ElectionMiddleware";

const EditCandidateModal = ({ onToggle, show, selectedCandidate }) => {

    const dispatch = useDispatch()
    const electionState = useSelector(state => state.election)


    const fileState = useSelector((state) => state.file)
    const candidateState = useSelector(state => state.candidate)

    const selectOptions = React.useMemo(() => {
        return electionState?.selectedElection.categories.map((value, index, arrray) => {
            return { value: index, label: value }
        });

    }, [electionState?.selectedElection.categories]);

    const [options, setOptions] = React.useState(selectOptions)



    const formik = useFormik({
        initialValues: {
            category: { value: -1, label: selectedCandidate.category },
            candidateName: selectedCandidate.name,
            file: null,
        },

        validate: (values) => {
            const errors = {};
            if (!values.category) {
                errors.category = "category is required.";
            }

            if (!values.candidateName) {
                errors.candidateName = "Candidate Name is required.";
            }

            if (values.file && (values.file.size > 1000000)) {
                errors.file = "The file selected cannot exceed 1mb";
            }


            return errors;
        },

        onSubmit: (values) => {
            console.log(values);
            if (values.file) {
                dispatch(setUploadType("EDIT_CANDIDATE"));
                dispatch(uploadFileToS3Middleware(values.file, "candidate_images"));
            }
            else {
                const editedCandidate = {
                    id: selectedCandidate.id,
                    name: formik.values.candidateName,
                    category: formik.values.category.label,
                }

                //get only the selected category
                const filteredCategory = options.filter((option) => (!option.isNew || option.isNew && option.value === formik.values.category.value))
                const categoryLabels = filteredCategory.map((category) => category.label);

                const editedElection = {
                    categories: categoryLabels
                }

                dispatch(editCandidateMiddleware(electionState.selectedElection.id, selectedCandidate.id, editedCandidate))
                dispatch(editElectionMiddleware(electionState.selectedElection.id, editedElection));

                dispatch(resetActionType())
            }
        },
    });

    React.useEffect(() => {
        if (fileState.ACTION_TYPE === uploadFileSuccess.toString() && fileState.UPLOAD_TYPE === "EDIT_CANDIDATE") {
            const editedCandidate = {
                id: selectedCandidate.id,
                name: formik.values.candidateName,
                imageUrl: fileState.uploadURL,
                category: formik.values.category.label,
            }

            //get only the selected category
            const filteredCategory = options.filter((option) => (!option.isNew || option.isNew && option.value === formik.values.category.value))
            const categoryLabels = filteredCategory.map((category) => category.label);

            const editedElection = {
                categories: categoryLabels
            }


            dispatch(editCandidateMiddleware(electionState.selectedElection.id, selectedCandidate.id, editedCandidate))
            dispatch(editElectionMiddleware(electionState.selectedElection.id, editedElection));
            dispatch(resetActionType())
        }

    }, [fileState.ACTION_TYPE])

    React.useEffect(() => {
        if (candidateState.ACTION_TYPE === editCandidateSuccess.toString()) {
            toast.success("Candidate details updated successfully")
            dispatch(resetActionTypeCandidate())
            formik.resetForm();
            onToggle();
        }
    }, [candidateState.ACTION_TYPE])

    React.useEffect(() => {
        ReactGA.modalview("edit-candidate")
    })

    return (
        <div
            style={{ backgroundColor: "rgba(0,0,0,0.5)" }}
            className="absolute w-full h-ful top-0 bottom-0 left-0 right-0"
        >
            <div className="flex flex-col border items-center justify-center w-full h-full">

                <div className="flex flex-col bg-white w-96 p-8 gap-4 rounded-md">

                    <div>
                        Edit candidate details
                    </div>

                    <div>
                        <form className="flex flex-col space-y-4"  >

                            <div className="w-full">
                                <div className="mb-2 block">
                                    <Label className="mb-2 block" htmlFor="email1" value="Candidate Name" />
                                </div>
                                <TextInput
                                    id="candidateName"
                                    name="candidateName"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.candidateName}
                                    placeholder="Enter candiate name"
                                    required={true}
                                    color={isFormFieldValid(formik, "candidateName") ? "primary" : "failure"}
                                    helperText={getFormErrorMessage(formik, "candidateName")}
                                />
                            </div>

                            <div className="w-full">
                                <div className="mb-2 block">
                                    <Label className="mb-2 block" htmlFor="email1" value="Type to create a new category or Select an existing one" />
                                </div>
                                <CreatableSelect
                                    styles={{
                                        control: (baseStyles, state) => ({
                                            ...baseStyles,
                                            backgroundColor: isFormFieldValid(formik, "category") ? "white" : "#FDF2F2",
                                            borderWidth: 2,
                                            borderRadius: "0.5rem",
                                            borderColor: isFormFieldValid(formik, "category") ? "#024681" : "#E02424",
                                        }),
                                    }}
                                    formatCreateLabel={(value => `Create "${value}" category`)}
                                    isClearable
                                    // isDisabled={true}
                                    // isLoading={true}
                                    placeholder="Create or select a category..."
                                    onChange={(newValue) => { console.log(newValue); formik.setFieldValue("category", newValue) }}
                                    onCreateOption={(inputvalue) => {

                                        const newValue = { value: Date.now(), label: inputvalue, isNew: true };
                                        setOptions(prev => [...prev, newValue]);
                                        formik.setFieldValue("category", newValue);

                                    }}
                                    options={options}
                                    value={formik.values.category}
                                />
                                <span className="text-red-500">{getFormErrorMessage(formik, "category")}</span>


                            </div>

                            <div className="w-full">
                                <div className="mb-2 block">
                                    <Label
                                        htmlFor="file"
                                        value="Choose another image? (Optional)"
                                    />
                                </div>
                                <FileInput
                                    accept="image/*"
                                    id="file"
                                    name="file"
                                    type={"file"}
                                    onChange={(e) => {
                                        formik.setFieldValue("file", e.target.files[0]);
                                    }}
                                    onBlur={formik.handleBlur}
                                    placeholder="End time"
                                    color={isFormFieldValid(formik, "file") ? "primary" : "failure"}
                                    helperText={getFormErrorMessage(formik, "file")}
                                />
                            </div>
                        </form>

                    </div>

                    <div className="flex flex-row justify-center gap-2  ">
                        <Button
                            disabled={fileState.isUploadFileLoading || candidateState.isEditCandidateLoading}
                            onClick={formik.handleSubmit}>
                            {fileState.isUploadFileLoading || candidateState.isEditCandidateLoading ? <Spinner /> : "SUBMIT"}
                        </Button>
                        <Button outline onClick={onToggle}>
                            CANCEL
                        </Button>
                    </div>
                </div>
            </div>

        </div>

    )
}

export default EditCandidateModal;