import axios from "axios"
import { CREATE_ADMIN_GOOGLESPREADSHEET_URL, CREATE_ADMIN_SHEET_URL } from "../../constants/URLS"
import { appendUserData } from "../slices/AuthenticateSlice"
import { createSheetError, createSheetStart, createSheetSuccess, createSpreadsheetError, createSpreadsheetStart, createSpreadsheetSuccess } from "../slices/SpreadSheetSlice"
import ReactGA from 'react-ga';

export const createSpreadsheetMiddleware = (email, adminId) => {
    return async (dispatch) => {
        dispatch(createSpreadsheetStart());

        return axios.post(
            CREATE_ADMIN_GOOGLESPREADSHEET_URL,

            {
                email,
                adminId,
            }

        ).then(function (response) {

            console.log(response.data);
            dispatch(createSpreadsheetSuccess({message:response.data.message , spreadsheet: response.data.data}));
            dispatch(appendUserData({data:response.data.data}));
            ReactGA.event({ category:"SPREADSHEETS", action :"CREATE SPREADSHEET SUCCESS" });


        }).catch(function (error) {
            
            ReactGA.event({ category:"ERRORS", action :"CREATE SPREADSHEET ERROR" });
            if (error.response) {
                console.log(error.response);
                dispatch(createSpreadsheetError({ message: error.response.data.message }));
                return;
            }
            dispatch(createSpreadsheetError({ message: error.message }));


        });

       
    }
}

export const createElectionSheetMiddleware = (spreadSheetId,electionId, adminId) => {
    return async (dispatch) => {
        dispatch(createSheetStart());

        return axios.post(
            CREATE_ADMIN_SHEET_URL,

            {
                spreadSheetId,
                electionId,
                adminId
            }

        ).then(function (response) {

            console.log(response.data);
            dispatch(createSheetSuccess({message:response.data.message}));
            ReactGA.event({ category:"SPREADSHEETS", action :"CREATE SHEET SUCCESS" });

        }).catch(function (error) {
            ReactGA.event({ category:"ERRORS", action :"CREATE SHEET ERROR" });

            if (error.response) {
                console.log(error.response);
                dispatch(createSheetError({ message: error.response.data.message }));
                return;
            }
            dispatch(createSheetError({ message: error.message }));


        });

       
    }
}

