import { Label, Radio, Table } from 'flowbite-react';
import React, { useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { text } from '../constants/tailwind-theme';

function MultipleBallotComponent({ candidate, onVote }) {
    const [selectedOption, setSelectedOption] = useState('');


    return (
        <Table.Row key={candidate?.id}>

            <Table.Cell className='border '>
                <img className='w-full sm:w-32 h-32 object-center object-cover' src={candidate?.imageUrl} />
            </Table.Cell>

            <Table.Cell className='border'>
                <h3 className={twMerge(text.heading3)}>{candidate?.name}</h3>
            </Table.Cell>

            <Table.Cell className='border'>
               
                <div className="flex items-center justify-center gap-2">
                    <Radio
                        style={{width:45 , height:45, borderColor:"blue" , borderWidth:2}}
                        name={candidate?.category}
                        required
                        type="radio"
                        value="YES"
                        defaultChecked={selectedOption === "YES"}
                        onChange={(e) => {
                            setSelectedOption(e.target.value);
                            onVote({
                                candidateName: candidate.name,
                                candidateImageUrl : candidate.imageUrl,
                                candidateId: candidate?.id,
                                voteType: "YES",
                                category: candidate?.category
                            })
                        }}
                    />
                    {/* <Label htmlFor="yes">
                        YES
                    </Label> */}
                </div>


            </Table.Cell>
        </Table.Row>

    );
}

export default MultipleBallotComponent;