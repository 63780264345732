import { Avatar, Navbar } from "flowbite-react";
import React from "react";

import { HiMenuAlt1 } from 'react-icons/hi';
import { useSelector } from "react-redux";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const ElectionNavbarComponent = (props) => {

    const { toggleCollapse } = props;

    const electionsState = useSelector(state => state.election)
    const authenticateState = useSelector(state => state.authenticate);
    
    return (


        <Navbar 
        
        fluid 
        >
            <div className="flex items-center">
                <HiMenuAlt1
                    className="mr-6 h-6 w-6 cursor-pointer text-white "
                    onClick={toggleCollapse}
                    
                />
                <span className={twMerge( text.heading2,"text-white")}>{electionsState?.selectedElection?.name}</span>

            </div>
            <div className="flex items-center gap-2">
            <Avatar alt="User settings" img={authenticateState.user.imageUrl} rounded={true} />
                
            </div>
        </Navbar>
    )
}
export default ElectionNavbarComponent;