import { Link, useNavigate } from "react-router-dom";
import { Badge, Button, Spinner } from "flowbite-react";
import PageTitleComponent from "../../components/PageTitleComponent";
import DataTableComponent from "../../components/DataTableComponent";
import React from "react";
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { useDispatch, useSelector } from "react-redux";
import { getPaymentsError, getPaymentsStart, getPaymentsSuccess } from "../../redux/slices/PaymentSlice";
import { getFirebaseDocs } from "../../util/firebaseFirestoreUtils";
import { onSnapshot, orderBy, where } from "firebase/firestore";

const ViewPaymentsPage = () => {

    const dispatch = useDispatch();
    const authenticateState = useSelector(state => state.authenticate);
    const paymentState = useSelector(state => state.payment);
    const navigate = useNavigate();

    React.useEffect(() => {

        const collecitonRef = getFirebaseDocs(FIREBASECOLLECTIONS.PAYMENTS,
            [
                where("userId", "==", authenticateState.user_id),
                orderBy("createdAt", "desc")
            ])

        dispatch(getPaymentsStart());


        const unsubscribeListener = onSnapshot(collecitonRef,
            {
                next: (querySnapshot) => {
                    // console.log("on snap callback called for get voted list");
                    try {
                        let payments = []

                        querySnapshot.forEach((doc) => {
                            payments.push(doc.data());
                        });


                        dispatch(getPaymentsSuccess({ payments: payments }));
                    } catch (error) {
                        dispatch(getPaymentsError({ message: error.message }))
                    }

                }
                ,
                error: (error) => {
                    console.log(error);
                    dispatch(getPaymentsError({ message: error.message }))

                }
            }
        );

        return () => {
            unsubscribeListener();
        }

    }, []);
    return (
        <div className="flex  flex-col gap-4">
            <PageTitleComponent
                title="Payments"
            />
            

            {
                paymentState.isGetPaymentsLoading ? <Spinner /> :
                    <DataTableComponent
                        data={paymentState.payments}
                        columns={[
                            {
                                name: "TXN REF",
                                selector: (row) => row.id,
                            },
                            {
                                name: "Election Name",
                                selector: (row) => row?.electionName,
                            },
                            {
                                name: "Payment Plan",
                                selector: (row) => row.paymentPlan,
                            },
                            {
                                name: "Amount Paid",
                                selector: (row) => row.amount,
                            },
                            {
                                name: "Votes Purchased",
                                selector: (row) => row.votes,
                            },
                            {
                                name: "Payment Status",
                                selector: (row) => 
                                <Badge
                                    color={row.paymentStatus === "SUCCESS" ? "success" :"warning"}>
                                    {row.paymentStatus}
                                </Badge> ,

                            },
                            {
                                name: "Value Status",
                                selector: (row) => 
                                <Badge
                                    color={row.valueStatus === "SUCCESS" ? "success" :"warning"}>
                                    {row.valueStatus}
                                </Badge> ,

                            },
                        ]}
                    />
            }
        </div>
    )
}

export default ViewPaymentsPage;