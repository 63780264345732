import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    ACTION_TYPE: "",

    isGetSearchResultsLoading: false,
    isAddElectionLoading: false,
    isGetElectionsLoading: false,
    isGetElectionLoading: false,
    isPublishElectionLoading: false,
    isEditElectionLoading: false,

    getSearchResultsMessage: "",
    addElectionMessage: "",
    getElectionsMessage: "",
    getElectionMessage: "",
    publishElectionMessage: "",
    editElectionMessage: "",

    selectedElectionIndex: null,
    selectedElection: {},
    searchResults: [],
    elections: [],
    election: {},

}

export const ElectionSlice = createSlice({
    name: 'election',
    initialState: INITIAL_STATE,
    reducers: {
        addElectionStart: state => {
            state.ACTION_TYPE = addElectionStart.toString()
            state.isAddElectionLoading = true
            state.addElectionMessage = ""
        },
        addElectionSuccess: (state, action) => {
            state.ACTION_TYPE = addElectionSuccess.toString()
            state.isAddElectionLoading = false
            state.addElectionMessage = "";
            state.selectedElection = action.payload.election
        },
        addElectionError: (state, action) => {
            state.ACTION_TYPE = addElectionError.toString()
            state.isAddElectionLoading = false
            state.addElectionMessage = action.payload.message
        },


        getElectionsStart: state => {
            state.ACTION_TYPE = getElectionsStart.toString()
            state.isGetElectionsLoading = true
            state.getElectionsMessage = ""
            state.elections = []
        },

        getElectionsSuccess: (state, action) => {
            state.ACTION_TYPE = getElectionsSuccess.toString()
            state.isGetElectionsLoading = false
            state.getElectionsMessage = ""
            state.elections = action.payload.elections
        },

        getElectionsError: (state, action) => {
            state.ACTION_TYPE = getElectionsError.toString()
            state.isGetElectionsLoading = false
            state.getElectionsMessage = action.payload.message
            state.elections = []
        },

        //search results
        getSearchResultsStart: state => {
            state.ACTION_TYPE = getSearchResultsStart.toString()
            state.isGetSearchResultsLoading = true
            state.getSearchResultsMessage = ""
            state.searchResults = []
        },

        getSearchResultsSuccess: (state, action) => {
            state.ACTION_TYPE = getSearchResultsSuccess.toString()
            state.isGetSearchResultsLoading = false
            state.getSearchResultsMessage = ""
            state.searchResults = action.payload.searchResults
        },

        getSearchResultsError: (state, action) => {
            state.ACTION_TYPE = getSearchResultsError.toString()
            state.isGetSearchResultsLoading = false
            state.getSearchResultsMessage = action.payload.message
            state.searchResults = []
        },

        getElectionStart: state => {
            state.ACTION_TYPE = getElectionStart.toString()
            state.isGetElectionLoading = true
            state.getElectionMessage = ""
            state.election = {}
        },

        getElectionSuccess: (state, action) => {
            state.ACTION_TYPE = getElectionSuccess.toString()
            state.isGetElectionLoading = false
            state.getElectionMessage = ""
            state.election = action.payload.election
        },

        getElectionError: (state, action) => {
            state.ACTION_TYPE = getElectionError.toString()
            state.isGetElectionLoading = false
            state.getElectionMessage = action.payload.message
            state.elections = {}
        },

        publishElectionStart: state => {
            state.ACTION_TYPE = publishElectionStart.toString()
            state.isPublishElectionLoading = true
            state.publishElectionMessage = ""
        },
        publishElectionSuccess: (state, action) => {
            state.ACTION_TYPE = publishElectionSuccess.toString()
            state.isPublishElectionLoading = false
            state.publishElectionMessage = action.payload.message
        },
        publishElectionError: (state, action) => {
            state.ACTION_TYPE = publishElectionError.toString()
            state.isPublishElectionLoading = false
            state.publishElectionMessage = action.payload.message
        },

        setSelectedElection: (state, action) => {
            state.ACTION_TYPE = setSelectedElection.toString()
            state.selectedElection = action.payload.selectedElection
            state.selectedElectionIndex = action.payload?.selectedElectionIndex ?? state.selectedElectionIndex
        },

        editElectionStart: state => {
            state.ACTION_TYPE = editElectionStart.toString()
            state.isEditElectionLoading = true
            state.editElectionMessage = ""
        },
        editElectionSuccess: (state, action) => {
            state.ACTION_TYPE = editElectionSuccess.toString()
            state.isEditElectionLoading = false
            state.editElectionMessage = ""
            state.selectedElection ={ ...state.selectedElection, ...action.payload.election}

        },
        editElectionError: (state, action) => {
            state.ACTION_TYPE = editElectionError.toString()
            state.isEditElectionLoading = false
            state.editElectionMessage = action.payload.message
        },


        resetActionType: state => {
            state.ACTION_TYPE = resetActionType.toString();
        },
    }
})

export const {
    addElectionStart, addElectionSuccess, addElectionError,
    getSearchResultsStart, getSearchResultsSuccess, getSearchResultsError,
    getElectionsStart, getElectionsSuccess, getElectionsError,
    getElectionStart, getElectionSuccess, getElectionError,
    publishElectionStart, publishElectionSuccess, publishElectionError,
    editElectionStart, editElectionSuccess, editElectionError,
    resetActionType,
    setSelectedElection,
} = ElectionSlice.actions

export default ElectionSlice.reducer