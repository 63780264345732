import axios from "axios"
import { SHARE_VOTING_LINK_TO_ALL_URL, SHARE_VOTING_LINK_URL, VOTER_LOGIN_URL, VOTER_VERIFY_OTP_URL } from "../../constants/URLS"
import {
    authenticateVoterError, authenticateVoterStart, authenticateVoterSuccess, getVotersError, getVotersStart,
    getVotersSuccess, verifyVoterOTPError, verifyVoterOTPStart, verifyVoterOTPSuccess,
    shareVotingLinkStart, shareVotingLinkSuccess,shareVotingLinkError,
    shareVotingLinkToAllStart, shareVotingLinkToAllSuccess,shareVotingLinkToAllError,


} from "../slices/VoterSlice"
import ReactGA from 'react-ga';

export const getVotersMiddleware = (spreadsheetId, range) => {
    return async (dispatch) => {
        dispatch(getVotersStart())
        try {
            const result = await axios.get(`https://heyphord-backend-apis.herokuapp.com/api/v1/vote/voters/get/${spreadsheetId}/${range}`);
            dispatch(getVotersSuccess({ spreadSheetDetails: result.data }))
            ReactGA.event({ category:"SPREADSHEETS", action :"GET SPREADSHET DETAILS SUCCESS" });

        } catch (error) {
            dispatch(getVotersError({ message: error?.response.message ?? error.message }))
            ReactGA.event({ category:"ERRORS", action :"GET SPREADSHET DETAILS ERRORS" });

        }
    }
}

export const authenticateVoterMiddleware = (email, adminId, electionId) => {
    return async (dispatch) => {
        dispatch(authenticateVoterStart())
        try {
            const result = await axios.post(`${VOTER_LOGIN_URL}/${email}/${adminId}/${electionId}`)
            dispatch(authenticateVoterSuccess({ voter: result.data.data }))
            ReactGA.event({ category:"VOTERS", action :"VOTER LOGIN ERRORS" });

        } catch (error) {
            ReactGA.event({ category:"ERRORS", action :"VOTER LOGIN ERRORS" });

            if (error.response) {
                dispatch(authenticateVoterError({ message: error.response.data.message }))
                return;
            }
            dispatch(authenticateVoterError({ message: error.message }))
        }
    }
}

export const verifyVoterOTPMiddleware = (email, otp, adminId, electionId) => {
    return async (dispatch) => {
        dispatch(verifyVoterOTPStart())
        try {
            const result = await axios.post(`${VOTER_VERIFY_OTP_URL}/${email}/${otp}/${adminId}/${electionId}`)
            dispatch(verifyVoterOTPSuccess({ voter: result.data.data }))
            ReactGA.event({ category:"VOTERS", action :"VOTER VERIFY SUCCESS" });

        } catch (error) {

            ReactGA.event({ category:"ERRORS", action :"VOTER VERIFY ERROR" });
            if (error.response) {
                // console.log(error.response);
                dispatch(verifyVoterOTPError({ message: error.response.data.message }))
                return;
            }
        }
    }
}
export const shareVotingLinkMiddleware = ( adminId, electionId,email) => {
    return async (dispatch) => {
        dispatch(shareVotingLinkStart())
        try {
            const result = await axios.post(
                `${SHARE_VOTING_LINK_URL}`,
                {
                    "adminId":adminId,
                    "electionId":electionId,
                    "voterEmail":email,
                }
                
                )
            dispatch(shareVotingLinkSuccess({ }));
            ReactGA.event({ category:"VOTERS", action :"SHARE VOTING LINK TO ONE AUTOMATED SUCCESS" });

        } catch (error) {

            ReactGA.event({ category:"ERRORS", action :"SHARE VOTING LINK TO ONE AUTOMATED ERROR" });
            if (error.response) {
                console.log(error.response);
                dispatch(shareVotingLinkError({ message: error.response.data.message }))
                return;
            }
            dispatch(shareVotingLinkError({ message: error.message }))

        }
    }
}

export const shareVotingLinkToAllMiddleware = ( adminId, electionId) => {
    return async (dispatch) => {
        dispatch(shareVotingLinkToAllStart())
        try {
            const result = await axios.post(`${SHARE_VOTING_LINK_TO_ALL_URL}/${adminId}/${electionId}`)
            dispatch(shareVotingLinkToAllSuccess({ }))
            ReactGA.event({ category:"VOTERS", action :"SHARE VOTING LINK TO ALL AUTOMATED SUCCESS" });

        } catch (error) {

            ReactGA.event({ category:"ERRORS", action :"SHARE VOTING LINK TO ALL AUTOMATED ERROR" });
            if (error.response) {
                console.log(error.response);
                dispatch(shareVotingLinkToAllError({ message: error.response.data.message }))
                return;
            }
            dispatch(shareVotingLinkToAllError({ message: error.message }))

        }
    }
}


export const setAnonymousVotedMiddleware = (adminId , electionId ) => {
        try {
            const localStorageKey = adminId+electionId+"ballot";

              //save to cache for later retrieval
              const toCache = {
                userId: localStorageKey,
                isVoted: true,
                votedAt: new Date().toISOString(),
            }

            localStorage.setItem(localStorageKey, JSON.stringify(toCache));
        } catch (error) {
            console.log(error.message);
        }

}

export const isAnonymousVotedMiddleware = (adminId , electionId ) => {
    try {
        const localStorageKey = adminId+electionId+"ballot";

        const jsonString = localStorage.getItem( localStorageKey);

        return JSON.parse( jsonString);
    } catch (error) {
        console.log(error.message);
    }
}
