
export const text ={
    heading1:"font-heading font-semibold text-4xl text-primary-500",
    heading2:"font-heading font-semibold text-2xl text-primary-500",
    heading3:"font-heading font-semibold text-md text-primary-500",
    description1:"font-body font-regular text-sm text-black ",
    description2:"font-body font-regular text-xs text-black ",
}

export const input ={
    primary:`border-2 border-primary-500 rounded-md  h-12 p-4  ${text.description1}`,
}

export const link ={
    heading3: "font-heading font-bold text-lg text-white hover:text-secondary-500 " ,
}

export const button ={
    primary:`rounded-sm  bg-primary-500 active:bg-secondary-500 disabled:bg-tertiary-500 justify-center items-center text-center  p-3 w-44 h-12`,
    secondary:`rounded-sm  bg-secondary-500 active:bg-primary-500 disabled:bg-tertiary-500 justify-center items-center text-center  p-3 w-44 h-12`,
    tertiary:`rounded-sm  bg-cyan-500 active:bg-primary-500 disabled:bg-tertiary-500 justify-center items-center text-center  p-3 w-44 h-12`,
    text:`font-description font-bold text-sm text-white text-center uppercase`,
}
