import { Outlet } from "react-router-dom"


export const VoterLayout = () => {
    return (
        <div className="flex flex-col h-screen w-full overflow-scroll ">

            <Outlet />
        </div>)
}

export default VoterLayout;