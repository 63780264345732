import { onSnapshot, orderBy } from "firebase/firestore";
import { Label, Select } from "flowbite-react";
import moment from "moment";
import React from "react";
import Chart from "react-google-charts";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import PageTitleComponent from "../../components/PageTitleComponent";
import VoteCreditsComponent from "../../components/VoterCreditsComponent";
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { text } from "../../constants/tailwind-theme";
import { getCandidatesError, getCandidatesStart, getCandidatesSuccess } from "../../redux/slices/CandidateSlice";
import { getElectionError, getElectionStart, getElectionSuccess } from "../../redux/slices/ElectionSlice";
import { getFirebaseDocRef, getFirebaseDocs } from "../../util/firebaseFirestoreUtils";

import newelection from "../../assets/lottie/newelection.json";
import EmptyPage from "../EmptyPage";
import LoadingPage from "../LoadingPage";

export const BallotResultsPage = () => {

    const navigate = useNavigate();

    const dispatch = useDispatch()

    const { adminId, electionId } = useParams();


    const electionState = useSelector(state => state.election);
    const candidateState = useSelector(state => state.candidate);

    const [chartType, setChartType] = React.useState("ColumnChart");

    const endDateTimeUTC = React.useMemo(() => moment.utc(electionState.election?.endDateTimeUTC), [electionState.election?.endDate, electionState.election?.endTime]);
    const isElectionEnded = moment.utc().isAfter(endDateTimeUTC);
    
    //realtime election listener
    React.useEffect(() => {
        const docRef = getFirebaseDocRef(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}`);
        dispatch(getElectionStart());

        const unsuscribe = onSnapshot(docRef, (doc) => {
            if (doc?.data() === undefined) {
                dispatch(getElectionError({ message: "Invalid URL" }));
                return;
            }

            dispatch(getElectionSuccess({ election: doc.data() }))
        },
            error => {
                dispatch(getElectionError({ message: error.message }))
            });

        return () => {
            unsuscribe();
        }
    }, []);

    //realtime candidates listener
    React.useEffect(() => {

        //dont execute this use effect if election has not ended
        if (!isElectionEnded) {
            return;
        }

        const collecitonRef = getFirebaseDocs(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}/${FIREBASECOLLECTIONS.CANDIDATES}`, [orderBy("position", "asc")])
        dispatch(getCandidatesStart());

        const unsubscribeListener = onSnapshot(collecitonRef,
            {

                next: (querySnapshot) => {
                    console.log("on snap callback called for get candidates in dashboard");
                    const candidates = [];


                    querySnapshot.forEach((doc) => {
                        candidates.push(doc.data())
                    });

                    dispatch(getCandidatesSuccess({ candidates: candidates }));
                },
                error: (error)=>{
                    dispatch(getCandidatesError( {message: error.message}))
                }
            }
        );

        return () => {
            unsubscribeListener();
        }
    }, []);

    if (electionState.isGetElectionLoading || candidateState.isGetCandidatesLoading ) {
        return <LoadingPage/>
    }
    if (electionState.ACTION_TYPE === getElectionError.toString()) {
        return <h1>UNAUTHORIZED</h1>
    }
    return (
        <div className="h-screen">
            <PageTitleComponent title={`Election Results`}

                rightComponent={
                    <div className="flex items-center justify-center my-1  mx-4 border-0">
                        <img className="w-10  border" src={electionState?.election?.imageUrl} alt="logo" />
                        <h1 className={twMerge(text.heading3, " text-center")} >{electionState?.election?.name}</h1>
                    </div>}
            />
            <div className="flex flex-row flex-wrap items-center justify-center md:justify-between w-full space-x-4 p-2  ">
                <h2 className={twMerge(text.heading2, "mx-2")}>Vote Count : {electionState?.election?.totalVotes}</h2>
                <div className="w-96" id="select">
                    <div className="mb-2 block">
                        <Label
                            htmlFor="countries"
                            value="Select Results type "
                        />
                    </div>
                    <Select
                        disabled={!isElectionEnded}
                        id="chart"
                        required={true}
                        value={chartType}
                        onChange={(e) => setChartType(e.target.value)}


                    >
                        <option value="ColumnChart">
                            Block Graph
                        </option>
                        <option value="PieChart">
                            Pie Chart
                        </option>
                        <option value="BarChart">
                            Bar Chart
                        </option>
                        <option value="Table">
                            Table
                        </option>


                    </Select>
                </div>
            </div>
            {/* if election has ended */}
            {
                isElectionEnded && electionState?.election?.totalVotes > 0 ?
                    <>
                        {electionState?.election?.categories?.map((category, index) => {

                            const votingResultsArray = []

                            const filteredCandidates = candidateState.candidates.filter((value, index, arrayy) => value.category === category);

                            if (filteredCandidates.length === 1) {
                                votingResultsArray.push(
                                    ["Vote Type", "Vote Count"],
                                );
                                votingResultsArray.push(
                                    [`${filteredCandidates[0].name}'s Yes Votes`, filteredCandidates[0].totalVotesYes]
                                );
                                votingResultsArray.push(
                                    [`${filteredCandidates[0].name}'s No Votes`, filteredCandidates[0].totalVotesNo]
                                );

                            } else {
                                votingResultsArray.push(["Candidate Name", "Total Votes"])
                                for (const candidateVariable of filteredCandidates) {
                                    votingResultsArray.push([candidateVariable.name, candidateVariable.totalVotesYes])

                                }
                            }


                            return (
                                <div key={index.toString()} className="border-4">
                                    <h1 className={twMerge(text.heading2, "m-4 mt-10 text-center")}>{category} </h1>
                                    <div className="flex justify-center items-center rounded border  p- w-full h-96 ">


                                        <Chart
                                            key={index.toString()}
                                            chartType={chartType}
                                            data={votingResultsArray}
                                            options={{
                                                title: category,
                                                is3D: true,
                                            }}
                                            width={"100%"}
                                            height={"100%"}
                                        />
                                    </div>
                                </div>
                            )


                        })}
                    </> :
                    isElectionEnded && electionState?.election?.totalVotes === 0 ?

                        <div className="my-5 mx-3">
                            <EmptyPage
                                lottieJson={newelection}
                                texxt={`No one has voted yet, Results will show here when people start voting`}
                            />
                        </div>
                        :
                        <div className="my-5 mx-3">
                            <EmptyPage
                                lottieJson={newelection}
                                texxt={`Oops! Election is still in session, come back after ${endDateTimeUTC.local().format('llll')}`}
                            />
                        </div>

            }

            <VoteCreditsComponent tailwindClassname="w-full mt-20 md:fixed bottom-0" />
        </div>
    )
}