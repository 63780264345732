



import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from 'react-router-dom';
import { appAuthenticationMiddleware } from '../redux/middlewares/AuthenticateMiddleware';
import router from "./routes";

const RootLayout = () => {
    
    const dispatch = useDispatch();

    React.useEffect(() => {
        // dispatch(appAuthenticationMiddleware());
    }, []);

    return (<RouterProvider router={router} />);

}

export default RootLayout;