import { Button, Label, Spinner, TextInput } from "flowbite-react";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitleComponent from "../../components/PageTitleComponent";
import { getVotersMiddleware, shareVotingLinkToAllMiddleware, shareVotingLinkMiddleware } from "../../redux/middlewares/VoterMiddleware";

import moment from "moment";
import { toast } from "react-hot-toast";
import { RWebShare } from "react-web-share";
import ConfirmationModal from "../../components/ConfirmationModal";
import {
    resetActionType, shareVotingLinkError, shareVotingLinkSuccess,
    shareVotingLinkToAllError, shareVotingLinkToAllSuccess
} from "../../redux/slices/VoterSlice";
import { twMerge } from "tailwind-merge";
import { text } from "../../constants/tailwind-theme";
import { useFormik } from "formik";
import { getFormErrorMessage, isFormFieldValid } from "../../util/formikUtils";
import ReactGA from 'react-ga';
import { ELECTION_TYPES } from "../../constants/ELECTIONTYPES";

const ShareVotingLinkPage = () => {

    const dispatch = useDispatch();
    const authenticateState = useSelector(state => state.authenticate);
    const electionState = useSelector(state => state.election);
    const electionType = electionState?.selectedElection?.type;

    const votersState = useSelector(state => state.voter)

    const [showMassReminderModal, setShowMassReminderModal] = React.useState(false)
    const [shareVotingLinkModal, setShareVotingLinkModal] = React.useState(false)

    const endDate = React.useMemo(() => moment(`${electionState.selectedElection?.endDate}T${electionState.selectedElection?.endTime}`), [electionState.selectedElection?.endDate, electionState.selectedElection?.endTime]);

    const formik = useFormik({
        initialValues: {
            voterEmail: "",
        },

        validate: (values) => {
            const errors = {};
            if (!values.voterEmail) {
                errors.voterEmail = "Voter's Email is required.";
                return errors;
            }
            var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

            if (!values.voterEmail.match(validRegex)) {
                errors.voterEmail = "Invalid Email.";
            }
            return errors;
        },

        onSubmit: (values) => {
            setShareVotingLinkModal(true);
        }
    });

    //when share voting link (automated) is clicked
    const showReminderPrompt = () => {
        if (endDate.isBefore(moment())) {
            toast.error("Election has ended.");
            return;
        } else if (votersState.spreadSheetDetails?.cellsErrorCount > 0) {
            toast.error("There are errors in your spreadsheet, please rectify them before sharing the voting link.", { duration: 10000 });
            return;
        }

        setShowMassReminderModal(true);
    }

    React.useEffect(() => {
        if (votersState.ACTION_TYPE === shareVotingLinkToAllSuccess.toString()) {

            toast.success("Voting link shared successfully");
            dispatch(resetActionType());

        } else if (votersState.ACTION_TYPE === shareVotingLinkToAllError.toString()) {
            toast.error(votersState.shareVotingLinkToAllMessage)
            // toast.error("An error occured sharing the link")
            dispatch(resetActionType());
        }

    }, [votersState.ACTION_TYPE]);

    React.useEffect(() => {
        if (votersState.ACTION_TYPE === shareVotingLinkSuccess.toString()) {

            toast.success("Voting link shared successfully");
            dispatch(resetActionType());
            formik.resetForm()


        } else if (votersState.ACTION_TYPE === shareVotingLinkError.toString()) {
            toast.error(votersState.shareVotingLinkMessage)
            dispatch(resetActionType());
        }

    }, [votersState.ACTION_TYPE]);

    useEffect(() => {

        const getSpreadsheetStats = () => dispatch(getVotersMiddleware(authenticateState.user.spreadsheetId, `${electionState?.selectedElection?.id}!A1:D`));

        //on tab focused
        window.addEventListener("focus", getSpreadsheetStats);

        //on page load
        getSpreadsheetStats();

        return () => {
            window.removeEventListener("focus", getSpreadsheetStats)
        }
    }, []);



    return (
        <div className="">

            <PageTitleComponent
                title="Share Voting Link"
                subtitle="You can manually share the link or you can use our automated link sharing feature."

            />

            <div className="flex flex-col flex-wrap gap-2 mt-4 ">

                {/* send link manually */}
                <div>

                    <h2 className={twMerge(text.heading3, "m-2")}>Share link manually</h2>
                    <RWebShare
                        disableNative={true}
                        sites={["copy", "whatsapp", "twitter", "facebook"]}
                        data={{
                            text: `This is the voting link for the "${electionState?.selectedElection?.name}" Election`,
                            url: `${window.location.origin}/voter/login/${authenticateState.user_id}/${electionState?.selectedElection?.id}`,
                            title: "Manually share the link for this election:"
                        }}
                        onClick={()=>{
                            ReactGA.event({ category:"VOTERS", action :"SHARE VOTING LINK MANUAL SUCCESS" });
                        }}          
                    >
                        <Button color={"purple"} fullSized size={"lg"}>Share voting link (Manual)</Button>
                    </RWebShare>
                    <hr className="mb-10 h-1 bg-black" />
                </div>



                {/* send email blast to all voters */}
                <div className={electionType === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}>

                    <h2 className={twMerge(text.heading3, "m-2")}>Share the voting link with all voters</h2>
                    <Button
                        fullSized
                        disabled={votersState.isShareVotingLinkToAllLoading || votersState.isGetVotersLoading}
                        size={"lg"}
                        onClick={showReminderPrompt}
                        color="warning"
                    >
                        {votersState.isShareVotingLinkToAllLoading || votersState.isGetVotersLoading ? <Spinner className="mr-3" /> : null}
                        Share Voting link with all voters (Automated)
                    </Button>
                    <hr className="mb-10 h-1 bg-black" />

                    {/* send reminder to only one person */}
                    <h2 className={twMerge(text.heading3, "m-2")}>Share link with one voter</h2>
                    <div className="flex flex-col gap-1">
                        <div className="mb-2 block">
                            <Label
                                htmlFor="voterEmail"
                                value="Enter voters email"
                            />
                        </div>
                        <TextInput
                            id="voterEmail"
                            name="voterEmail"
                            type="email"
                            value={formik.values.voterEmail}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            placeholder="name@example.com"
                            color={isFormFieldValid(formik, "voterEmail") ? "primary" : "failure"}
                            helperText={getFormErrorMessage(formik, "voterEmail")}


                        />
                        <Button
                            className="mt-4"
                            color={"primary"}
                            size="lg"
                            fullSized
                            onClick={formik.handleSubmit}
                        >
                            {votersState.isShareVotingLinkLoading ? <Spinner className="mr-3" /> : null}
                            Share Voting Link
                        </Button>
                    </div>
                </div>


            </div>






            {shareVotingLinkModal ?
                <ConfirmationModal
                    headerText={`Share voting link? `}
                    bodyText={`This will share the voting link with ${formik.values.voterEmail}. ${formik.values.voterEmail} must be in the voters' spreadsheet. Do you want to continue? `}
                    onConfirm={() => {
                        setShareVotingLinkModal(false);
                        dispatch(shareVotingLinkMiddleware(
                            authenticateState.user_id,
                            electionState?.selectedElection?.id,
                            formik.values.voterEmail
                        ));

                    }}
                    onCancel={() => setShareVotingLinkModal(false)}
                    show={shareVotingLinkModal}
                /> : null}

            {showMassReminderModal ?
                <ConfirmationModal
                    headerText="Send Mass Email?"
                    bodyText="This will send a mass email to all emails in your spreadsheet, do you want to continue? You can do this for a limited number of times"
                    onConfirm={() => {
                        setShowMassReminderModal(false);
                        dispatch(shareVotingLinkToAllMiddleware(
                            authenticateState.user_id,
                            electionState?.selectedElection?.id,
                        ));
                    }}
                    onCancel={() => setShowMassReminderModal(false)}
                    show={showMassReminderModal}
                /> : null}





        </div>
    )
}
export default ShareVotingLinkPage;