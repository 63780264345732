import {
  loginWithGmailError, loginWithGmailStart, loginWithGmailSuccess,signupWithGmailSuccess, logOutError, logOutStart, logOutSuccess, validateUserSuccess
} from "../slices/AuthenticateSlice";

import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { signInWithGoogleFirebase, signOutFirebase } from "../../util/firebaseAuthUtils";
import {  getFirebaseDoc, setFirebaseDoc } from "../../util/firebaseFirestoreUtils";

import ReactGA from 'react-ga';

//  firebase auth


import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../../constants/firebase-config";

// app authentication middleware -> handles app entry point
export const appAuthenticationMiddleware = () => {
  return async (dispatch) => {


    onAuthStateChanged(auth, (user) => {
      if (user) {
        //user already logged in

        const docId = user.uid
        const userDoc = {
          userId: user.uid,
          name: user.displayName,
          email: user.email,
          imageUrl: user.photoURL,
        }

        dispatch(validateUserSuccess({ user_id: docId, user: userDoc }));
        return;
      }

      // "user not logged in"
    });
  };
};

export const loginWithGmailMiddleware = () => {
  return async (dispatch) => {
    dispatch(loginWithGmailStart())
    try {
      const userCredential = await signInWithGoogleFirebase()
      const docId = userCredential.user.uid
      const docSnap = await getFirebaseDoc(`${FIREBASECOLLECTIONS.USERS}/${docId}`)

      //user already signed up
      if (docSnap?.exists()) {
        dispatch(loginWithGmailSuccess({ user_id: docId, user: docSnap.data() }));
        ReactGA.event({ category:"AUTH", action :"LOGIN WITH GMAIL SUCCESS" });


      } else {
        //new user store user details
        const userDoc = {
          userId: userCredential.user.uid,
          name: userCredential.user.displayName,
          email: userCredential.user.email,
          imageUrl: userCredential.user.photoURL,
          createdAt: new Date().toISOString()
        }

        await setFirebaseDoc(`${FIREBASECOLLECTIONS.USERS}/${docId}`, userDoc);
        dispatch(signupWithGmailSuccess({ user_id: docId, user: userDoc }));
        ReactGA.event({ category:"AUTH", action :"SIGNUP WITH GMAIL SUCCESS" });

      }

    } catch (error) {
      dispatch(loginWithGmailError({ message: error.message }));
      ReactGA.event({ category:"ERRORS", action :"LOGIN WITH GMAIL ERROR" });

    }
  }
}


export const logOutMiddleware = () => {
  return async (dispatch) => {
    dispatch(logOutStart());

    try {

      //TODO FIND WAY TO REMOVE ALL REDUX STATES
      signOutFirebase();
      localStorage.removeItem("user_id");
      dispatch(logOutSuccess());
      ReactGA.event({ category:"AUTH", action :"LOGOUT SUCCESS" });


    } catch (error) {
      dispatch(logOutError({ message: error.code }));
      ReactGA.event({ category:"ERRORS", action :"LOGOUT ERROR" });

    }

  };
};
