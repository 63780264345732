import { Button } from "flowbite-react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { BallotInfoComponent } from "../../components/BallotInfoComponent";
import VoteCreditsComponent from "../../components/VoterCreditsComponent";
import { text } from "../../constants/tailwind-theme";

export const BallotSuccessPage = (props) => {
    //check if admin id and election id is valid
    const { adminId, electionId } = useParams();
    const { state } = useLocation();

    const electionState = useSelector(state => state.election);

    return <BallotInfoComponent message={ state?.message ?? "Your vote has been submitted successfully."}/>
    
}