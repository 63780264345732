import { createBrowserRouter, createRoutesFromElements, Route } from "react-router-dom";
import VoterLoginPage from "../pages/auth/VoterLoginPage";
import { AdminLayout } from "./AdminLayout";
import { AuthLayout } from "./AuthLayout";
import ElectionsLayout from "./ElectionsLayout";
import { VoterLayout } from "./VoterLayout";
import WebsiteLayout from "./WebsiteLayout";

import CreateNewElectionPage from "../pages/admin/AddElectionPage";
import UpgradeElectionPage from "../pages/admin/UpgradeElectionPage";
import ViewElectionsPage from "../pages/admin/ViewElectionsPage";
import ViewPaymentsPage from "../pages/admin/ViewPaymentsPage";
import PaymentStatus from "../pages/admin/PaymentStatus";


//election layout pages

import ViewCandidatesPage from "../pages/admin/ViewCandidatesPage";
import EditElectionPage from '../pages/admin/EditElectionPage';
import ElectionDashboardPage from '../pages/admin/ElectionDashboardPage';


//voter layout pages
import ManageVotersPage from "../pages/admin/ViewVotersPage";
import HomePage from "../pages/HomePage";
import ThemePage from "../pages/ThemePage";
import { BallotPage } from "../pages/voters/BallotPage";
import { BallotResultsPage } from "../pages/voters/BallotResultsPage";
import { BallotSuccessPage } from "../pages/voters/BallotSuccessPage";
import AdminLoginPage from "../pages/auth/AdminLoginPage";
import ViewVotedPage from "../pages/admin/ViewVotedPage";
import AddCandidatePage from "../pages/admin/AddCandidatePage";
import ShareVotingLinkPage from "../pages/admin/ShareVotingLinkPage";
import ElectionSearchPage from "../pages/ElectionSearchPage";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route >
            {/* Website layout */}
            <Route path="/" element={<WebsiteLayout />} >
                <Route >
                    <Route path="/" element={<HomePage />} />
                    <Route path="/search" element={<ElectionSearchPage />} />
                    {/* <Route path="/theme" element={<ThemePage />} /> */}
                </Route>
            </Route>



            {/* Admin Auth layout */}
            <Route path="auth" element={<AuthLayout />}>
                <Route>
                    <Route path="/auth/" element={<AdminLoginPage />} />
                    <Route path="/auth/admin-login" element={<AdminLoginPage />} />

                    <Route path="*" element={<AdminLoginPage />} />

                </Route>
            </Route>




            {/* Admin layout */}
            <Route path="admin/" element={<AdminLayout />}>
                <Route path="/admin/" element={<ViewElectionsPage />} />
                <Route path="elections/" element={<ViewElectionsPage />} />
                <Route path="elections/add" element={<CreateNewElectionPage />} />
                <Route path="elections/edit" element={<EditElectionPage />} />
                <Route path="elections/payments" element={<ViewPaymentsPage />} />
                <Route path="*" element={<ViewElectionsPage />} />

            </Route>

            {/* election layout */}
            <Route path="admin/election" element={<ElectionsLayout />} >
                <Route >
                    <Route path="/admin/election/" element={<ElectionDashboardPage />} />
                    <Route path="view" element={<ElectionDashboardPage />} />
                    <Route path="edit" element={<EditElectionPage />} />
                    <Route path="candidates/add" element={<AddCandidatePage />} />
                    <Route path="candidates" element={<ViewCandidatesPage />} />
                    <Route path="voters" element={<ManageVotersPage />} />
                    <Route path="share-link" element={<ShareVotingLinkPage />} />
                    <Route path="voted" element={<ViewVotedPage />} />
                    <Route path="upgrade/status" element={<PaymentStatus />} />
                    <Route path="upgrade" element={<UpgradeElectionPage />} />
                    <Route path="*" element={<ElectionDashboardPage />} />
                </Route>
            </Route>


            {/* voter layout */}
            <Route path="voter" element={<VoterLayout />} >
                <Route>
                    <Route path="login/:adminId/:electionId" element={<VoterLoginPage />} />
                    <Route path="login/:adminId/:electionId/:voterEmail" element={<VoterLoginPage />} />

                    <Route path="ballot/:adminId/:electionId" element={<BallotPage />} />
                    <Route path="ballot-success/:adminId/:electionId" element={<BallotSuccessPage />} />
                    <Route path="ballot-results/:adminId/:electionId" element={<BallotResultsPage />} />


                </Route>
            </Route>


        </Route>
    )
)

export default router;