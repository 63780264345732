import { Navigate, Outlet } from "react-router-dom";

import React, { useState } from 'react';
import AdminNavbarComponent from "../components/AdminNavbarComponent";
import AdminSidebarComponent from "../components/AdminSidebarComponent";
import { useSelector } from "react-redux";


export const AdminLayout = () => {


    const [collapsed, setCollapsed] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    const authState = useSelector(state => state.authenticate)

    React.useEffect(() => {

        //collaps sidebar on mobile view
        if (window.innerWidth < 768) {
            setCollapsed(true)
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }

        //collaps sidebar on screen resized to mobile view
        window.addEventListener("resize", (ev) => {

            if (ev.currentTarget.innerWidth < 768) {
                setCollapsed(true);
                setIsSmallScreen(true)
            } else {
                setIsSmallScreen(false);
            }
        });

        return () => {
            // Anything in here is fired on component unmount.
            window.removeEventListener("resize", (ev) => { });
        }
    }, [])


    if (!authState.isLoggedIn) {
        return <Navigate to="/"  />
    }


    return (

        <div className="flex h-screen w-full flex-col overflow-clip">
            <AdminNavbarComponent toggleCollapse={() => setCollapsed(value => !value)} />

            <div className="flex h-full overflow-clip bg-white">
                <AdminSidebarComponent
                    collapsed={collapsed}
                    closeSideBar={() => {
                        if (isSmallScreen) {
                            setCollapsed(true)
                        }
                    }}

                />
                <main className="flex-1 overflow-auto p-4 bg-tertiary-300" >
                    <div >
                        <Outlet />
                    </div>
                </main>
            </div>
        </div>

    )
}