import { Button, Label, Spinner, TextInput } from "flowbite-react";
import React from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import VoterCreditsComponent from "../../components/VoterCreditsComponent";
import VoterLoginElectionDetailsComponent from "../../components/VoterLoginElectionDetailsComponent";
import { text } from "../../constants/tailwind-theme";
import { getFormErrorMessage, isFormFieldValid } from "../../util/formikUtils";

const VoterVerifyOTPPage = (props) => {

    const { formikVerifyOTP } = props;

    const voterState = useSelector(state => state.voter)
    const electionState = useSelector(state => state.election)
    const { adminId, electionId, voterEmail } = useParams();

    return (
        <>
            <div className="flex flex-col items-center spaced-y-4 w-full h-screen">

                <div className="flex flex-col items-center justify-center mt-10">
                    <img className="w-40  border " src={electionState?.election?.imageUrl} alt="logo" />
                    <h1 className={twMerge(text.heading2, " text-center")} >{electionState?.election?.name} { }</h1>
                </div>

                <div className="flex flex-col items-center w-full md:w-6/12 gap-4 border-0 p-4 ">

                    {/* Email */}
                    <div className="w-full ">
                        <div className="mb-2 block">
                            <Label className="mb-2 block" htmlFor="email1" value={"Enter the OTP code sent to this email: " + voterEmail} />
                        </div>
                        <TextInput
                            id="otp"
                            name="otp"
                            onChange={formikVerifyOTP?.handleChange}
                            onBlur={formikVerifyOTP?.handleBlur}
                            value={formikVerifyOTP?.values.otp}
                            type="email"
                            placeholder="Enter OTP"
                            required={true}
                            color={isFormFieldValid(formikVerifyOTP, "otp") ? "primary" : "failure"}
                            helperText={getFormErrorMessage(formikVerifyOTP, "otp")}
                        />
                        <p className={twMerge(text.description2, "mt-2")}>OTP will expire after one failed attempt, so please enter it carfully</p>
                    </div>



                    <Button
                        className="my-4"
                        disabled={voterState.isVerifyVoterOTPLoading} onClick={formikVerifyOTP?.handleSubmit} color={"primary"} >
                        {voterState.isVerifyVoterOTPLoading ? <Spinner className="mr-3 w-5 h-5" /> : null}
                        Verify OTP
                    </Button>

                    <VoterLoginElectionDetailsComponent
                        adminId={adminId}
                        electionId={electionId}
                        election={electionState?.election}
                    />

                </div>

                <VoterCreditsComponent tailwindClassname="w-full md:fixed mt-20 bottom-0" />

            </div>
        </>
    )
}

export default VoterVerifyOTPPage;