export const PAYMENT_PLANS = {
    FREE:"FREE",
    PAY_PER_VOTE:"PAY_PER_VOTE",
    ENTERPRISE:"ENTERPRISE",
}
export const pricingPlan =[
    {
        name:"FREE",
        value:PAYMENT_PLANS.FREE,
        electionType:"PUBLIC ELECTIONS ",
        description:"Public Election with Unlimited Votes 😀 ",
        currency:"$",
        price:0 ,
        pricePerVotes: 0,
        priceText:"$0" ,
        features:[
            "Realtime Election Dashboard",
            "Manage Election",
            "Manage Candidates",
            "Reorder Candidates & Categories",
            "Manual Voting Link sharing via Whatsapp, Facebook and Facebook",
        ]
    },
    {
        name:"PAY PER VOTE",
        value:PAYMENT_PLANS.PAY_PER_VOTE,
        electionType:"PRIVATE ELECTIONS ",
        description:"Private Election + Free for the first 20 voters, then $1 per 20 votes",
        currency:"$",
        price:1 ,
        pricePerVotes: 20,
        priceText:"$1/20 votes" ,
        features:[
            "All FREE features and :",
            "Private & Secured Election",
            "Manage Voters via Google Spreadsheet Integration",
            "Automatically check for errors in Google Spreadsheet",
            "Ad Free",
        ]
    },
    {
        name:"ENTERPRISE",
        value:PAYMENT_PLANS.ENTERPRISE,
        electionType:"PRIVATE ELECTIONS ",
        description:"Private Election + Unlimted votes + Unrestricted Feature access",
        currency:"$",
        price:50 ,
        pricePerVotes: 0,
        priceText:"$50" ,
        features:[
            "All 'PAY PER VOTE' features and :",
            "Unlimited Votes",
            "Automated Voting link sharing to all voters via email",
            "Automated Voting link sharing to 1 voter via email",
        ]
    }
]