import { Spinner, Table } from "flowbite-react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import PageTitleComponent from "../../components/PageTitleComponent";
import EmptyListPage from "../EmptyPage";

import { onSnapshot, orderBy, where } from "firebase/firestore";
import Moment from "react-moment";
import { twMerge } from "tailwind-merge";
import emptylist from "../../assets/lottie/emptylist.json";
import generalerror from "../../assets/lottie/generalerror.json";
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { text } from "../../constants/tailwind-theme";
import { getVotedError, getVotedStart, getVotedSuccess } from "../../redux/slices/VoterSlice";
import { getFirebaseDocs } from "../../util/firebaseFirestoreUtils";

const ViewVotedPage = () => {

    const dispatch = useDispatch();
    const authenticateState = useSelector(state => state.authenticate);
    const electionState = useSelector(state => state.election);

    const votersState = useSelector(state => state.voter)


    React.useEffect(() => {

        const collecitonRef = getFirebaseDocs(FIREBASECOLLECTIONS.VOTERS,
            [
                where("electionId", "==", electionState?.selectedElection?.id),
                where("isVoted", "==", true),
                orderBy("votedAt", "desc")
            ])

        dispatch(getVotedStart());

        const unsubscribeListener = onSnapshot(collecitonRef,
            {
                next: (querySnapshot) => {
                    // console.log("on snap callback called for get voted list");
                    try {
                        const voted = []

                        querySnapshot.forEach((doc) => {
                            voted.push(doc.data());
                        });


                        dispatch(getVotedSuccess({ voted: voted }));
                    } catch (error) {
                        dispatch(getVotedError({ message: error.message }))
                    }

                }
                , error: (error) => {
                    console.log(error);
                    dispatch(getVotedError({ message: error.message }))

                }
            }
        );

        return () => {
            unsubscribeListener();
        }

    }, []);




    return (
        <div className="">

            <PageTitleComponent
                title="Voted List"
                subtitle="Details of users who have already casted their vote"

            />



            {
                votersState.isGetVotedLoading ?
                    <div className="flex justify-center items-center w-full">
                        <Spinner className="h-10 w-10 m-20" />
                    </div> 
                    :
                votersState.voted?.length > 0 ?

                    <div className="mt-10">
                        <Table striped={true}>

                            {/* Table head */}
                            <Table.Head>

                                <Table.HeadCell>
                                    <h3 className={twMerge(text.heading3, "text-white")}>#</h3>
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    <h3 className={twMerge(text.heading3, "text-white")}>Email</h3>
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    <h3 className={twMerge(text.heading3, "text-white")}>Name</h3>
                                </Table.HeadCell>
                                <Table.HeadCell>
                                    <h3 className={twMerge(text.heading3, "text-white")}>Voted At</h3>
                                </Table.HeadCell>

                            </Table.Head>

                            {/* table body */}
                            <Table.Body className="divide-y">
                                {votersState.voted.map((tableRow, index, votersArray) => {

                                    return (<Table.Row key={index.toString()}>
                                        <Table.Cell className="">
                                            <h3 className={twMerge(text.description1)}>{index + 1}</h3>
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <h3 className={twMerge(text.description1)}>{tableRow.email}</h3>
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <h3 className={twMerge(text.description1)}>{tableRow.name}</h3>
                                        </Table.Cell>
                                        <Table.Cell className="">
                                            <h3 className={twMerge(text.description1)}><Moment format="llll">{tableRow.votedAt}</Moment> </h3>
                                        </Table.Cell>
                                    </Table.Row>)
                                }


                                )}
                            </Table.Body>
                        </Table>
                    </div>
                    :
                votersState.ACTION_TYPE === getVotedError.toString() ?

                    <div className=" flex  items-center justify-center w-full p-10">
                        <EmptyListPage
                            lottieJson={generalerror}
                            texxt={votersState.getVotedMessage}

                        />
                    </div>
                    :
                    <div className=" flex  items-center justify-center w-full p-10">
                        <EmptyListPage
                            lottieJson={emptylist}
                            texxt="No Votes yet. People who have casted their vote will show here. This does not include who they voted for. "

                        />
                    </div>

            }

        </div>
    )
}
export default ViewVotedPage;