import { twMerge } from "tailwind-merge";
import { text } from "../../constants/tailwind-theme";

const WebsiteCreditsComponent = ({tailwindClassname}) => (

    <div className= {twMerge(" flex flex-row flex-wrap justify-center  md:justify-between bg-primary-500 p-4 ",tailwindClassname)}>
        <a href="https://heyphord.com" target="_blank"><p className={twMerge(text.heading3," text-center text-white text-xs w-full underline","m-1")}>Developed By Heyphord I.T Solutions</p></a>

        <p className={twMerge(`${text.heading3} m-1 text-center text-white text-xs`)}>2023© VOTE BY HEYPHORD. All Rights Reserved</p>
    </div>
)

export default WebsiteCreditsComponent;