import { onSnapshot } from "firebase/firestore";
import { Badge, Button, Progress, Spinner } from "flowbite-react";
import React from "react";
import { HiUserGroup } from "react-icons/hi";
import Moment from "react-moment";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import WalkthroughComponent from "../../components/WalkthroughComponent";
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { text } from "../../constants/tailwind-theme";
import { getCandidatesError, getCandidatesStart, getCandidatesSuccess } from "../../redux/slices/CandidateSlice";
import { publishElectionError, publishElectionSuccess, setSelectedElection, resetActionType as resetActionTypeElection } from "../../redux/slices/ElectionSlice";
import { getFirebaseDocRef, getFirebaseDocs } from "../../util/firebaseFirestoreUtils";
import { ELECTION_TYPES } from "../../constants/ELECTIONTYPES";
import { ELECTION_STATUS } from "../../constants/ELECTIONSTATUS";
import ConfirmationModal from "../../components/ConfirmationModal";
import { publishElectionMiddleware } from "../../redux/middlewares/ElectionMiddleware";
import { toast } from "react-hot-toast";
import { calculateProgressColor, calculateProgressPercentage } from '../../util/miscUtil'
export const ElectionDashboardPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate();
    const electionState = useSelector(state => state.election)
    const candidateState = useSelector(state => state.candidate)
    const authenticateState = useSelector(state => state.authenticate);

    const [publishElectionModal, setPublishElectionModal] = React.useState(false);

    const progressPercentage = calculateProgressPercentage( electionState?.selectedElection?.totalVotes ?? 0 , electionState?.selectedElection?.voteLimit ?? 0)
    const progressColor = calculateProgressColor(progressPercentage)


    //listen to election in realtime
    React.useEffect(() => {
        const docRef = getFirebaseDocRef(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionState?.selectedElection?.id}`);

        const unsub = onSnapshot(docRef, (doc) => {
            dispatch(setSelectedElection({ selectedElection: doc.data() }))
        });

        return () => {
            unsub();
        }
    }, []);

    //get candidate in realtime
    React.useEffect(() => {

        const collecitonRef = getFirebaseDocs(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionState?.selectedElection?.id}/${FIREBASECOLLECTIONS.CANDIDATES}`)

        dispatch(getCandidatesStart());
        const unsubscribeListener = onSnapshot(collecitonRef,
            {
                next: (querySnapshot) => {
                    console.log("on snap callback called for get candidates in dashboard");
                    try {
                        const candidates = [];

                        querySnapshot.forEach((doc) => {
                            candidates.push(doc.data())
                        });

                        dispatch(getCandidatesSuccess({ candidates: candidates }));
                    } catch (error) {
                        const errorCode = error.code
                        dispatch(getCandidatesError({ message: errorCode ?? error }))
                    }
                    
                },
                
                error: (error)=>{
                    dispatch(getCandidatesError({ message: error.message }))

                }

            });

        return () => {
            console.log(`unsubscribe listener called`)
            unsubscribeListener();
        }

    }, []);

    //election event change listener
    React.useEffect(() => {

        if (electionState.ACTION_TYPE === publishElectionSuccess.toString()) {
            toast.success(electionState.publishElectionMessage, { duration: 2000 });
            dispatch(resetActionTypeElection())

        } else if (electionState.ACTION_TYPE === publishElectionError.toString()) {

            toast.error("There was an error publishing your election");
            dispatch(resetActionTypeElection())

        }

    }, [electionState.ACTION_TYPE]);


    //listen for screen width changes
    const [isSmallScreen, setIsSmallScreen] = React.useState(false);
    React.useEffect(() => {

        //collaps sidebar on mobile view
        if (window.innerWidth < 768) {
            setIsSmallScreen(true);
        } else {
            setIsSmallScreen(false);
        }

        //collaps sidebar on screen resized to mobile view
        window.addEventListener("resize", (ev) => {

            if (ev.currentTarget.innerWidth < 768) {
                setIsSmallScreen(true)
            } else {
                setIsSmallScreen(false);
            }
        });

        return () => {
            // Anything in here is fired on component unmount.
            window.removeEventListener("resize", (ev) => { });
        }
    }, [])
    return (
        <div className="space-y-4">
            {/* WALK THROUGH */}

            <WalkthroughComponent electionType={electionState?.selectedElection?.type} isSmallScreen={isSmallScreen} />


            {/* ELECTION OVERVIEW  */}
            <div className="flex flex-col w-full ">

                {/* DASHBOARD TITLE */}
                <h2 className={twMerge(text.heading2, "m-3")}>Election Overview</h2>


                {/* DASHBOARD CONTENT */}

                <div className="flex flex-row flex-wrap justify-between items-center border-2 rounded bg-white shadow-md space-x-4 p-4 w-full">

                    <div className="flex flex-row justify-center flex-wrap gap-4">
                        <div className=" relative border w-40 h-40">
                            <img className="absolute top-0 aspect-square h-full object-center object-cover " src={electionState?.selectedElection?.imageUrl} alt="logo" />
                            <Badge color={"warning"} className="absolute top-2 left-0 uppercase ">{electionState?.selectedElection?.type}</Badge>
                            <Badge color={"success"} className="absolute top-8 left-0 uppercase ">{electionState?.selectedElection?.paymentPlan}</Badge>
                        </div>

                        <div>
                            <h3 className={twMerge(text.heading2, "")}>{electionState?.selectedElection?.name} </h3>

                            <h4 className={text.heading3}>Status:  <span className="uppercase">{electionState?.selectedElection?.status ?? ELECTION_STATUS.UNPUBLISHED}</span>  </h4>
                            <h4 className={text.heading3}>Vote Limit: {electionState?.selectedElection?.totalVotes} / {electionState?.selectedElection?.type === ELECTION_TYPES.PUBLIC ? "∞" : electionState?.selectedElection?.voteLimit}  </h4>
                            <h4 className={text.heading3}>Starts on: <Moment local format="llll" date={electionState?.selectedElection?.startDateTimeUTC}></Moment></h4>
                            <h4 className={text.heading3}>Ends on: <Moment local format="llll" date={electionState?.selectedElection?.endDateTimeUTC}></Moment></h4>
                            <h4 className={text.heading3}>Election Type: <span className="uppercase">{electionState?.selectedElection?.type} </span> </h4>
                            <h4 className={text.heading3}>Payment Plan: <span className="uppercase">{electionState?.selectedElection?.paymentPlan}</span>  </h4>
                        </div>
                    </div>

                    <div className="flex flex-col justify-self-end  p-4 gap-2 w-full md:w-4/12 ">

                        <Button
                            disabled={electionState.isPublishElectionLoading}
                            onClick={() => setPublishElectionModal(value => !value)}
                            size={"sm"}
                            fullSized
                            color={electionState?.selectedElection?.status === ELECTION_STATUS.PUBLISHED ? "failure" : "success"}
                        >
                            {electionState.isPublishElectionLoading ? <Spinner className="mr-3 h-5 w-5" /> : null}

                            {electionState?.selectedElection?.status === ELECTION_STATUS.PUBLISHED ? "Unpublish" : "Publish Election"}
                        </Button>

                        <Button
                            className={electionState?.selectedElection?.type === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}
                            onClick={() => {
                                console.log(electionState?.selectedElection?.electionIndex);
                                navigate("/admin/election/upgrade", { state: { electionIndex: electionState?.selectedElectionIndex } })

                            }}
                            size={"sm"}
                            fullSized
                            color={"warning"} >
                            Upgrade Election
                        </Button>

                        <Link to="/admin/election/candidates">
                            <Button size={"sm"} fullSized color={"primary"} >View Candidates</Button>
                        </Link>

                        <Link to="/admin/election/voters"
                            className={electionState?.selectedElection?.type === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}

                        >
                            <Button color={"purple"} fullSized size={"sm"} >Manage Voters</Button>
                        </Link>

                    </div>
                </div>

            </div>


            {/* STATS */}
            <div className="flex flex-col w-full">

                {/* DASHBOARD TITLE */}
                <h2 className={twMerge(text.heading2, "m-3")}>Stats</h2>

                {/* DASHBOARD CONTENT */}
                <div className="flex flex-row flex-wrap justify-between gap-1">
                    <div className="flex flex-col border-2 rounded bg-white shadow-md p-4 w-full  md:w-2/12 ">
                        <h2 className={twMerge(text.heading3, "text-base")}><HiUserGroup className="inline mb-1" size={20} /> Vote Count</h2>
                        <h2 className={twMerge(text.description2, "leading-3 text-gray-600")}>The total number of people who have casted their vote.  </h2>
                        <h2 className={twMerge(text.heading1)}>{electionState?.selectedElection?.totalVotes} </h2>
                    </div>

                    <div className="flex flex-col border-2 rounded bg-white shadow-md p-4 w-full  md:w-2/12 ">
                        <h2 className={twMerge(text.heading3, "text-base")}><HiUserGroup className="inline mb-1" size={20} /> Vote Limit</h2>
                        <h2 className={twMerge(text.description2, "leading-3 text-gray-600")}>The voting limit. Kindly upgrade before you exceed the voting limit  </h2>
                        <h2 className={twMerge(text.heading1)}>{electionState?.selectedElection?.totalVotes}/{electionState?.selectedElection?.type === ELECTION_TYPES.PUBLIC ? "∞" : electionState?.selectedElection?.voteLimit} </h2>

                        <Progress
                            size={"lg"}
                            className={electionState?.selectedElection?.type === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block mt-2'}
                            progress={progressPercentage}
                            color={progressColor}

                        />

                    </div>
                    <div className="flex flex-col border-2 rounded bg-white shadow-md p-4 w-full  md:w-2/12 ">
                        <h2 className={twMerge(text.heading3, "text-base")}><HiUserGroup className="inline mb-1" size={20} /> Categories</h2>
                        <h2 className={twMerge(text.description2, "leading-3 text-gray-600")}>Total number of Categories created for this election.</h2>

                        <h2 className={twMerge(text.heading1)}>{electionState?.selectedElection?.categories?.length ?? "N/A"}</h2>
                    </div>
                    <div className="flex flex-col border-2 rounded bg-white shadow-md p-4 w-full  md:w-2/12 ">
                        <h2 className={twMerge(text.heading3, "text-base")}><HiUserGroup className="inline mb-1" size={20} /> Candidates</h2>
                        <h2 className={twMerge(text.description2, "leading-3 text-gray-600")}>Total number of candidates</h2>

                        <h2 className={twMerge(text.heading1)}>{candidateState?.candidates?.length ?? "N/A"}</h2>
                    </div>

                </div>

            </div>






            {/* modals */}

            {publishElectionModal ?
                <ConfirmationModal
                    headerText={electionState?.selectedElection?.status === ELECTION_STATUS.PUBLISHED ? "Unpublish ?" : "Publish ?"}
                    bodyText={`Are you sure you want to ${electionState?.selectedElection?.status === ELECTION_STATUS.PUBLISHED ? "UNPUBLISH " : "PUBLISH "} this election?`}
                    onConfirm={() => {
                        const candLenght = candidateState?.candidates?.length ?? 0;
                        const status = electionState?.selectedElection?.status !== ELECTION_STATUS.PUBLISHED ? ELECTION_STATUS.PUBLISHED : ELECTION_STATUS.UNPUBLISHED;

                        if (candLenght < 1 && status === ELECTION_STATUS.PUBLISHED) {
                            toast("Add at least 1 candidate before publishing this election")
                            return;
                        }

                        dispatch(publishElectionMiddleware(electionState?.selectedElection?.id, status));
                        setPublishElectionModal(false)
                    }}
                    onCancel={() => setPublishElectionModal(false)}
                    show={publishElectionModal}
                /> : null}
        </div>
    )
}
export default ElectionDashboardPage;