import { Sidebar } from "flowbite-react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { HiArrowSmLeft } from 'react-icons/hi';

import React from "react";
import vote_logo_transparent_bg from '../assets/images/vote_logo_white_bg.png';
import { BsTable, BsFileEarmarkSpreadsheetFill, BsPeople, BsPersonLinesFill, BsListCheck, BsPersonCheck } from "react-icons/bs";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedElection } from "../redux/slices/ElectionSlice";
import { ELECTION_TYPES } from "../constants/ELECTIONTYPES";

const ElectionSidebarComponent = (props) => {
    const { pathname } = useLocation();
    
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { collapsed, closeSideBar } = props;
    const electionState = useSelector(state => state.election)
    const electionType = electionState?.selectedElection?.type;

    return (

        // make the sidebar fixed, and hide it on collapse clicked
        <div className={`flex w-full z-10 h-full fixed md:relative md:block  ${collapsed ? "hidden md:w-16" : "md:w-64"}`}>

            <Sidebar collapsed={collapsed} aria-label="Sidebar with multi-level dropdown example">
                <Sidebar.Logo
                    href="#"
                    img={vote_logo_transparent_bg}
                    imgAlt="Vote logo"
                >
                    <h1 className={twMerge(text.heading3, "text-white")}>Vote</h1>
                </Sidebar.Logo>
                <Sidebar.Items>
                    <Sidebar.ItemGroup>
                        <Sidebar.Item
                            as={Link} to={"/admin/election/view"} active={"/admin/election/view" === pathname}
                            icon={BsTable}
                            onClick={closeSideBar}

                        >
                            Dashboard
                        </Sidebar.Item>

                        {/* Manage elections */}
                        <Sidebar.Collapse
                            icon={BsListCheck}
                            label="Manage Election"
                        >
                            <Sidebar.Item
                                as={Link} to={"/admin/election/edit"} active={"/admin/election/edit" === pathname}
                                onClick={closeSideBar}
                            >
                                Edit Election
                            </Sidebar.Item>
                            <Sidebar.Item
                                as={Link} state={{electionIndex:electionState?.selectedElectionIndex}} to={"/admin/election/upgrade"} active={"/admin/election/upgrade" === pathname}
                                onClick={closeSideBar}
                                className={electionType === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}
                            >
                                Upgrade Election
                            </Sidebar.Item>

                        </Sidebar.Collapse>

                        {/* mange candidates */}
                        <Sidebar.Collapse
                            icon={BsPeople}
                            label="Manage Candidates"
                        >


                            <Sidebar.Item
                                as={Link} to={"/admin/election/candidates/add"} active={"/admin/election/candidates/add" === pathname}
                                onClick={closeSideBar}
                            >
                                Add Candidate
                            </Sidebar.Item>
                            <Sidebar.Item
                                as={Link} to={"/admin/election/candidates"} active={"/admin/election/candidates" === pathname}
                                onClick={closeSideBar}
                            >
                                View Candidates
                            </Sidebar.Item>
                        </Sidebar.Collapse>

                        <Sidebar.Collapse
                            icon={BsFileEarmarkSpreadsheetFill}
                            label="Manage Voters"
                        >
                            {/* conditionally render sidbar pages */}
                            <Sidebar.Item
                                className={electionType === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}
                                as={Link} to={"/admin/election/voters"} active={"/admin/election/voters" === pathname}
                                onClick={closeSideBar}
                            >
                                Voters Database
                            </Sidebar.Item>

                            <Sidebar.Item
                                label=""
                                as={Link} to={"/admin/election/share-link"} active={"/admin/election/share-link" === pathname}
                                onClick={closeSideBar}
                            >
                                Share voting link
                            </Sidebar.Item>

                            {/* conditionally render sidbar pages */}
                            <Sidebar.Item
                                className={electionType === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}
                                as={Link} to={"/admin/election/voted"} active={"/admin/election/voted" === pathname}
                                // icon={BsPersonCheck}
                                onClick={closeSideBar}
                            >
                                Voted List
                            </Sidebar.Item>

                        </Sidebar.Collapse>

                    </Sidebar.ItemGroup>
                    <Sidebar.ItemGroup>
                        <Sidebar.Item
                            as={Link} to={"/admin/elections"} active={"/admin" === pathname}
                            href="#"
                            icon={HiArrowSmLeft}
                            onClick={closeSideBar}
                        >
                            View All Elections
                        </Sidebar.Item>

                    </Sidebar.ItemGroup>
                </Sidebar.Items>
            </Sidebar>

            {/* click outside sidebar to collapse */}
            <div
                onClick={closeSideBar}
                className="flex flex-grow opacity-70  bg-black">

            </div>
        </div>

    )
}

export default ElectionSidebarComponent;