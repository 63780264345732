import { Button, Spinner, TextInput } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import React from "react";
import ElectionSearchItemComponent from "../components/website/ElectionSearchItemComponent";
import WebsiteCreditsComponent from "../components/website/WebsiteCreditsComponent";
import { searchFilter } from "../constants/HOMEPAGECONTENTS";
import { FIREBASECOLLECTIONS } from "../constants/FIREBASECOLLECTIONS";
import { getFirebaseDocs } from "../util/firebaseFirestoreUtils";
import { onSnapshot, orderBy, where } from "firebase/firestore";
import {  getSearchResultsError, getSearchResultsStart, getSearchResultsSuccess } from "../redux/slices/ElectionSlice";
import { toast } from "react-hot-toast";
import moment from "moment";
import EmptyListPage from "./EmptyPage";
import emptylist from "../assets/lottie/emptylist.json"
import { ELECTION_STATUS } from "../constants/ELECTIONSTATUS";
const ElectionSearchPage = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const authenticateState = useSelector(state => state.authenticate)
    const electionState = useSelector(state => state.election)

    const [selectedFilter, setSelectedFilter] = React.useState(searchFilter.ALL);
    const [filterdedElection, setFilteredElection] = React.useState(electionState?.searchResults ?? []);

   
    
    const onCategoryFilterClicked = React.useCallback( (CATEGORY) => {
        setSelectedFilter(CATEGORY);

        const filtered = electionState?.searchResults.filter(election => {

            const startDateTimeUTC = moment.utc(election?.startDateTimeUTC)
            const endDateTimeUTC = moment.utc(election?.endDateTimeUTC)
            const currentDateTimeUTC = moment.utc();

            if (CATEGORY === searchFilter.ALL) {
                console.log(CATEGORY);
                return true;

            } else if (CATEGORY === searchFilter.UPCOMING) {
                return currentDateTimeUTC.isBefore(startDateTimeUTC)

            }
            else if (CATEGORY === searchFilter.PAST) {
                return currentDateTimeUTC.isAfter(endDateTimeUTC)

            } else if (CATEGORY === searchFilter.INPROGRESS) {
                return currentDateTimeUTC.isAfter(startDateTimeUTC) && currentDateTimeUTC.isBefore(endDateTimeUTC)
            }

        });

        setFilteredElection(filtered);

    }, [selectedFilter]);

    const onSearch = React.useCallback( (SEARCHKEY) => {
        setSelectedFilter(searchFilter.ALL);
        const filtered = electionState?.searchResults.filter(election => {
            return election.name.toString().toLowerCase().includes( SEARCHKEY.toLowerCase())
        });
        setFilteredElection(filtered);
    } ,[]);

    React.useEffect(() => {

        const electionsRef = getFirebaseDocs(
            `${FIREBASECOLLECTIONS.ELECTIONS}`
            ,
            [where( "status","==", ELECTION_STATUS.PUBLISHED), orderBy("createdAt", "desc")]);
            
            dispatch( getSearchResultsStart())

        const unsubscribeListener = onSnapshot(electionsRef,
            {
                next:(querySnapshot) => {
                    console.log("on snap callback called for get search results");
                    try {
                        const elections = []
                        querySnapshot.forEach((doc) => {
                            elections.push(doc.data())
                        });
                        dispatch(getSearchResultsSuccess({ searchResults: elections }));
                        setFilteredElection( elections);
                    } catch (error) {
                        dispatch(getSearchResultsError({ message: error.message }))
                    }
                },
                error:(error)=>{
                    console.log(error);
                    dispatch(getSearchResultsError({ message: error.message }))

                }
            } 
            );

        return () => {
            unsubscribeListener();
        }

    }, []);

    React.useEffect(() => {
        if (electionState.ACTION_TYPE === getSearchResultsError.toString()) {
            toast.error(electionState.getSearchResultsMessage, { duration: 10000 })
        }
    }, [electionState.ACTION_TYPE]);


    return (
        <div >

            {/* //seach  */}
            <div className="flex flex-col gap-2 w-full bg-tertiary-300 p-4">

                <div className="flex justify-center">
                    <TextInput onChange={(e)=> onSearch( e.target.value) } required color={"primary"} className="w-full md:w-7/12" placeholder="Search Election name" />
                </div>

                <div className="flex flex-row flex-wrap justify-center gap-4">
                    <Button color={"primary"} onClick={() => { onCategoryFilterClicked(searchFilter.ALL) }} outline={searchFilter.ALL !== selectedFilter}> All</Button>
                    <Button color={"primary"} onClick={() => { onCategoryFilterClicked(searchFilter.INPROGRESS) }} outline={searchFilter.INPROGRESS !== selectedFilter}> In progress</Button>
                    <Button color={"primary"} onClick={() => { onCategoryFilterClicked(searchFilter.UPCOMING) }} outline={searchFilter.UPCOMING !== selectedFilter}> Upcoming</Button>
                    <Button color={"primary"} onClick={() => { onCategoryFilterClicked(searchFilter.PAST) }} outline={searchFilter.PAST !== selectedFilter}> Past</Button>
                </div>

            </div>

            <div className="flex flex-row flex-wrap justify-center w-full gap-4 my-8">
                {
                electionState.isGetSearchResultsLoading ? <Spinner/> :    
                filterdedElection.length > 0 ?
                    filterdedElection?.map((election, index) =>
                        <ElectionSearchItemComponent
                            key={index.toString()}
                            item={election}
                            voteLink={`/voter/login/${election.userId}/${election?.id}`}
                            viewResultsLink={`/voter/ballot-results/${election.userId}/${election?.id}`}
                            tag={ moment.utc().isBefore(election?.startDateTimeUTC) ? searchFilter.UPCOMING : moment.utc().isAfter(election.endDateTimeUTC) ? searchFilter.PAST : searchFilter.INPROGRESS}

                        />
                    ) :
                    <EmptyListPage
                        lottieJson={emptylist}
                        // buttonText="Create New Election"
                        texxt={"Nothing to show for now... All published elections will be displayed here"}
                    // onButtonClick={() => navigate("/admin/elections/add")}
                    />

                }

            </div>




            <WebsiteCreditsComponent tailwindClassname="w-full md:fixed mt-20 bottom-0" />
        </div>
    )
}

export default ElectionSearchPage;