import { Badge, Button, Progress } from "flowbite-react";
import React from "react";
import { text } from "../constants/tailwind-theme";
import moment from "moment";
import { ELECTION_TYPES } from "../constants/ELECTIONTYPES";
import { ELECTION_STATUS } from "../constants/ELECTIONSTATUS";
import { calculateProgressColor, calculateProgressPercentage } from "../util/miscUtil";

const ElectionItemComponent = (props) => {
    const { name, totalVotes, voteLimit, startDateTimeUTC, endDateTimeUTC, imageUrl, paymentPlan, type, categories, status } = props.item;
    const { onManageClicked, onEditClicked, onUpgradeClicked } = props;
    
    const progressPercentage =  calculateProgressPercentage (totalVotes ,voteLimit ) ;
    const progressColor = calculateProgressColor ( progressPercentage);

    
    return (
        <div className=" flex flex-col gap-2 border-2 rounded bg-white shadow-md p-4 w-72 m-5">
            <div className=" relative border w-full h-40">
                <img className="absolute top-0 w-full h-full" src={imageUrl} alt="logo" />
                <Badge color={status !== ELECTION_STATUS.PUBLISHED? "failure": "success"} className="absolute top-2 left-0  uppercase ">{status}</Badge>
                <Badge color={"warning"} className="absolute top-8 left-0  uppercase ">{type}</Badge>
                <Badge color={"purple"} className="absolute top-14 left-0  uppercase ">{paymentPlan?.toString().replace(/_/g," ")}</Badge>
            </div>

            <div className="flex flex-row">
                <h2 className={text.heading3}>{name}</h2>
            </div>
            <div className="flex flex-row">
                <h2>Total votes:</h2>
                <span className="ml-2">{totalVotes}</span>
            </div>
            <div className="flex flex-col">
                <h2>Vote Limit: {totalVotes}/{type=== ELECTION_TYPES.PUBLIC? "∞" : voteLimit}</h2>
                <Progress
                    size={"lg"}
                    color={progressColor}
                    className={type === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}
                    progress={progressPercentage}/>
            </div>
            <div className="flex flex-row">
                <h2>Categories:</h2>
                <span className="ml-2">{categories?.length ?? 0}</span>
            </div>
            <div className="flex flex-row">
                <h2>Starts on:</h2>
                <span className="ml-1">  {moment(startDateTimeUTC).local().format("lll")}</span>
            </div>
            <div className="flex flex-row">
                <h2>Ends on:</h2>
                <span className="ml-1">{moment(endDateTimeUTC).local().format("lll")} </span>
            </div>
            <div className="flex flex-row justify-center flex-wrap gap-1">
                <Button color="primary" size={"xs"} onClick={onManageClicked}>
                    Manage
                </Button>
                <Button
                    color="warning"
                    size={"xs"}
                    onClick={onUpgradeClicked}
                    className={type === ELECTION_TYPES.PUBLIC ? 'hidden' : 'block'}


                >
                    Upgrade Plan
                </Button>

            </div>

        </div>
    )
}
export default ElectionItemComponent;