import { createSlice } from "@reduxjs/toolkit"

const INITIAL_STATE = {
    ACTION_TYPE: "",

    isCreateSpreadsheetLoading: false,
    isCreateSheetLoading: false,

    createSpreadsheetMessage: "",
    createSheetMessage: "",

    spreadsheet :{}

}

export const SpreadSheetSlice = createSlice({
    name: 'spreadsheet',
    initialState: INITIAL_STATE,
    reducers: {
        createSpreadsheetStart: state => {
            state.ACTION_TYPE = createSpreadsheetStart.toString()
            state.isCreateSpreadsheetLoading = true
            state.createSpreadsheetMessage = ""
        },
        createSpreadsheetSuccess: (state, action) => {
            state.ACTION_TYPE = createSpreadsheetSuccess.toString()
            state.isCreateSpreadsheetLoading = false
            state.createSpreadsheetMessage = action.payload.message ;
            state.spreadsheet = action.payload.spreadsheet ;
        },
        createSpreadsheetError: (state, action) => {
            state.ACTION_TYPE = createSpreadsheetError.toString()
            state.isCreateSpreadsheetLoading = false
            state.createSpreadsheetMessage = action.payload.message
        },


        createSheetStart: state => {
            state.ACTION_TYPE = createSheetStart.toString()
            state.isCreateSheetLoading = true
            state.createSheetMessage = ""
        },
        createSheetSuccess: (state, action) => {
            state.ACTION_TYPE = createSheetSuccess.toString()
            state.isCreateSheetLoading = false
            state.createSheetMessage = action.payload.message ;
        },
        createSheetError: (state, action) => {
            state.ACTION_TYPE = createSheetError.toString()
            state.isCreateSheetLoading = false
            state.createSheetMessage = action.payload.message
        },

      

        resetActionType: state => {
            state.ACTION_TYPE = resetActionType.toString();
        },
    }
});

export const {
    createSpreadsheetStart, createSpreadsheetSuccess, createSpreadsheetError,
    createSheetStart, createSheetSuccess, createSheetError,
    resetActionType,
} = SpreadSheetSlice.actions

export default SpreadSheetSlice.reducer