import { Outlet } from "react-router-dom";
import WebsiteNavbarComponent from '../components/website/WebsiteNavbarComponent';


export const WebsiteLayout = () => {

    return (

        <div className="flex h-screen w-full flex-col overflow-hidden">
            <WebsiteNavbarComponent />


            <main className="flex-1 overflow-auto " >
                <div >
                    <Outlet />
                </div>
            </main>


        </div>

    )
}
export default WebsiteLayout;