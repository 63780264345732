import { Button } from "flowbite-react";
import { useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import VoteCreditsComponent from "../components/VoterCreditsComponent";
import { text } from "../constants/tailwind-theme";
import { ELECTION_STATUS } from "../constants/ELECTIONSTATUS";

export const BallotInfoComponent = (props) => {
    //check if admin id and election id is valid
    const { adminId, electionId } = useParams();
    // const { state } = useLocation();

    const electionState = useSelector(state => state.election)
    return (
        <div className="flex flex-col items-center gap-4 w-full h-screen ">

            <div className="flex flex-col items-center justify-center my-10">
                <img className="h-40  border " src={electionState?.election?.imageUrl} alt="logo" />
                <h1 className={twMerge(text.heading2, " text-center")} >{electionState?.election?.name}</h1>
            </div>

            <h1 className={twMerge(text.heading1, "text-center px-2")}>{props?.message ?? ""} </h1>

            <p className={text.description1}>Here are some things you can do</p>


            <div className="space-y-2  mb-20">
                <Button
                    className={electionState?.election?.status !== ELECTION_STATUS.PUBLISHED ? 'hidden' : 'block'}
                    color={"primary"}
                    href={`/voter/ballot-results/${adminId}/${electionId}`}
                >View Results</Button>
                <Button color={"warning"} href="/auth/admin-login">Create your own election, its free!</Button>
                <Button color={"success"} href="/">Learn more about this software</Button>
            </div>

            <VoteCreditsComponent tailwindClassname="w-full  md:fixed bottom-0" />

        </div>
    )
}