import { uuidv4 } from "@firebase/util";
import { Button, FileInput, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import React from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import CreatableSelect from 'react-select/creatable';
import { addCandidateMiddleware } from "../../redux/middlewares/CandidateMiddleware";
import { uploadFileToS3Middleware } from "../../redux/middlewares/FileMiddleware";
import { addCandidateSuccess, resetActionType as resetActionTypeCandidate } from "../../redux/slices/CandidateSlice";
import { resetActionType as resetActionTypeFile, setUploadType, uploadFileSuccess } from "../../redux/slices/FileSlice";
import { getFormErrorMessage, isFormFieldValid } from "../../util/formikUtils";

import { editElectionMiddleware } from "../../redux/middlewares/ElectionMiddleware";
import { resetActionType as resetActionTypeElection } from "../../redux/slices/ElectionSlice";
import { useNavigate } from "react-router-dom";
import PageTitleComponent from "../../components/PageTitleComponent";
import moment from "moment";

const AddCandidatePage = () => {

    const dispatch = useDispatch()
    const electionState = useSelector(state => state.election)
    const candidateState = useSelector(state => state.candidate);
    const navigate = useNavigate();

    const fileState = useSelector((state) => state.file)

    //get the options to be used in the category dropdown
    const selectOptions = React.useMemo(() => {
        return electionState?.selectedElection.categories.map((value, index, arrray) => {
            return { value: index, label: value }
        });

    }, [electionState?.selectedElection.categories]);

    const [options, setOptions] = React.useState(selectOptions)




    const formik = useFormik({
        initialValues: {
            category: "",
            candidateName: "",
            file: null,
        },

        validate: (values) => {
            const errors = {};
            if (!values.category) {
                errors.category = "category is required.";
            }

            if (!values.candidateName) {
                errors.candidateName = "Candidate Name is required.";
            }

            if (!values.file) {
                errors.file = "Candidate image is required";
            }

            if (values.file && (values.file.size > 1000000)) {
                errors.file = "The file selected cannot exceed 1mb";
            }

            return errors;
        },

        onSubmit: (values) => {

            dispatch(setUploadType("ADD_CANDIDATE"));
            dispatch(uploadFileToS3Middleware(values.file, "candidate_images"));
        },
    });

    React.useEffect(() => {

        if (fileState.ACTION_TYPE === uploadFileSuccess.toString() && fileState.UPLOAD_TYPE === "ADD_CANDIDATE") {

            const candidateId = uuidv4();
            const candidate = {
                id: candidateId,
                position: Date.now(),
                name: formik.values.candidateName,
                imageUrl: fileState.uploadURL,
                category: formik.values.category.label,
                totalVotesYes: 0,
                totalVotesNo: 0,
                createdAt: moment.utc().toISOString(),
            }

             //get only the selected category
             const filteredCategory= options.filter((option) =>(!option.isNew ||option.isNew && option.value === formik.values.category.value))
             const categoryLabels = filteredCategory.map((category)=> category.label);

            const editedElection = {
                categories: categoryLabels
            }


            dispatch(addCandidateMiddleware(electionState.selectedElection.id, candidateId, candidate))

            //update the categories for the election
            dispatch(editElectionMiddleware(electionState.selectedElection.id, editedElection));

            dispatch(resetActionTypeFile());
            dispatch(resetActionTypeElection());
        }

    }, [fileState.ACTION_TYPE])


    React.useEffect(() => {
        if (candidateState.ACTION_TYPE === addCandidateSuccess.toString()) {
            toast.success("Candidate added successfully",{duration:5000});
             formik.resetForm();
            dispatch(resetActionTypeCandidate());
            navigate("/admin/election/candidates")      

        }
    }, [candidateState.ACTION_TYPE])

    return (
        <div className="flex flex-col items-center">
            <PageTitleComponent
                title="Add New Candidate"
            />

            <div className="flex flex-col items-center w-full md:w-6/12 space-y-4  p-2 mt-10" >

                <div className="w-full">
                    <div className="mb-2 block">
                        <Label className="mb-2 block" htmlFor="email1" value="Candidate Name" />
                    </div>
                    <TextInput
                        id="candidateName"
                        name="candidateName"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.candidateName}
                        placeholder="Enter candiate name"
                        required={true}
                        color={isFormFieldValid(formik, "candidateName") ? "primary" : "failure"}
                        helperText={getFormErrorMessage(formik, "candidateName")}
                    />
                </div>

                <div className="w-full">
                    <div className="mb-2 block">
                        <Label className="mb-2 block" htmlFor="email1" value="Type to create a new category or Select an existing one" />
                    </div>
                    <CreatableSelect

                        styles={{
                            control: (baseStyles, state) => ({
                                ...baseStyles,
                                backgroundColor: isFormFieldValid(formik, "category") ? "white" : "#FDF2F2",
                                borderWidth: 2,
                                borderRadius: "0.5rem",
                                borderColor: isFormFieldValid(formik, "category") ? "#024681" : "#E02424",
                            }),
                        }}
                        formatCreateLabel={(value => `Create "${value}" category`)}
                        isClearable
                        placeholder="Create or select a category..."
                        onChange={(newValue) => { console.log(newValue); formik.setFieldValue("category", newValue) }}
                        onCreateOption={(inputvalue) => {

                            const newValue = { value: Date.now(), label: inputvalue, isNew: true };
                            setOptions(prev => [...prev, newValue]);
                            formik.setFieldValue("category", newValue);

                        }}
                        options={options}
                        value={formik.values.category}
                    />
                    <span className="text-red-500">{getFormErrorMessage(formik, "category")}</span>


                </div>

                <div className="w-full">
                    <div className="mb-2 block">
                        <Label
                            htmlFor="file"
                            value="Select an image for this candidate"
                        />
                    </div>
                    <FileInput
                        accept="image/*"
                        id="file"
                        name="file"
                        type={"file"}
                        onChange={(e) => {
                            formik.setFieldValue("file", e.target.files[0]);
                        }}
                        onBlur={formik.handleBlur}
                        placeholder="End time"
                        color={isFormFieldValid(formik, "file") ? "primary" : "failure"}
                        helperText={getFormErrorMessage(formik, "file")}
                    />
                </div>
            </div>
            <Button
                className="mt-10"
                disabled={fileState.isUploadFileLoading || candidateState.isAddCandidateLoading}
                onClick={formik.handleSubmit}>
                {fileState.isUploadFileLoading || candidateState.isAddCandidateLoading ? <Spinner /> : "SUBMIT"}
            </Button>
           

        </div>

    )
}

export default AddCandidatePage;