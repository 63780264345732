import { onSnapshot } from "firebase/firestore";
import { Button, Label, Modal, Spinner, TextInput } from "flowbite-react";
import { useFormik } from "formik";
import moment from "moment";
import React from "react";
import { toast } from "react-hot-toast";
import { HiOutlineExclamationCircle } from "react-icons/hi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { BallotInfoComponent } from "../../components/BallotInfoComponent";
import VoterCreditsComponent from "../../components/VoterCreditsComponent";
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { text } from "../../constants/tailwind-theme";
import { authenticateVoterMiddleware, isAnonymousVotedMiddleware, verifyVoterOTPMiddleware } from "../../redux/middlewares/VoterMiddleware";
import { getElectionError, getElectionStart, getElectionSuccess } from "../../redux/slices/ElectionSlice";
import { authenticateVoterError, authenticateVoterSuccess, resetActionType, verifyVoterOTPError, verifyVoterOTPSuccess } from "../../redux/slices/VoterSlice";
import { getFirebaseDocRef } from "../../util/firebaseFirestoreUtils";
import { getFormErrorMessage, isFormFieldValid } from "../../util/formikUtils";
import LoadingPage from "../LoadingPage";
import { VoterLoginOpenElectionPage } from "./VoterLoginOpenElectionPage";
import VoterLoginElectionDetailsComponent from "../../components/VoterLoginElectionDetailsComponent";
import { ELECTION_TYPES } from "../../constants/ELECTIONTYPES";
import { ELECTION_STATUS } from "../../constants/ELECTIONSTATUS";
import VoterVerifyOTPPage from "./VoterVerifyOTPPage";

import EmptyPage from '../EmptyPage'
import lottieError from "../../assets/lottie/generalerror.json"

const VoterLoginPage = () => {

    //check if admin id and election id is valid
    const { adminId, electionId, voterEmail } = useParams();

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const voterState = useSelector(state => state.voter)
    const electionState = useSelector(state => state.election);

    //curent time hooks
    const [isGetCurrentTime, setIsGetCurrentTime] = React.useState(false);
    const [currentTimeMoment, setCurrentTimeMoment] = React.useState(moment.utc());

    const [showConfirmationModal, setShowConfirmationModal] = React.useState(false)
    const startDateTimeUTC = React.useMemo(() => moment.utc(electionState.election?.startDateTimeUTC), [electionState.election?.startDateTimeUTC]);
    const endDateTimeUTC = React.useMemo(() => moment.utc(electionState.election?.endDateTimeUTC), [electionState.election?.endDateTimeUTC]);

    const electionType = React.useMemo(() => electionState?.election?.type, [electionState?.election?.type]);

    const formikVoterAuthenticate = useFormik({
        initialValues: {
            votingId: "",
        },

        validate: (values) => {
            const errors = {};
            if (!values.votingId) {
                errors.votingId = "Email is required.";
                return errors;

            }
            if (! /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,5})+$/.test(values.votingId)) {
                errors.votingId = "Enter a valid email address.";
                return errors;
            }

            if (electionState.election.totalVotes >= electionState.election.voteLimit) {
                errors.votingId = "Voting limit has been exceeded. The admin of this election will be notified to upgrade.";
                return errors;

            }

            return errors;
        },

        onSubmit: (values) => {
            setShowConfirmationModal(true);
        },
    });

    const formikVerifyOTP = useFormik({
        initialValues: {
            voterId: voterEmail,
            otp: "",
        },

        validate: (values) => {
            const errors = {};
            if (!values.otp) {
                errors.otp = "OTP is required.";
                return errors;
            }
            if (!values.voterId) {
                errors.otp = "Email is required.";
                return errors;
            }

            if (electionState.election.totalVotes >= electionState.election.voteLimit) {
                errors.votingId = "Voting limit has been exceeded. The admin of this election will be notified to upgrade.";
                return errors;

            }

            return errors;
        },

        onSubmit: (values) => {
            console.log(values);
            dispatch(verifyVoterOTPMiddleware(values.voterId, values.otp, adminId, electionId));
        },
    });

    //get current time from internet
    React.useEffect(() => {

        const fetchCurrentTimeUTC = async () => {
            setIsGetCurrentTime(true);
            try {

                const response = await fetch('https://worldtimeapi.org/api/timezone/Etc/UTC');
                const data = await response.json();
                setCurrentTimeMoment(moment.utc(data.utc_datetime));
                setIsGetCurrentTime(false);
                console.log("internet time", data.utc_datetime);
                
            } catch (error) {
                setCurrentTimeMoment(moment.utc());
                setIsGetCurrentTime(false);

            }
        }

        fetchCurrentTimeUTC();

    }, []);

    //realtime election listener
    React.useEffect(() => {
        const docRef = getFirebaseDocRef(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionId}`);

        dispatch(getElectionStart());

        const unsubscribe = onSnapshot(docRef, (doc) => {

            if (doc?.data() === undefined) {
                dispatch(getElectionError({ message: "Invalid URL" }));
                return;
            }
            dispatch(getElectionSuccess({ election: doc.data() }));
        },
            error => {
                dispatch(getElectionError({ message: error.message }))
            }
        );

        return () => {
            unsubscribe();
        }
    }, []);

    //authenticate voter event
    React.useEffect(() => {
        if (voterState.ACTION_TYPE === authenticateVoterSuccess.toString()) {
            dispatch(resetActionType())
            formikVerifyOTP.setFieldValue("voterId", formikVoterAuthenticate.values.votingId);
            navigate(`/voter/login/${adminId}/${electionId}/${formikVoterAuthenticate.values.votingId}`, { state: { voterId: formikVoterAuthenticate.values.votingId, adminId: adminId, electionId: electionId } })

        } else if (voterState.ACTION_TYPE === authenticateVoterError.toString()) {
            toast.error(voterState.authenticateVoterMessage, { duration: 14000 });
            dispatch(resetActionType());
        }
    }, [voterState.ACTION_TYPE]);

    //verify otp events
    React.useEffect(() => {
        if (voterState.ACTION_TYPE === verifyVoterOTPSuccess.toString()) {

            //getting here means otp is valid
            navigate(`/voter/ballot/${adminId}/${electionId}`, { replace: true, state: { voterId: formikVerifyOTP.values.voterId } })
            dispatch(resetActionType());

        } else if (voterState.ACTION_TYPE === verifyVoterOTPError.toString()) {
            toast.error(voterState.verifyVoterOTPMessage);
            dispatch(resetActionType());

        }
    }, [voterState.ACTION_TYPE]);

    //if election is loading
    if (electionState.isGetElectionLoading || isGetCurrentTime) {
        return <LoadingPage loadingText="Loading..." />
    }
    //if an error occured getting election details
    else if (electionState.ACTION_TYPE === getElectionError.toString()) {
        return <EmptyPage lottieJson={lottieError} texxt={"INVALID VOTING LINK"} />
    }

    //if election has not been published
    else if (electionState.election.status !== ELECTION_STATUS.PUBLISHED) {
        return <BallotInfoComponent message={"Admin has not PUBLISHED this election yet. Come back later"} />
    }

    //if election has not started
    else if (currentTimeMoment.isBefore(startDateTimeUTC)) {
        return <BallotInfoComponent message={"Election has not started. Please come back after: " + startDateTimeUTC.local().format("lll")} />
    }

    //if election has ended
    else if (currentTimeMoment.isAfter(endDateTimeUTC)) {
        return <BallotInfoComponent message="Election has ended" />
    }

    //if this is a public election
    else if (electionType === ELECTION_TYPES.PUBLIC) {

        //check if user has already voted
        const anonymostVoterObject = isAnonymousVotedMiddleware(adminId, electionId);
        if (anonymostVoterObject != null) {
            return <BallotInfoComponent message="You have already voted" />
        }

        return <VoterLoginOpenElectionPage />
    }

    //if user is verifying OTP
    else if (voterEmail) {

        return <VoterVerifyOTPPage formikVerifyOTP={formikVerifyOTP} />
    }


    return (

        <div className="flex flex-col items-center spaced-y-4 w-full h-full">

            <div className="flex flex-col items-center justify-center mt-10">
                <img className="h-40  border " src={electionState?.election?.imageUrl} alt="logo" />
                <h1 className={twMerge(text.heading2, " text-center")} >{electionState?.election?.name}</h1>
            </div>


            <div className="flex flex-col items-center w-full md:w-6/12 gap-4 border-0 p-4 ">

                {/* Email */}
                <div className="w-full">
                    <div className="mb-2 block">
                        <Label className="mb-2 block" htmlFor="email1" value="Your email" />
                    </div>
                    <TextInput
                        id="votingId"
                        name="votingId"
                        onChange={formikVoterAuthenticate.handleChange}
                        onBlur={formikVoterAuthenticate.handleBlur}
                        value={formikVoterAuthenticate.values.votingId}
                        type="email"
                        placeholder="Enter your email"
                        required={true}
                        color={isFormFieldValid(formikVoterAuthenticate, "votingId") ? "primary" : "failure"}
                        helperText={getFormErrorMessage(formikVoterAuthenticate, "votingId")}
                    />
                    <p className={twMerge(text.description2, "text-gray-500  mt-1")}>You will be redirected to another page to enter your OTP</p>
                </div>



                <Button
                    disabled={voterState.isAuthenticateVoterLoading}
                    onClick={formikVoterAuthenticate.handleSubmit}
                    color={"primary"}
                    className="my-4"
                >
                    {voterState.isAuthenticateVoterLoading ? <Spinner className="mr-3 w-5 h-5" /> : null}
                    Request OTP
                </Button>

                <VoterLoginElectionDetailsComponent
                    adminId={adminId}
                    electionId={electionId}
                    election={electionState?.election}
                />


            </div>

            <Modal
                // size={"sm"}
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
            >

                <Modal.Header >Confirm Email</Modal.Header>

                <Modal.Body>
                    <HiOutlineExclamationCircle className="mx-auto mb-4 h-14 w-14  dark:text-gray-200" />
                    <h3 className={twMerge(text.heading3, "text-center mt-1")}>
                        We will send an OTP to {formikVoterAuthenticate.values.votingId}. Is this email correct? or would you like to edit?
                    </h3>
                </Modal.Body>

                <Modal.Footer className="flex flex-row justify-center">
                    <Button outline onClick={() => setShowConfirmationModal(false)}>
                        No, cancel
                    </Button>
                    <Button
                        color={"primary"}
                        onClick={() => {
                            dispatch(authenticateVoterMiddleware(formikVoterAuthenticate.values.votingId, adminId, electionId));
                            setShowConfirmationModal(false);
                        }}>
                        Yes, i'm sure
                    </Button>
                </Modal.Footer>
            </Modal>

            <VoterCreditsComponent tailwindClassname="w-full md:fixed mt-20 bottom-0" />
        </div>
    )
}

export default VoterLoginPage;