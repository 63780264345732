import { Button, Modal } from "flowbite-react";
import React from "react";
import {HiOutlineExclamationCircle} from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";


const ConfirmationModal = ({ headerText, bodyText, onToggle, confirmationText, show, onConfirm, onCancel }) => {


    return (
        <Modal
        size={"sm"}
            show={show}
            onClose={onCancel}
        >

            <Modal.Header>
                {headerText ?? "Are you sure?"}
            </Modal.Header>

            <Modal.Body>
                <HiOutlineExclamationCircle className="mx-auto mb-4 h-10 w-10 text-red-500 dark:text-gray-200" />
                <h3 className={twMerge( text.heading3, "text-center")}>
                    {bodyText ?? "Are you sure?"}
                </h3>


            </Modal.Body>

            <Modal.Footer className="flex flex-row justify-center">
                <Button
                    color={"primary"}
                    onClick={onConfirm}>
                    {confirmationText ?? "Yes, i'm sure"}
                </Button>
                <Button outline onClick={onCancel}>
                    No, cancel
                </Button>
            </Modal.Footer>
        </Modal>

    )
}

export default ConfirmationModal;