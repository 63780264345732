import { Button } from "flowbite-react";
import React, { useEffect } from "react";
import { BsFileEarmarkSpreadsheetFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import PageTitleComponent from "../../components/PageTitleComponent";
import { getVotersMiddleware } from "../../redux/middlewares/VoterMiddleware";
import EmptyListPage from "../EmptyPage";

import { HiUserGroup } from "react-icons/hi";
import { twMerge } from "tailwind-merge";
import dataerror from "../../assets/lottie/dataerror.json";
import generalerror from "../../assets/lottie/generalerror.json";
import googlesheet from "../../assets/lottie/googlesheet.json";
import happy from "../../assets/lottie/jumping-lottie-animation.json";
import searching from "../../assets/lottie/searching.json";
import ConfirmationModal from "../../components/ConfirmationModal";
import { text } from "../../constants/tailwind-theme";
import { getVotersError } from "../../redux/slices/VoterSlice";
import ReactGA from 'react-ga';

const ManageVotersPage = () => {

    const dispatch = useDispatch();
    const authenticateState = useSelector(state => state.authenticate);
    const electionState = useSelector(state => state.election);

    const votersState = useSelector(state => state.voter)

    const [openSpreadsheetModal, setOpenSpreadsheetModal] = React.useState(false)


    useEffect(() => {

        const getSpreadsheetStats = () => dispatch(getVotersMiddleware(authenticateState.user.spreadsheetId, `${electionState?.selectedElection?.id}!A1:D`));

        //on tab focused
        window.addEventListener("focus", getSpreadsheetStats);

        //on page load
        getSpreadsheetStats();

        return () => {
            window.removeEventListener("focus", getSpreadsheetStats)
        }
    }, []);



    return (
        <div className="">

            <PageTitleComponent
                title="Voters Database"
                subtitle="Click on the 'Open Voters Database' button to add your voters, you will be redirected to a Google spreadsheet. Return to this page after adding your voters"
                rightComponent={
                    <div className="flex flex-row flex-wrap gap-2 ">

                        <Button
                            onClick={() => {
                                console.log("on click");
                                setOpenSpreadsheetModal(true);
                            }}
                            color="primary"
                        >
                            <BsFileEarmarkSpreadsheetFill className="mr-3" /> Open Voters' Database
                        </Button>


                    </div>
                }
            />

            <div className="flex flex-col w-full">

                {/* DASHBOARD TITLE */}
                <h2 className={twMerge(text.heading3, "m-2")}>Spreadsheet stats</h2>

                {/* DASHBOARD CONTENT */}
                <div className="flex flex-row flex-wrap justify-between gap-1">
                    <div className="flex flex-col border-2 rounded bg-white shadow-md p-4 w-full  md:w-3/12 ">
                        <h2 className={twMerge(text.heading3, "text-base")}><HiUserGroup className="inline mb-1" size={20} /> Spreadsheet Errors</h2>
                        <h2 className={twMerge(text.description2, "leading-3 text-gray-600")}>The total number of errors in your spreadsheet</h2>
                        <h2 className={twMerge(text.heading1)}>{votersState.spreadSheetDetails?.cellsErrorCount ?? "N/A"}</h2>
                    </div>
                    <div className="flex flex-col border-2 rounded bg-white shadow-md p-4 w-full  md:w-3/12 ">
                        <h2 className={twMerge(text.heading3, "text-base")}><HiUserGroup className="inline mb-1" size={20} /> Eligible Voters</h2>
                        <h2 className={twMerge(text.description2, "leading-3 text-gray-600")}>Voters in the spreadsheet who can vote without any issues.</h2>

                        <h2 className={twMerge(text.heading1)}>{ votersState.isGetVotersLoading ? "N/A" :(votersState.spreadSheetDetails?.voterCount - votersState.spreadSheetDetails?.rowErrorCount) }</h2>
                    </div>
                    <div className="flex flex-col border-2 rounded bg-white shadow-md p-4 w-full  md:w-3/12 ">
                        <h2 className={twMerge(text.heading3, "text-base")}><HiUserGroup className="inline mb-1" size={20} /> Spreadsheet rows</h2>
                        <h2 className={twMerge(text.description2, "leading-3 text-gray-600")}>The number of rows/users in your voters database</h2>

                        <h2 className={twMerge(text.heading1)}>{votersState.spreadSheetDetails?.voterCount ?? "N/A"}</h2>
                    </div>

                </div>

            </div>



            {
                votersState.isGetVotersLoading ?
                    <div className="flex justify-center items-center w-full">
                        <EmptyListPage
                            lottieJson={searching}
                            texxt="Scanning your spreadsheet..."

                        />

                    </div> :
                    votersState.ACTION_TYPE === getVotersError.toString() ?
                        <div className="flex justify-center items-center w-full">
                            <EmptyListPage
                                lottieJson={generalerror}
                                texxt={votersState.getVotersMessage}
                            />
                        </div> :
                        votersState.spreadSheetDetails?.voterCount === 0 ?

                            <div className=" flex  items-center justify-center w-full my-5">
                                <EmptyListPage
                                    lottieJson={googlesheet}
                                    buttonText="Open Voters Database!"
                                    texxt="You have not added any voters yet. Click the button below, you will be redirected to a Google Spreadsheet to add your voters. Return to this page after adding your voters"
                                    onButtonClick={() => {
                                        window.open(`${electionState?.selectedElection?.sheetURL}`, '_blank', 'noopener,noreferrer');
                                    }
                                    }
                                />
                            </div> :

                            votersState.spreadSheetDetails?.cellsErrorCount > 0 ?


                                <div className=" flex  items-center justify-center w-full my-5">

                                    <EmptyListPage
                                        lottieJson={dataerror}
                                        buttonText="Open Voters Database!"
                                        texxt={`${votersState.spreadSheetDetails?.cellsErrorCount} cells in your spreadsheet have issues that need to be fixed. Cells with errors will be highlighted red, please fix them and return to this page`}
                                        onButtonClick={() => {
                                            window.open(`${electionState?.selectedElection?.sheetURL}`, '_blank', 'noopener,noreferrer');
                                        }
                                        }
                                    />
                                </div>
                                :
                                <div className=" flex  items-center justify-center w-full my-5">

                                    <EmptyListPage
                                        lottieJson={happy}
                                        // buttonText="Manage Voters"
                                        texxt="Hooray! No issues detected in your spreadsheet. 😉"
                                        onButtonClick={() => {
                                            window.open(`${electionState?.selectedElection?.sheetURL}`, '_blank', 'noopener,noreferrer');
                                        }
                                        }
                                    />
                                </div>

            }


            {openSpreadsheetModal ?
                <ConfirmationModal
                    headerText="Manage Voters?"
                    bodyText="You will be redirected to a Google spreadsheet. Please return to this page after adding or editing your voters' database"
                    onConfirm={() => {
                        window.open(`${electionState?.selectedElection?.sheetURL}`, '_blank', 'noopener,noreferrer');
                        setOpenSpreadsheetModal(false);
                        ReactGA.event({category:"SPREADSHEETS",action:"OPEN VOTERS DATABASE SUCCESS"} )

                    }}
                    confirmationText="Ok"
                    onCancel={() => setOpenSpreadsheetModal(false)}
                    show={openSpreadsheetModal}
                /> : null}



        </div>
    )
}
export default ManageVotersPage;