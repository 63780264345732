import { Button } from "flowbite-react";
import React from "react";
import { HiTrash } from "react-icons/hi";
import { IoCaretDown, IoCaretUp } from "react-icons/io5";
import { twMerge } from "tailwind-merge";
import { text } from "../constants/tailwind-theme";

const CategoryItemComponent = (props) => {
    const { title, onMoveUpClicked, onMoveDownClicked,onDeleteClicked, index, categorySize ,candidateSize } = props;

    return (
        <div className="flex flex-row flex-wrap justify-between border-b-2 border-gray-200 p-2">
            <h1 className={twMerge(text.heading2, "text-center md:text-start")}>{title} </h1>

            <div className="flex flex-wrap gap-1">
                {
                    index === 0 ? null : <Button pill onClick={onMoveUpClicked} color={"primary"} size={"xs"}>
                        <IoCaretUp  className="mr-1" />Move Up
                    </Button>
                }

                { index+1 === categorySize? null :
                    <Button pill onClick={onMoveDownClicked} color={"primary"} size={"xs"}>
                        <IoCaretDown  className="mr-1" /> Move Down
                    </Button>
                }
                { candidateSize !== 0 ? null :
                    <Button pill onClick={onDeleteClicked} color={"failure"} size={"xs"}>
                        <HiTrash />
                    </Button>
                }

            </div>
        </div>
    )
}
export default CategoryItemComponent;