import React from "react";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import { button, text } from "../../constants/tailwind-theme";

const FeatureCardComponent = (props) => {
    const { icon, title, title2 } = props;

    return (
        <div className="flex w-full lg:w-5/12 p-4 bg-gray-100 rounded-md">
            <div className="p-2">
                {icon}
            </div>
            <div className="p-2">
                <h3 className={twMerge(text.heading2, "text-left")}>{title}</h3>
                <p className={twMerge(text.description1, "text-justify")}>{title2}</p>
            </div>
        </div>
    )
}
export default FeatureCardComponent;