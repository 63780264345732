import { auth } from "../../constants/firebase-config"
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS"
import {  setFirebaseDoc, updateFirebaseDoc } from "../../util/firebaseFirestoreUtils"
import { addPaymentError, addPaymentStart, addPaymentSuccess, editPaymentError, editPaymentStart, editPaymentSuccess, getPaymentError, getPaymentStart, getPaymentSuccess } from "../slices/PaymentSlice"
import ReactGA from 'react-ga';

export const addPaymentMiddleware = (payment) => {
    return async (dispatch) => {
        dispatch(addPaymentStart())
        try {
            await setFirebaseDoc(`${FIREBASECOLLECTIONS.PAYMENTS}/${payment.id}`, payment)
            dispatch(addPaymentSuccess({ payment: payment }));

            ReactGA.event({ category:"PAYMENTS", action :"ADD PAYMENT SUCCESS" ,value: payment.amount  });
            
        } catch (error) {
            const errorCode = error.code
            console.log(`error: ${error}`)
            dispatch(addPaymentError({ message: errorCode ?? error }))
            ReactGA.event({ category:"ERRORS", action :"ADD PAYMENT ERROR" });

        }
    }
}


