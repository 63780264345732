//  firebase auth
import {  signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut, signInWithPopup } from "firebase/auth";
import { auth, provider } from "../constants/firebase-config";


export const signUpWithEmailAndPasswordFirebase = async (email, password) => {

    try {

        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        return userCredential;

    } catch (error) {
        throw error;
    }

}

export const signInWithGoogleFirebase = async () => {

    try {

        const userCredential = await signInWithPopup(auth, provider);
        return userCredential;

    } catch (error) {
        throw error;
    }

}



export const signInWithEmailAndPasswordFirebase = async (email, password) => {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        return userCredential;

    } catch (error) {
        throw error;
    }

}

export const forgotPasswordFirebase = async (email) => {
    try {
        await sendPasswordResetEmail(auth, email);
    } catch (error) {
        throw error;
    }

};

export const updatePassword = async (email, newPassword) => {

    if (!auth.currentUser) {
        throw "User must be signed in first";
    }

    await updatePassword(auth.currentUser, newPassword);
}



export const signOutFirebase = async () => {

    try {
        await signOut(auth);
    } catch (error) {
        throw error;
    }


}