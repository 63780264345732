import { combineReducers } from "redux";
import sidebarSlice from "./SidebarSlice";
import AuthenticateSlice from "./AuthenticateSlice";
import UserSlice from "./UserSlice";
import FileSlice from "./FileSlice";
import NavigationSlice from "./NavigationSlice";
import ElectionSlice from "./ElectionSlice";
import CandidateSlice from "./CandidateSlice";
import VoterSlice from "./VoterSlice";
import SpreadSheetSlice from "./SpreadSheetSlice";
import ResultSlice from "./ResultSlice";
import PaymentSlice from "./PaymentSlice";


export default combineReducers({
  sidebar: sidebarSlice,
  authenticate: AuthenticateSlice,
  file: FileSlice,
  user: UserSlice,
  election: ElectionSlice,
  voter: VoterSlice,
  result: ResultSlice,
  spreadsheet: SpreadSheetSlice,
  candidate: CandidateSlice,
  payment: PaymentSlice,
  navigation: NavigationSlice,
});
