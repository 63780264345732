import { Button, Navbar } from "flowbite-react";
import React from "react";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { twMerge } from "tailwind-merge";

import vote_logo_transparent_bg from '../../assets/images/vote_logo_white_bg.png';
import { link } from "../../constants/tailwind-theme";
import { navLinks } from "../../constants/HOMEPAGECONTENTS";

const WebsiteNavbarComponent = (props) => {


    const dispatch = useDispatch()
    const authState = useSelector(state => state.authenticate)


    return (
        <>


            <Navbar
                fluid={true}
                rounded={false}
            >
                <Navbar.Brand href="/">
                    <img
                        src={vote_logo_transparent_bg}
                        className="mr-3 h-6 sm:h-9"
                        alt="Flowbite Logo"
                    />

                </Navbar.Brand>
                <div className="flex md:order-2 space-x-2">


                    <Link to={"/auth/admin-login"}>
                        <Button color="warning">
                            GET STARTED
                        </Button>
                    </Link>

                    <Navbar.Toggle />
                </div>
                <Navbar.Collapse>
                    {
                        navLinks.map((navLink, index) =>

                            <Navbar.Link
                                href={navLink.href}
                                active={true}
                                key={index.toString()}
                            >
                                <h3 className={twMerge(link.heading3)}>{navLink.name}</h3>
                            </Navbar.Link>
                        )
                    }

                </Navbar.Collapse>
            </Navbar>
        </>

    )
}
export default WebsiteNavbarComponent;