import React from "react";
import { twMerge } from "tailwind-merge";
import logo_white from "../assets/images/vote_logo_white_bg.png";
import { text } from "../constants/tailwind-theme";


export const LoadingPage = ({loadingText}) => {

    return (
        <div className="flex flex-col items-center justify-center  h-screen">
            <img src={logo_white} className="w-32 animate-bounce" />
            <h2 className={twMerge( text.description2,"text-center mb-10")}>Developed by HEYPHORD I.T SOLUTIONS</h2>
            <h2 className={twMerge( text.heading2,"text-center")}>{loadingText ?? "Loading..."}</h2>
        </div>
    )
}

export default LoadingPage;