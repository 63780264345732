import { TextInput } from "flowbite-react";
import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { FiSearch } from "react-icons/fi";

const DataTableComponent = ({
  columns,
  data = [],
  searchable,
  searchObjectIndex = 0,
  searchPlaceholderText,
}) => {
  const customStyles = {
    rows: {
      style: {
        fontSize: "medium",
        paddingTop: "28px",
        paddingBottom: "25px",
      },
    },
    headCells: {
      style: {
        color: "white",
        background: "#024681",
        fontWeight: 700,
        // textTransform: "uppercase",
        letterSpacing: "wider",
        fontSize: "small",
        paddingLeft: "15px",
      },
    },
  };

  const [filteredData, setFilteredData] = useState(data);

  const onSearch = (searchTerm) => {
    const searchData = data?.filter((value) => {
      const colValue = Object.values(value)[searchObjectIndex].toString();
      return colValue?.toLowerCase().includes(searchTerm?.toLowerCase());
    });

    setFilteredData(searchData);
  };

  return (
    <>
      {searchable && (
        <div className="w-1/3 mb-5">
          <TextInput
            id="search"
            icon={FiSearch}
            placeholder={searchPlaceholderText}
            required={true}
            // value={searchTerm}
            onChange={(e) => onSearch(e.target.value)}
          />
        </div>
      )}
      <DataTable
        columns={columns}
        data={filteredData}
        customStyles={customStyles}
      />
    </>
  );
};

export default DataTableComponent;
