import { onSnapshot, orderBy } from "firebase/firestore";
import { Button, Spinner } from "flowbite-react";
import React from "react";
import { IoAdd } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import CandidateItemComponent from "../../components/CandidateItemComponent";
import DeleteConfirmationModal from "../../components/DeleteConfirmationModal";
import EditCandidateModal from "../../components/EditCandidateModal";
import PageTitleComponent from "../../components/PageTitleComponent";
import { FIREBASECOLLECTIONS } from "../../constants/FIREBASECOLLECTIONS";
import { deleteCandidateMiddleware, editCandidateMiddleware } from "../../redux/middlewares/CandidateMiddleware";
import { getFirebaseDocRef, getFirebaseDocs } from "../../util/firebaseFirestoreUtils";
import newcandidate from '../../assets/lottie/newcandidate.json';
import CategoryItemComponent from "../../components/CategoryItemComponent";
import EmptyListPage from "../EmptyPage";
import { useNavigate } from "react-router-dom";

import { getCandidatesError, getCandidatesStart, getCandidatesSuccess, resetActionType as resetCandidateActionType } from "../../redux/slices/CandidateSlice";
import { setSelectedElection, resetActionType as resetElectionActionType, editElectionSuccess, editElectionError } from "../../redux/slices/ElectionSlice";
import { editElectionMiddleware } from "../../redux/middlewares/ElectionMiddleware";


export const CandidatesPage = () => {

    const dispatch = useDispatch()
    const electionState = useSelector(state => state.election)
    const candidateState = useSelector(state => state.candidate)
    const authenticateState = useSelector(state => state.authenticate)
    const navigate = useNavigate();

    //hooks for modals
    const [showEditCandidateModal, setShowEditCandidateModal] = React.useState(false)
    const [showDeleteCandidateModal, setShowDeleteCandidateModal] = React.useState(false)
    const [selectedCandidate, setSelectedCandidate] = React.useState()
    const [showDeleteCategoryModal, setShowDeleteCategoryModal] = React.useState(false)
    const [selectedCategoryIndex, setSelectedCategoryIndex] = React.useState()

    const onDeleteCategory = (categoryIndex) => {


        let categoryCopyy = [...electionState?.selectedElection.categories];
        categoryCopyy.splice(categoryIndex, 1);

        dispatch(editElectionMiddleware(electionState.selectedElection.id, { categories: categoryCopyy }))

    }

    //realtime election change listener
    React.useEffect(() => {
        const docRef = getFirebaseDocRef(`${FIREBASECOLLECTIONS.ELECTIONS}/${electionState?.selectedElection?.id}`);

        const unsub = onSnapshot(docRef, (doc) => {
            dispatch(setSelectedElection({ selectedElection: doc.data() }))
        });
        return () => {
            unsub();
        }
    }, []);

    //candidate listener realtime
    React.useEffect(() => {

        const collecitonRef = getFirebaseDocs(
            `${FIREBASECOLLECTIONS.ELECTIONS}/${electionState.selectedElection?.id}/${FIREBASECOLLECTIONS.CANDIDATES}`,
            [orderBy("position", "asc")]
        )

        dispatch(getCandidatesStart());
        const unsubscribeListener = onSnapshot(collecitonRef, (querySnapshot) => {
            console.log("on snap callback called for get candidates");
            try {
                const candidates = []

                querySnapshot.forEach((doc) => {
                    candidates.push(doc.data());
                });


                dispatch(getCandidatesSuccess({ candidates: candidates }));
            } catch (error) {
                dispatch(getCandidatesError({ message: error.message }))
            }

        });

        return () => {
            unsubscribeListener();
        }

    }, []);



    return (
        <div>
            <PageTitleComponent
                title="View Candidates"
                rightComponent={<Button color="primary" onClick={() => navigate('/admin/election/candidates/add')}> <IoAdd /> Add New Candidate</Button>}
            />



            {/* if empty show "Click here to add a new candidate" button. on click show the add candidate modal */}
            {
                candidateState.isGetCandidatesLoading ? 
                <Spinner /> :
                    candidateState.candidates?.length > 0 ?
                    electionState?.selectedElection.categories.map((category, categoryIndex, categoryArray) => {
                        const candidates = candidateState.candidates.filter((candidate, candidateIndex, candidateArray) => category === candidate.category)
                        return (
                            <div className="flex flex-col m-2" key={category}>

                                <CategoryItemComponent
                                    key={categoryIndex.toString()}
                                    index={categoryIndex}
                                    categorySize={categoryArray.length}
                                    candidateSize={candidates.length}
                                    title={category}

                                    onDeleteClicked={() => {
                                        setShowDeleteCategoryModal(true);
                                        setSelectedCategoryIndex(categoryIndex);

                                    }
                                    }

                                    onMoveUpClicked={() => {
                                        console.log("index", categoryIndex);
                                        let copyy = [...electionState?.selectedElection.categories];
                                        let itemToBeDeleted = copyy[categoryIndex - 1];
                                        copyy.splice(categoryIndex - 1, 1);
                                        copyy.splice(categoryIndex, 0, itemToBeDeleted);


                                        dispatch(editElectionMiddleware(
                                            electionState.selectedElection.id,
                                            { categories: copyy }
                                        ));


                                    }}
                                    onMoveDownClicked={() => {
                                        console.log("index", categoryIndex);
                                        let copyy = [...electionState?.selectedElection.categories];
                                        let itemToBeDeleted = copyy[categoryIndex];
                                        copyy.splice(categoryIndex, 1);
                                        copyy.splice(categoryIndex + 1, 0, itemToBeDeleted);

                                        dispatch(editElectionMiddleware(
                                            electionState.selectedElection.id,
                                            { categories: copyy }
                                        ));
                                    }}
                                />

                                <div className="flex flex-row flex-wrap items-center justify-center md:justify-start">

                                    {candidates.map((candidate, index, arrayy) => {
                                        return (
                                            <div key={candidate.id}>
                                                <CandidateItemComponent
                                                    index={index}
                                                    size={arrayy.length}
                                                    onMoveToLastClicked={() => {
                                                        dispatch(
                                                            editCandidateMiddleware(
                                                                electionState.selectedElection.id,
                                                                candidate.id,
                                                                { position: Date.now() }
                                                            )
                                                        );
                                                    }}
                                                    onEditClicked={() => {
                                                        dispatch(resetCandidateActionType());

                                                        setSelectedCandidate(candidate);
                                                        setShowEditCandidateModal(value => !value)
                                                    }}
                                                    onDeleteClicked={() => {
                                                        dispatch(resetCandidateActionType());
                                                        setSelectedCandidate(candidate);
                                                        setShowDeleteCandidateModal(true);
                                                    }}
                                                    item={candidate} />
                                            </div>
                                        )
                                    })}
                                </div>
                            </div>
                        )


                    })
                        :
                    <EmptyListPage
                        lottieJson={newcandidate}
                        buttonText="Add New Candidate"
                        texxt={"Click the button below to add your first candidate"}
                        onButtonClick={() => navigate('/admin/election/candidates/add')}
                    />

            }



            {/* modals */}
            {showDeleteCategoryModal ? <DeleteConfirmationModal onConfirm={() => { onDeleteCategory(selectedCategoryIndex); setShowDeleteCategoryModal(false) }} onCancel={() => setShowDeleteCategoryModal(false)} show={showDeleteCategoryModal} /> : null}
            {showDeleteCandidateModal ? <DeleteConfirmationModal onConfirm={() => { dispatch(deleteCandidateMiddleware(electionState.selectedElection.id, selectedCandidate.id)); setShowDeleteCandidateModal(false) }} onCancel={() => setShowDeleteCandidateModal(false)} show={showDeleteCandidateModal} /> : null}
            {/* todo: fix this issue . modal input changes not working */}
            {showEditCandidateModal && <EditCandidateModal selectedCandidate={selectedCandidate} onToggle={() => { setShowEditCandidateModal(value => !value) }} show={showEditCandidateModal} /> }
        </div>
    )
}

export default CandidatesPage;